import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PropertiesLabel from './PropertiesLabel';
import Property from './Property';
import MorePropertiesIcon from './MorePropertiesIcon';
import propertiesSplit from './propertiesSplit';

const StyledProperties = styled.ul`
    padding: 0;
    margin: 0 !important;
    list-style: none;
`;

const Properties = (props) => {
    const { title, properties } = props;

    const [ propertiesWithIcon, propertiesWithoutIcon ] = propertiesSplit(properties);

    const PropertiesList = propertiesWithIcon.map((property, key) => {
        return(
            <Property
                name={ property.name || null }
                id={ property.id || null }
                key={ `result_${key}` }
            />
        )
    });

    return (
        <StyledProperties>
            { properties.length > 0 &&
                <PropertiesLabel title={ title } />
            }

            { PropertiesList }

            <MorePropertiesIcon properties={ propertiesWithoutIcon }/>
        </StyledProperties>
    );
}

Properties.defaultProps = {
    title: '',
    properties: [],
}

Properties.propTypes = {
    title: PropTypes.string,
    properties: PropTypes.array,
}

export default Properties;
