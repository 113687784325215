import React from 'react';
import BaseIcon from './BaseIcon';

class MinivanIcon extends BaseIcon {
    render() {
        return (<svg version='1.1' id='minivan' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 800'>
            <path
                fill={ this.props.primary }
                d='M781.6,501.8V229.8c0-13.6-11.1-24.7-24.7-24.7H238.3v-56.8h-47.9v56.8h-31.7c-8.2,0-15.7,4-20.3,10.7L22.7,385
                c-2.8,4.1-4.3,9-4.3,13.9v102.9c-5.6,4.9-9.2,12-9.2,20.1V571c0,14.7,11.9,26.6,26.6,26.6h61c10.2,31.3,39.6,54.1,74.4,54.1
                s64.2-22.8,74.4-54.1h308.8c10.2,31.3,39.6,54.1,74.4,54.1s64.2-22.8,74.4-54.1h61c14.7,0,26.6-11.9,26.6-26.6v-49.1
                C790.8,513.9,787.2,506.7,781.6,501.8z M171.2,604.5c-17.1,0-31.1-13.9-31.1-31.1c0-17.1,13.9-31.1,31.1-31.1s31.1,13.9,31.1,31.1
                C202.3,590.7,188.3,604.5,171.2,604.5z M229.8,370.7L91.4,396.5l96.8-140.1h41.6V370.7z M407.2,370.7L407.2,370.7H276.6V256.3h130.7
                V370.7z M584.6,370.7H453.9V256.3h130.7V370.7z M628.8,604.5c-17.1,0-31.1-13.9-31.1-31.1c0-17.1,13.9-31.1,31.1-31.1
                c17.1,0,31.1,13.9,31.1,31.1C659.9,590.7,645.9,604.5,628.8,604.5z M729.3,370.7h-98.1V256.3h98.1V370.7z'
            />
        </svg>);
    }
}

export default MinivanIcon;
