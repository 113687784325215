import React from 'react';
import PropTypes from 'prop-types';
import Card from './Card';
import CardContainer from './CardContainer';
import CardTitle from './CardTitle';

const CardBox = ({ bgColor, children, title, bxShadowOff, paddingOff, titleSpaceOff }) => {
    return (
        <CardContainer titleSpaceOff={titleSpaceOff}>
            <CardTitle
                title={ title }
                titleSpaceOff={ titleSpaceOff }
            />

            <Card bgColor={ bgColor } bxShadowOff={ bxShadowOff } paddingOff={ paddingOff } >
                { children }
            </Card>
        </CardContainer>
    );
}

CardBox.propTypes = {
    bgColor: PropTypes.string,
    bxShadowOff: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    paddingOff: PropTypes.bool,
    title: PropTypes.string,
    titleSpaceOff: PropTypes.bool,
}

export default CardBox;
