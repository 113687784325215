const blackList = {
    blackListedPropertyGroups: [
        13, // 'Extras'
    ],

    checkPropertyGroups(id) {
        return this.blackListedPropertyGroups.includes(id);
    },

    filterPropertyGroups(results) {
        return results.map(
            (result) => {
                return ({
                    ...result,
                    properties: result.properties.filter(
                        (property) => {
                            return !this.checkPropertyGroups(property.property_group_id)
                        }
                    )
                })
            }
        );
    }
}

export default blackList;
