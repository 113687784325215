import React from 'react';
import PropTypes from 'prop-types';
import BookButton from './resultItem/BookButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FlexItem from '../grid/FlexItem';
import GridRow from '../grid/GridRow';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Map from '../map/Map';
import Price from './resultItem/productPrice';
import ProductCategory from './resultItem/productCategory';
import ProductName from './resultItem/productName';
import ProductProperties from './properties/Properties';
import ResultPaper from './resultItem/ResultPaper';
import styled from 'styled-components';
import Table from '../table/Table';
import { ConfirmationConsumer } from '../../context/Confirmation';
import { GlobalConsumer } from '../../context/Global';
import { TableRow } from '@material-ui/core';
import { t } from 'i18next';

const StyledTableCell = styled.td`
    display: table-cell;
    text-align: left;
    vertical-align: inherit;

    &:first-of-type {
        padding-left: 12px;
    }
`;

const StyledColumn = styled.th`
    position: relative;
    padding: 0;
    text-align: left;
    white-space: nowrap;
    text-transform: uppercase;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0,0,0,0.6);

    &:first-of-type {
        padding-left: 12px;
    }
`;


/**
 * @todo properties need to be filled with real data
 */

class ResultItem extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
         showBody: false,
      }
    }

    handleClick = (result, selectProduct, changePage) => {
        selectProduct(result);
        changePage('confirmation');
    }

    handleOnToggleBody = () => {
        this.setState(state => ({
            showBody: !state.showBody,
        }));
        this.setState(state => ({ open: !state.open }));
    }

    render() {
        const { result } = this.props;
        const { showBody } = this.state;

        return (
            <ConfirmationConsumer>
                {({ selectProduct, searchDetails }) => {
                    const isCombined = result.is_combined;
                    const selectedProducts = result.selected_products;

                    return (
                        <ResultPaper >
                            <GridRow flexDirection="row" size={0}>
                                <GridRow size={ 5 } flexDirection="column">
                                    <ProductName title={ result.title } />

                                    { result.subcategory && <ProductCategory category={ result.subcategory.name } /> }

                                    <FlexItem />

                                    <ProductProperties
                                        title={ `${ t('comparison:options') } / ${ t('comparison:properties') }` }
                                        properties={ result.properties }
                                    />
                                </GridRow>

                                { result.location_latitude &&
                                    <Map
                                        size="300x150"
                                        lat={ result.location_latitude }
                                        lng={ result.location_longitude }
                                        poi={ searchDetails.location.destination }
                                    />
                                }

                                <GridRow
                                    flexDirection="column"
                                    justifyContent="space-between"
                                    size={ 0 }
                                >
                                    <Price
                                        amount={ result.price }
                                        currency={ result.price_currency }
                                    />

                                    <GlobalConsumer>
                                        {({ changePage }) => {
                                            return (
                                                <BookButton
                                                    tabIndex={ 0 }
                                                    onClick={ () => {
                                                        this.handleClick(result, selectProduct, changePage);
                                                    } }
                                                    title={ t('comparison:bookNow') }
                                                />
                                            )
                                        }}
                                    </GlobalConsumer>
                                </GridRow>
                            </GridRow>
                            <br/>

                            {
                               isCombined &&
                                <GridRow flexDirection="row" size={ 0 }>
                                    <ListItem
                                        button
                                        onClick={ this.handleOnToggleBody }
                                        style={{ marginLeft: -20, flex: 1 }}
                                    >
                                        <ListItemText primary={ t('comparison:selectedProducts') } />

                                        { showBody ? <ExpandLess /> : <ExpandMore /> }
                                    </ListItem>
                                </GridRow>
                            }

                            {
                                showBody &&
                                <GridRow flexDirection="row">
                                    <Table style={{ marginLeft: -20, flex: 1 }}>
                                        <TableRow>
                                            <StyledColumn style={{ width: '15%' }}>
                                                { t('comparison:quantity') }
                                            </StyledColumn>
                                            <StyledColumn style={{ width: '70%' }}>
                                                { t('comparison:product') }
                                            </StyledColumn>
                                            <StyledColumn style={{ width: '15%' }}>
                                                { t('comparison:category') }
                                            </StyledColumn>
                                        </TableRow>

                                        {
                                            selectedProducts.map((selectedProduct) => {
                                                const { category, id, title } = selectedProduct.product;

                                                return (
                                                    <TableRow key={id}>
                                                        <StyledTableCell>{ selectedProduct.quantity }x</StyledTableCell>
                                                        <StyledTableCell>{ title }</StyledTableCell>
                                                        <StyledTableCell>{ category.name }</StyledTableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </Table>
                                </GridRow>
                            }
                        </ResultPaper>
                    )
                }}
            </ConfirmationConsumer>
        );
    }
}

ResultItem.propTypes = {
    result: PropTypes.shape({
        id: PropTypes.string.isRequired,
        location_address: PropTypes.string,
        location_latitude: PropTypes.number,
        location_longitude: PropTypes.number,
        price: PropTypes.number.isRequired,
        price_currency: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
}

export default ResultItem;
