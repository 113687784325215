import React from 'react';
import CardBox from '../../../components/cardBox/CardBox';
import PropTypes from 'prop-types';
import DirectionsMap from '../../../components/map/DirectionsMap';
import CollapsibleListItem from '../../../components/listItem/CollapsibleListItem';

const TripDetails = ({ originAddress, destinationAddress }) => (
    <CardBox
        paddingOff
        title="Trip details"
    >
        <CollapsibleListItem
            label="Route"
            initiallyOpen={ false }
        >
            <DirectionsMap
                size="400x170"
                origin={ originAddress }
                destination={ destinationAddress }
            />
        </CollapsibleListItem>
    </CardBox>
)

TripDetails.propTypes = {
    originAddress: PropTypes.string.isRequired,
    destinationAddress: PropTypes.string.isRequired,
}

export default TripDetails;
