import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
    width: 100% !important;
    margin: 16px 0 20px;
`;

const StyledWrapper = styled.div`
    position: relative;
`;

const StyledInputLabel = styled(InputLabel)`
    top: 0;
    left: 0;
    position: absolute;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
`;

const SelectField = ({ children, label, name, onChange, value }) => {
    return (
        <StyledWrapper>
            <StyledInputLabel>
                { label }
            </StyledInputLabel>

            <StyledSelect
                name={ name }
                onChange={ onChange }
                value={ value }
            >
                { children }
            </StyledSelect>
        </StyledWrapper>
    );
}

SelectField.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
}

export default SelectField;
