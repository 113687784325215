import React from 'react';
import BaseIcon from './BaseIcon';

class ParkIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="park" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g>
                <g transform="translate(-680.000000, -120.000000)">
                    <g transform="translate(691.000000, 129.000000)">
                        <path
                            fill={ this.props.primary }
                            style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                            d="M220.7,394.8v89.4c0,7.7,6.2,13.9,13.9,13.9h20.9
                            c7.7,0,13.9-6.2,13.9-13.9v-89.4h159.1c12.6,0,18.7-15.5,9.4-24l-112-103.7h59.5c11.8,0,18.2-13.8,10.6-22.8l-88-104.8h29.7
                            c11.6,0,18.1-13.4,10.8-22.5L255.8,1.1c-5.5-6.9-16.1-6.9-21.6,0L141.5,117c-7.3,9.1-0.8,22.5,10.8,22.5H182L94,244.4
                            c-7.6,9-1.2,22.8,10.6,22.8h59.5L52.2,370.7c-9.2,8.6-3.2,24,9.4,24L220.7,394.8L220.7,394.8z"
                        />
                    </g>
                </g>
            </g>
        </svg>);
    }
}

export default ParkIcon;
