require('whatwg-fetch');
import 'core-js/features/promise';
import 'core-js/features/object/assign';
import 'core-js/features/array/includes';
import 'core-js/features/array/find';
import 'core-js/features/array/for-each';

import React from 'react';
import ReactDOM from 'react-dom';
import './config/i18next';
import App from "./components/App";

// Initialising the Google maps API
const script = document.createElement('script');
script.src = `https://maps.googleapis.com/maps/api/js?key=${ process.env.GOOGLE_MAPS_KEY }&libraries=places`;
document.head.appendChild(script);

ReactDOM.render(
    <App />,
    document.getElementById('booking-tool')
);
