import React from 'react';
import BaseIcon from './BaseIcon';

class UnpavedIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="unpaved" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path
                fill={ this.props.primary }
                d="M146.9,139.2c2.7-25.9,10-54.9,27.5-79.7c2.2-3.1-1.1-7.1-4.6-5.5c-19,9.1-34.3,21.3-47,35c-0.7,0.9-2.2,0.5-2.4-0.7
                c-4.4-19.7-12-40.1-24.1-62.7c-1.8-3.3-6.9-2.2-6.9,1.6c0.2,23.9-4.6,52.7-10.9,80.4c-0.2,1.1-1.8,1.5-2.6,0.5
                C63.8,93.6,48.7,80.5,29.1,71c-3.5-1.6-6.7,2.4-4.6,5.5c14.8,21.7,21.3,46.7,23.9,69.1c0.2,1.5-1.5,2.2-2.6,1.1
                c-8.4-8.8-17.5-16.8-27-22.2c-3.3-2-6.9,1.6-4.9,5.1c7.3,12,12.4,29,15.9,44.1c0.4,1.6,1.8,2.7,3.5,2.7h129.8c1.6,0,3.1-1.1,3.5-2.7
                c4-18.2,10.2-39.6,19.3-53.8c2-3.1-1.5-7.1-4.7-5.3c-11.3,6.2-22.1,15.5-31.9,25.9C148.4,141.4,146.8,140.6,146.9,139.2z"
            />
        </svg>);
    }
}

export default UnpavedIcon;
