import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CSSGridContainer from '../../../components/grid/CSSGridContainer';
import CSSGridItem from '../../../components/grid/CSSGridItem';
import DateTimePicker from '../../../components/input/DateTimePicker';
import FormErrors from '../../../components/form/FormErrors';
import LocationField from '../../../components/input/LocationField';
import TabContent from '../../../components/tab/TabContent';
import TextField from '../../../components/input/TextField';
import _ from 'lodash';
import utils from '../../../utils';
import { ComparisonConsumer } from '../../../context/Comparison';
import { ConfirmationConsumer } from '../../../context/Confirmation';
import { t } from 'i18next';

class TaxiForm extends React.Component {
    constructor(props){
        super(props);

        const {
            destinationAddress,
            destinationArrivalDate,
            location,
            numberOfPersons,
            originAddress,
        } = props.searchDetails;

        this.state = {
            apiErrors: [],
            location: location,
            search: {
                destinationAddress: destinationAddress,
                destinationArrivalDate: destinationArrivalDate,
                numberOfPersons: numberOfPersons,
                originAddress: originAddress,
            },
            typeOfBooking: '',
            validated: true,
            validElements: {},
        }
    }

    checkElementValidity = (name, value) => {
        this.setState(
            (prevState) => {
                return {
                    validElements: {
                        ...prevState.validElements,
                        [name]: value,
                    }
                };
            }, this.validateForm);
    }

    validateForm = () => {
        const { validElements } = this.state;

        let result = true;

        for (var i in validElements) {
            if (validElements[i] === false) {
                result = false;
                break;
            }
        }

        this.setState({
            validated: result,
        })

        return result;
    }

    setApiErrors = (errors) => {
        this.setState({
            apiErrors: errors || [],
        });
    }

    /**
     * This handles the submit of the form.
     * The state.search object gets updated other search data.
     * The form gets validated and then we will fetch the correct Catalogue data.
     *
     * @param {function} fetchTaxiCatalogue
     * @param {function} setLoaderState
     * @param {function} createSearchDetails
     * @param {function} clearResults
     * @param {string} category
     * @todo find out why we need the _cloneDeep function.
     */
    handleSubmit = (fetchTaxiCatalogue, setLoaderState, createSearchDetails, clearResults, category, e) => {
        e.preventDefault();

        // Fetch the state.
        const { location, search } = this.state;

        // Until we find a better way, we clone the search object so that context doesn't have a reference to the local state.
        const searchDetails = _.cloneDeep(search);
        const params = _.cloneDeep(search);

        // Change the date objects to strings
        params.destinationArrivalDate = utils.formatDate(search.destinationArrivalDate);
        params.destinationDepartureDate = utils.formatDate(search.destinationDepartureDate);

        // Add POI id to the search for each leg of the trip.
        for (const leg of Object.keys(location)) {
            if (_.get(location[leg],'type')) {
                const POIKey = `${ leg }PointOfInterestId`;

                if (location[leg].type !== 'google-places') {
                    params[POIKey] = location[leg].id;
                } else {
                    delete params[POIKey];
                }
            }
        }

        // Add the type of booking, day and time to the searchDetails, then put all the search form field in the Confirmation context, to use it on another page or to populate the form when going back to the first page.
        searchDetails.location = location;
        searchDetails.typeOfBooking = category;

        // Validate the form values.
        if (this.validateForm()) {
            // Trigger the loader.
            setLoaderState(true);

            // Clearing the results so that the user never sees the old results.
            clearResults();

            // Fetch the results with the state as payload.
            fetchTaxiCatalogue(params).then(() => {
                this.setApiErrors([]);
                return createSearchDetails(searchDetails);
            }, (error) => {
                return this.setApiErrors(error.response.message.split('\n.label'));
            }).then(() => setLoaderState(false));
        }
    }

    handleAddressChange = (value, prefix, key) => {
        let { location, search } = this.state;
        let newLocation = location[prefix];

        if (location[prefix] == null || !value.type) {
            newLocation = {
                name: value
            };
        }

        if (value.address) {
            search[key] = value.address;
        }

        if (value.id) {
            newLocation = value;
        }

        this.setState({
            location: {
                ...location,
                [prefix]: newLocation,
            },
            search: search,
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    handleSearchChange = e => {
        const search = this.state.search;

        search[e.target.name] = e.target.value;

        this.setState({
            search: search,
        });
    }

    render() {
        return (
            <ComparisonConsumer>
                {({ clearResults, fetchTaxiCatalogue, setLoaderState, loading }) => {
                    return (
                        <ConfirmationConsumer>
                            {({ createSearchDetails }) => {
                                return (
                                    <form onSubmit={this.handleSubmit.bind(this, fetchTaxiCatalogue, setLoaderState, createSearchDetails, clearResults, 'taxi')}>
                                        <TabContent>
                                            <CSSGridContainer columns={ 3 }>
                                                <CSSGridItem size={ 2 }>
                                                    <LocationField
                                                        autoComplete="off"
                                                        errorText={ t('forms:formElements.taxi.originAddress.error') }
                                                        label={ t('forms:formElements.taxi.originAddress.label') }
                                                        fullWidth={ true }
                                                        name="originAddress"
                                                        onChange={ (value) => this.handleAddressChange(value, 'origin', 'originAddress') }
                                                        required
                                                        valid={ this.checkElementValidity }
                                                        value={ this.state.location.origin }
                                                    />
                                                </CSSGridItem>

                                                <CSSGridItem>
                                                    <DateTimePicker
                                                        errorText={ t('forms:formElements.taxi.destinationArrivalDate.error') }
                                                        label={ t('forms:formElements.taxi.destinationArrivalDate.label') }
                                                        name="destinationArrivalDate"
                                                        onChange={ this.handleSearchChange }
                                                        required
                                                        valid={ this.checkElementValidity }
                                                        value={ this.state.search.destinationArrivalDate }
                                                        warningText={ t('forms:formValidation.matchFormat', {format: "yyyy-mm-dd hh:mm"}) }
                                                    />
                                                </CSSGridItem>
                                            </CSSGridContainer>

                                            <CSSGridContainer columns={ 3 }>
                                                <CSSGridItem size={ 2 }>
                                                    <LocationField
                                                        autoComplete="off"
                                                        errorText={ t('forms:formElements.taxi.destinationAddress.error') }
                                                        label={ t('forms:formElements.taxi.destinationAddress.label') }
                                                        fullWidth={ true }
                                                        name="destinationAddress"
                                                        onChange={ (value) => this.handleAddressChange(value, 'destination', 'destinationAddress') }
                                                        required
                                                        valid={ this.checkElementValidity }
                                                        value={ this.state.location.destination }
                                                    />
                                                </CSSGridItem>

                                                <CSSGridItem>
                                                    <TextField
                                                        constraint={ /^(\d+)$/ }
                                                        label={ t('forms:formElements.common.numberOfPersons.label') }
                                                        name="numberOfPersons"
                                                        onChange={ this.handleSearchChange }
                                                        valid={ this.checkElementValidity }
                                                        value={ this.state.search.numberOfPersons }
                                                        warningText={ t('forms:formElements.common.numberOfPersons.warning') }
                                                    />
                                                </CSSGridItem>
                                            </CSSGridContainer>

                                            <FormErrors errors={ this.state.apiErrors }/>
                                        </TabContent>

                                        <Button
                                            disabled={ !this.state.validated || loading }
                                            type="submit"
                                            fullWidth={ true }
                                            color="secondary"
                                            variant="contained"
                                        >
                                            { t('forms:formElements.taxi.searchButton.label') }
                                        </Button>
                                    </form>
                                );
                            }}
                        </ConfirmationConsumer>
                    );
                }}
            </ComparisonConsumer>
        )
    }
}

TaxiForm.propTypes = {
    searchDetails: PropTypes.object.isRequired,
}

export default TaxiForm;
