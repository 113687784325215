import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTable = styled.table`
    width: 100%;
    border: 0;
    border-collapse: collapse;
`;

const Table = ({ children }) => {
    return (
        <StyledTable>
            <tbody>
                { children }
            </tbody>
        </StyledTable>
    );
}

Table.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

export default Table;
