import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TableCol from './TableCol';
import Price from '../Price';

const StyledTableRow = styled.tr`
    width: 100%;
`;

const TableRow = ({ cartItem, currency }) => {
    return (
        <StyledTableRow>
            <TableCol primary={ cartItem.primary } >
                { cartItem.label }
            </TableCol>

            <TableCol
                price
                primary={ cartItem.primary }
                topBorder={ cartItem.topBorder }
            >
                <Price amount={ cartItem.price } currency={ currency } />
            </TableCol>
        </StyledTableRow>
    );
}

TableRow.propTypes = {
    cartItem: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
}

export default TableRow;
