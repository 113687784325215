import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCard = styled.div`
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    ${ props => props.bxShadowOff ? '' : 'box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;' }
    border-radius: 2px;
    ${ props => props.paddingOff ? '' : 'padding: 16px;' }
    background-color: ${ props => props.bgColor || '#FFF' };
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
`;

const Card = ({ children, bxShadowOff, paddingOff, bgColor }) => {
    return (
        <StyledCard bxShadowOff={bxShadowOff} bgColor={bgColor} paddingOff={paddingOff} >
            { children }
        </StyledCard>
    );
}

Card.propTypes = {
    bgColor: PropTypes.string,
    bxShadowOff: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    paddingOff: PropTypes.bool,
}

export default Card;
