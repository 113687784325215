import React from 'react';
import BaseIcon from './BaseIcon';

class DoubleRoomIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="double-room" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <g>
                <path fill={ this.props.primary } d="M175.9,298.2L175.9,298.2c0-20.9,17-37.9,37.9-37.9h62.7h62.7c20.9,0,37.9,17,37.9,37.9v0c0,2.6-0.3,5.2-0.8,7.7H400h23.6
                    c-0.5-2.5-0.8-5.1-0.8-7.7v0c0-20.9,17-37.9,37.9-37.9h62.7h62.7c20.9,0,37.9,17,37.9,37.9v0c0,2.6-0.3,5.2-0.8,7.7h41.1v-79.4
                    c0-33-26.8-59.8-59.8-59.8h-109H400h-95.5h-109c-33,0-59.8,26.8-59.8,59.8v79.4h41.1C176.2,303.4,175.9,300.8,175.9,298.2z"/>
                <path fill={ this.props.primary } d="M400,472.7h95.5h217.8l-48.8-157.1h-44.8c-6.3,12.1-19,20.5-33.6,20.5h-62.7h-62.7c-14.6,0-27.3-8.3-33.6-20.5H400h-27.1
                    c-6.3,12.1-19,20.5-33.6,20.5h-62.7h-62.7c-14.6,0-27.3-8.3-33.6-20.5h-44.8L86.6,472.7h217.8H400z"/>
                <polygon fill={ this.props.primary } points="400,482.5 304.5,482.5 86.6,482.5 86.6,581.8 86.6,584.2 86.6,633.4 143.7,633.4 143.7,584.2 304.5,584.2
                    400,584.2 495.5,584.2 656.3,584.2 656.3,633.4 713.4,633.4 713.4,584.2 713.4,581.8 713.4,482.5 495.5,482.5 	"/>
            </g>
        </svg>);
    }
}

export default DoubleRoomIcon;
