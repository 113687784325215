import React from 'react';

// Import mapped SVG icons
import PlaneIcon from './icons/PlaneIcon';
import PortIcon from './icons/PortIcon';
import TrainIcon from './icons/TrainIcon';

import AccommodationIcon from './icons/AccomodationIcon';
import BinocularsIcon from './icons/BinocularsIcon';
import CityIcon from './icons/CityIcon';
import EventCentreIcon from './icons/EventCentreIcon';
import EventIcon from './icons/EventIcon';
import HospitalIcon from './icons/HospitalIcon';
import MobianIcon from './icons/MobianIcon';
import NeighbourhoodIcon from './icons/NeighbourhoodIcon';
import ParkIcon from './icons/ParkIcon';
import RecreationIcon from './icons/RecreationIcon';

// Map icons to POI types
const iconMapping = {
    'accommodation': AccommodationIcon,
    'airport': PlaneIcon,
    'city': CityIcon,
    'event': EventIcon,
    'event_center': EventCentreIcon,
    'hospital': HospitalIcon,
    'neighbourhood': NeighbourhoodIcon,
    'other': MobianIcon,
    'park': ParkIcon,
    'port': PortIcon,
    'recreation': RecreationIcon,
    'sight': BinocularsIcon,
    'station': TrainIcon,
}

export const hasIcon = (id) => {
    return !!iconMapping[id];
};

const PointOfInterestTypeIcon = ({ id }) => {
    const IconComponent = iconMapping[id] || iconMapping['other'];

    return <IconComponent primary="#EF3D5C" />;
};

export default PointOfInterestTypeIcon;
