import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledFlexContainer = styled.div`
    display: flex;
    justify-content: ${ props => props.justify };
`;

const FlexContainer = ({ children, justify, className }) => {
    return (
        <StyledFlexContainer
            justify={ justify }
            // The line below is needed for styled components
            className={ className }
        >
            {children}
        </StyledFlexContainer>
    );
}

FlexContainer.defaultProps = {
    justify: 'flex-start',
}

FlexContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    justify: PropTypes.string
}

export default FlexContainer;
