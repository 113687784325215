import React from 'react';
import BaseIcon from './BaseIcon';

class EntryCodeIcon extends BaseIcon {
    render() {
        return (<svg version='1.1' id='entry_code' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 800'>
            <g>
                <path fill={ this.props.primary } d='M644.4,441.1c-8.7-52.1-47.4-56.7-61-56.7c-3,0-4.6,0.2-4.6,0.2c-11.8-23.5-29.4-29.4-44.1-29.4
                    c-14.7,0-26.4,5.9-26.4,5.9c-11.8-23.5-29.8-29.4-44.7-29.4c-15,0-26.9,5.9-26.9,5.9v-62.2c0-23.6-17.7-35.4-35.4-35.4
                    c-17.7,0-35.4,11.8-35.4,35.4c0,47.2,0,156.3,0,156.3c-81.7,19.9-7.3,142.6,16.3,179.5v45.3c0,10,9.9,19.1,19.9,19.1h187
                    c10,0,16.4-9.1,16.4-19.1v-43C649.1,545.4,651.9,485.8,644.4,441.1z'/>
                <circle fill={ this.props.primary } cx='400.7' cy='173' r='35.6'/>
                <circle fill={ this.props.primary } cx='293.9' cy='275.5' r='35.6'/>
                <circle fill={ this.props.primary } cx='293.9' cy='173' r='35.6'/>
                <circle fill={ this.props.primary } cx='189.3' cy='275.5' r='35.6'/>
                <circle fill={ this.props.primary } cx='189.3' cy='173' r='35.6'/>
                <circle fill={ this.props.primary } cx='293.9' cy='378' r='35.6'/>
                <circle fill={ this.props.primary } cx='189.3' cy='378' r='35.6'/>
            </g>
        </svg>);
    }
}

export default EntryCodeIcon;
