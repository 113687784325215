import React from 'react';
import PropTypes from 'prop-types';

class BaseIcon extends React.Component {
    render() {
        return null;
    }
}

BaseIcon.defaultProps = {
    primary: '#000000',
    secondary: '#8e8e8e',
};

BaseIcon.propTypes = {
    primary: PropTypes.string,
    secondary: PropTypes.string,
};

export default BaseIcon;
