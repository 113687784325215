import React from 'react';
import BaseIcon from './BaseIcon';

class EventIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="event" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
            <path
                fill={ this.props.primary }
                d="M256.5,311.2H212c-8.6,0-16.1,7-16.1,16.1v27.3c0,8.6,7,16.1,16.1,16.1h44.5c8.6,0,16.1-7,16.1-16.1v-27.3
                C272.6,318.7,265.1,311.2,256.5,311.2z"
            />
            <path
                fill={ this.props.primary }
                d="M141.8,311.2H97.3c-8.6,0-16.1,7-16.1,16.1v27.3c0,8.6,7,16.1,16.1,16.1h44.5c8.6,0,16.1-7,16.1-16.1v-27.3
                C157.9,318.7,150.4,311.2,141.8,311.2z"
            />
            <path
                fill={ this.props.primary }
                d="M256.5,211H212c-8.6,0-16.1,7-16.1,16.1v27.3c0,8.6,7,16.1,16.1,16.1h44.5c8.6,0,16.1-7,16.1-16.1V227
                C272.6,218.5,265.1,211,256.5,211z"
            />
            <path
                fill={ this.props.primary }
                d="M141.8,211H97.3c-8.6,0-16.1,7-16.1,16.1v27.3c0,8.6,7,16.1,16.1,16.1h44.5c8.6,0,16.1-7,16.1-16.1V227
                C157.9,218.5,150.4,211,141.8,211z"
            />
            <path
                fill={ this.props.primary }
                d="M371.3,211h-44.5c-8.6,0-16.1,7-16.1,16.1v27.3c0,8.6,7,16.1,16.1,16.1h44.5c8.6,0,16.1-7,16.1-16.1V227
                C387.4,218.5,379.9,211,371.3,211z"
            />
            <path
                fill={ this.props.primary }
                d="M467.2,316V103.2c0-13.9-5.4-26.3-13.9-35.9v-0.5h-0.5c-10.2-11.3-24.7-18.2-40.7-18.2h-40.2V24.4c0-10.2-8-18.2-18.2-18.2
                h-27.3c-10.2,0-18.2,8-18.2,18.2v24.1H160V24.4c0-10.2-8-18.2-18.2-18.2h-27.3c-10.2,0-18.2,8-18.2,18.2v24.1H56
                c-16.1,0-30.6,7-40.7,18.2h0v0.5c-8.6,9.7-13.9,22-13.9,35.9v292.2c0,30,24.7,54.7,54.7,54.7h256.8c17.7,33.2,52.5,55.8,92.8,55.8
                c57.9,0,105.1-47.2,105.1-105.1C510.7,365.9,493.5,334.8,467.2,316z M300.5,400.8c0,4.3,0.5,8.6,1.1,12.9H56
                c-10.2,0-18.2-8-18.2-18.2v-223h392.5v126.5c-8-2.1-16.1-3.2-24.7-3.2C347.7,295.7,300.5,342.9,300.5,400.8z M452.8,392.2
                l-16.6,16.6c-1.1,1.1-1.6,2.7-1.6,4.8l3.8,22.5c1.1,5.4-4.8,9.7-9.7,7l-20.9-10.7c-1.6-0.5-3.2-0.5-4.8,0l-20.4,10.7
                c-4.8,2.7-10.7-1.6-9.7-7l3.8-23.1c0.5-1.6,0-3.2-1.6-4.8l-16.6-16.1c-3.8-3.8-1.6-10.7,3.8-11.8l23.1-3.2c1.6,0,3.2-1.1,3.8-2.7
                l10.2-20.4c2.7-4.8,9.7-4.8,12.3,0l10.2,20.9c0.5,1.6,2.1,2.7,3.8,2.7l22.5,3.2C454.4,381.5,456.5,388.4,452.8,392.2z"
            />
        </svg>);
    }
}

export default EventIcon;
