import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

class CollapsibleListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.initiallyOpen || false,
        }
    }

    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    render() {
        const {
            label,
            children,
        } = this.props;

        return(
            <React.Fragment>
                <ListItem button onClick={ this.handleClick }>
                    <ListItemText primary={ label } />

                    { this.state.open ? <ExpandLess /> : <ExpandMore /> }
                </ListItem>

                <Collapse in={ this.state.open } timeout="auto" unmountOnExit>
                    { children }
                </Collapse>
            </React.Fragment>
        )
    }
}

export default CollapsibleListItem;
