import React from 'react';
import BookingInformation from '../containers/bookingInformation/BookingInformation';
import FunnelStepper from '../containers/FunnelStepper';
import { ConfirmationConsumer } from '../context/Confirmation';

class Confirmation extends React.Component {
    render() {
        return (
            <ConfirmationConsumer>
                {({ selectedProduct }) => {
                    return (
                        <div>
                            <BookingInformation selectedProduct={ selectedProduct } />

                            <FunnelStepper />
                        </div>
                    )
                }}
            </ConfirmationConsumer>
        )
    }
}

export default Confirmation;
