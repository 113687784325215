const ERROR_NAME = 'ServerError';

class ServerError extends Error {
    constructor(message, status, response) {
        super(message);
        this.name = ERROR_NAME;
        this.status = status;
        this.response = response;
    }
}

ServerError.ERROR_NAME = ERROR_NAME;

export default ServerError;
