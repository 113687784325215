import React from 'react';
import BaseIcon from './BaseIcon';

class AccomodationIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="accomodation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 491 491">
            <path
                fill={ this.props.primary }
                d="M71.7,166.5L71.7,166.5c0-16.2,13.2-29.4,29.4-29.4h48.6h48.6c16.2,0,29.4,13.2,29.4,29.4l0,0c0,2-0.2,4-0.6,6h18.4h18.3
                c-0.4-1.9-0.6-4-0.6-6l0,0c0-16.2,13.2-29.4,29.4-29.4h48.6h48.6c16.2,0,29.4,13.2,29.4,29.4l0,0c0,2-0.2,4-0.6,6h31.9v-61.6
                c0-25.6-20.8-46.4-46.4-46.4h-84.5h-74.1h-74.1H86.9c-25.6,0-46.4,20.8-46.4,46.4v61.6h31.9C71.9,170.5,71.7,168.5,71.7,166.5z"
            />
            <path
                fill={ this.props.primary }
                d="M245.5,301.8h74.1h168.9L450.6,180h-34.7c-4.9,9.4-14.7,15.9-26.1,15.9h-48.6h-48.6c-11.3,0-21.2-6.4-26.1-15.9h-21h-21
                c-4.9,9.4-14.7,15.9-26.1,15.9h-48.6h-48.6c-11.3,0-21.2-6.4-26.1-15.9H40.4L2.4,301.8h168.9H245.5z"
            />
            <polygon
                fill={ this.props.primary }
                points="245.5,309.4 171.4,309.4 2.4,309.4 2.4,386.5 2.4,388.3 2.4,426.5 46.7,426.5 46.7,388.3 171.4,388.3
                245.5,388.3 319.6,388.3 444.3,388.3 444.3,426.5 488.6,426.5 488.6,388.3 488.6,386.5 488.6,309.4 319.6,309.4"
            />
        </svg>);
    }
}

export default AccomodationIcon;
