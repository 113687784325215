import React from 'react';
import BaseIcon from './BaseIcon';

class RecreationIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="recreation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g>
                <path
                    fill={ this.props.primary }
                    d="M462.7,215.4c-1.4,0-2.7,0.1-4,0.2c-5.3-23.5-14.6-45.6-27.2-65.4c1-0.8,2-1.7,3-2.7c17.9-17.9,17.9-46.9,0-64.8
                    s-46.9-17.9-64.8,0c-1,1-1.9,2-2.7,3c-19.8-12.6-41.8-21.9-65.4-27.2c0.1-1.3,0.2-2.6,0.2-4c0-25.3-20.5-45.8-45.8-45.8
                    s-45.8,20.5-45.8,45.8c0,1.4,0.1,2.7,0.2,4c-23.5,5.3-45.6,14.6-65.4,27.2c-0.8-1-1.7-2-2.7-3c-17.9-17.9-46.9-17.9-64.8,0
                    s-17.9,46.9,0,64.8c1,1,2,1.9,3,2.7C67.9,170,58.6,192,53.3,215.6c-1.3-0.1-2.6-0.2-4-0.2c-25.3,0-45.8,20.5-45.8,45.8
                    S24,307,49.3,307c1.4,0,2.7-0.1,4-0.2c5.3,23.5,14.6,45.6,27.2,65.4c-1,0.8-2,1.7-3,2.7c-17.9,17.9-17.9,46.9,0,64.8
                    c15.2,15.2,38.4,17.5,56,6.9l25.4-50.7c-41.8-30.2-69-79.3-69-134.7c0-91.6,74.5-166.1,166.1-166.1s166.1,74.5,166.1,166.1
                    c0,55.4-27.2,104.5-69,134.7l25.4,50.7c17.6,10.6,40.8,8.3,56-6.9c17.9-17.9,17.9-46.9,0-64.8c-1-1-2-1.9-3-2.7
                    c12.6-19.8,21.9-41.8,27.2-65.4c1.3,0.1,2.6,0.2,4,0.2c25.3,0,45.8-20.5,45.8-45.8C508.5,235.9,488,215.4,462.7,215.4z"
                />
                <path
                    fill={ this.props.primary }
                    d="M272.5,426.5c-5.4,0.5-10.9,0.8-16.5,0.8s-11.1-0.3-16.5-0.8l-19.7,39.3c11.8,2.1,23.8,3.2,36.2,3.2
                    c12.3,0,24.4-1.1,36.2-3.2L272.5,426.5z"/>
                <path
                    fill={ this.props.primary }
                    d="M367.4,472.2L277.1,292c9.8-6.7,16.2-18,16.2-30.8c0-20.6-16.7-37.3-37.3-37.3s-37.3,16.7-37.3,37.3
                    c0,12.8,6.4,24.1,16.2,30.8l-90.3,180.2c-5.3,10.6-1,23.5,9.6,28.8s23.5,1,28.8-9.6l73-145.8l73,145.8c3.8,7.5,11.3,11.8,19.2,11.8
                    c3.2,0,6.5-0.7,9.6-2.3C368.4,495.7,372.7,482.8,367.4,472.2z"/>
            </g>
        </svg>);
    }
}

export default RecreationIcon;
