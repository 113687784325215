import React from 'react';
import BaseIcon from './BaseIcon';

class TripleRoomIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="triple-room" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <g>
                <g>
                    <polygon fill={ this.props.primary } points="472.8,457.1 472.8,525.8 472.8,527.5 472.8,561.5 512.3,561.5 512.3,527.5 623.6,527.5 734.9,527.5 734.9,561.5
                        774.3,561.5 774.3,527.5 774.3,525.8 774.3,457.1 623.6,457.1 		"/>
                    <path fill={ this.props.primary } d="M554,329.5L554,329.5c0-14.5,11.7-26.2,26.2-26.2h43.3h43.3c14.5,0,26.2,11.7,26.2,26.2l0,0c0,1.8-0.1,3.6-0.5,5.3h47.7
                        v-54.9c0-22.9-18.5-41.4-41.4-41.4h-75.4h-75.4c-22.9,0-41.4,18.5-41.4,41.4v54.9h47.7C554.1,333.1,554,331.3,554,329.5z"/>
                    <path d="M774.3,450.3l-33.8-108.7h-50.3c-4.4,8.4-13.1,14.1-23.3,14.1h-43.3h-43.3c-10.2,0-18.9-5.8-23.3-14.1h-50.3l-33.8,108.7
                        h150.7H774.3z"/>
                </g>
                <g>
                    <path fill={ this.props.primary } d="M87.4,329.6L87.4,329.6c0-14.4,11.7-26.2,26.2-26.2h43.3h43.3c14.4,0,26.2,11.7,26.2,26.2l0,0c0,1.8-0.2,3.6-0.6,5.3h16.4
                        h16.3c-0.3-1.7-0.6-3.5-0.6-5.3l0,0c0-14.4,11.7-26.2,26.2-26.2h43.3h43.3c14.4,0,26.2,11.7,26.2,26.2l0,0c0,1.8-0.2,3.6-0.6,5.3
                        h28.4v-54.8c0-22.8-18.5-41.3-41.3-41.3h-75.3h-66h-66h-75.3c-22.8,0-41.3,18.5-41.3,41.3V335H88
                        C87.6,333.2,87.4,331.4,87.4,329.6z"/>
                    <path fill={ this.props.primary } d="M242.2,450.2h66h150.4l-33.7-108.5h-30.9c-4.4,8.4-13.1,14.2-23.2,14.2h-43.3h-43.3c-10.1,0-18.9-5.7-23.2-14.2h-18.7
                        h-18.7c-4.4,8.4-13.1,14.2-23.2,14.2h-43.3h-43.3c-10.1,0-18.9-5.7-23.2-14.2H59.5L25.7,450.2h150.4H242.2z"/>
                    <polygon fill={ this.props.primary } points="242.2,457 176.2,457 25.7,457 25.7,525.5 25.7,527.2 25.7,561.2 65.1,561.2 65.1,527.2 176.2,527.2 242.2,527.2
                        308.1,527.2 419.2,527.2 419.2,561.2 458.7,561.2 458.7,527.2 458.7,525.5 458.7,457 308.1,457"/>
                </g>
            </g>
        </svg>);
    }
}

export default TripleRoomIcon;
