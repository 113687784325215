import React from 'react';
import CardBox from '../../components/cardBox/CardBox';
import Tab from '../../components/tabs/Tab';
import TabContainer from '../../components/tabs/TabContainer';
import ParkingForm from './search/ParkingForm';
import TaxiForm from './search/TaxiForm';
import TransferForm from './search/TransferForm';
import { ConfirmationConsumer } from '../../context/Confirmation';
import { t } from 'i18next';

class Search extends React.Component {
    render() {
        return (
            <CardBox
                paddingOff
                title={ t('common:bookYourMobility') }
            >
                <ConfirmationConsumer>
                    {({ createSearchDetails, searchDetails, updateSearchDetails }) => {
                        return (
                            <TabContainer>
                                <Tab label={ t('common:parking') }>
                                    <ParkingForm
                                        createSearchDetails={ createSearchDetails }
                                        searchDetails={ searchDetails }
                                        updateSearchDetails={ updateSearchDetails }
                                    />
                                </Tab>

                                <Tab label={ t('common:taxi') }>
                                    <TaxiForm
                                        createSearchDetails={ createSearchDetails }
                                        searchDetails={ searchDetails }
                                    />
                                </Tab>

                                <Tab label={ t('common:transfer') }>
                                    <TransferForm
                                        createSearchDetails={ createSearchDetails }
                                        searchDetails={ searchDetails }
                                    />
                                </Tab>
                            </TabContainer>
                        )
                    }}
                </ConfirmationConsumer>
            </CardBox>
        )
    }
}

export default Search;
