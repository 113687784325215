import React from 'react';
import BaseIcon from './BaseIcon';

class LocationPinIcon extends BaseIcon {
    render() {
        return (<svg version='1.1' id='locationpin' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 800'>
            <g>
                <path
                    fill={ this.props.primary }
                    d='M400,9.2c-156.1,0-283.1,127-283.1,283.1c0,193.7,253.3,478.1,264.1,490.1c10.1,11.3,27.8,11.3,37.9,0
                    c10.8-12,264.1-296.4,264.1-490.1C683.1,136.2,556.1,9.2,400,9.2z M400,434.7c-78.5,0-142.4-63.9-142.4-142.4
                    S321.5,149.8,400,149.8s142.4,63.9,142.4,142.4S478.5,434.7,400,434.7z'
                />
            </g>
        </svg>);
    }
}

export default LocationPinIcon;
