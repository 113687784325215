import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTab = styled.div`
    display: ${ props => props.selected ? `block` : `none` };
`;

const Tab = (props) => (
    <StyledTab { ...props } >
        { props.children }
    </StyledTab>
)

Tab.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
}

export default Tab;
