import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: relative;
    margin: 10px 0 0 0;
    padding: 15px 20px;
    color: #856404;
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
`;

const FormErrors = ({ errors }) => {
    if (!errors || errors.length === 0) {
        return null;
    }

    return <Wrapper dangerouslySetInnerHTML={{ __html: errors.join('<br>') }} />
}

FormErrors.propTypes = {
    errors: PropTypes.array,
}

export default FormErrors;
