import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSubTitle = styled.p`
    color: DimGray;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: italic;
    line-height: 14px;
    margin: 0;
    ${ props => props.marginLeft && 'margin-left: 40px;' }
    ${ props => props.uppercase && 'text-transform: uppercase;' }
`;

const SubTitle = ({ children, uppercase, marginLeft }) => {
    return (
        <StyledSubTitle uppercase={ uppercase } marginLeft={ marginLeft }>
            { children }
        </StyledSubTitle>
    );
}

SubTitle.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    marginLeft: PropTypes.bool,
    uppercase: PropTypes.bool
}

export default SubTitle;
