import React from 'react';
import BookingAdditions from '../../containers/bookingDetails/bookingAdditions/BookingAdditions';
import BookingPersonalDetails from '../../containers/bookingDetails/bookingPersonalDetails/BookingsPersonalDetails';
import CardBox from '../../components/cardBox/CardBox';
import Divider from '../../components/Divider';

class BookingDetails extends React.Component {
    render() {
        return (
            <CardBox titleSpaceOff >
                <BookingPersonalDetails handleFormValidation={ this.props.handleFormValidation }/>

                <Divider marginTop="20px" marginBottom="20px" color="transparent" />

                <BookingAdditions />
            </CardBox>
        )
    }
}

export default BookingDetails;
