import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTableCol = styled.td`
    ${ props => props.primary && `
        font-weight: 500;
        width: 20%;
    ` }
    padding: 10px 0 10px;
    vertical-align: top;
    
    &:last-of-type {
        padding-left: 20px;
    }
`;

const TableCol = ({ value, primary }) => {
    return (
        <StyledTableCol primary={ primary }>
            { value }
        </StyledTableCol>
    );
}

TableCol.propTypes = {
    value: PropTypes.string,
    primary: PropTypes.bool
}

export default TableCol;