import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPropertiesLabel = styled.p`
    color: grey;
    font-size: 14px;
    margin: 0;
`;

const PropertiesLabel = ({ title }) => {
    return (
        <StyledPropertiesLabel>
            { title }
        </StyledPropertiesLabel>
    );
}

PropertiesLabel.propTypes = {
    title: PropTypes.string,
}

export default PropertiesLabel;
