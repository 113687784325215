import React from 'react';
import PropTypes from 'prop-types';
import Comparison from '../pages/Comparison';
import { ComparisonProvider } from '../context/Comparison';
import { ConfirmationProvider } from '../context/Confirmation';
import Confirmation from '../pages/Confirmation';
import PageNotExists from '../components/PageNotExists';
import PageWrapper from '../components/PageWrapper';

class Page extends React.Component {
    components = {
        comparison: Comparison,
        confirmation: Confirmation,
        empty: PageNotExists,
    };

    render() {
        const PageComponent = this.components[this.props.page];

        return (
            <PageWrapper>
                <ComparisonProvider>
                    <ConfirmationProvider>
                        <PageComponent />
                    </ConfirmationProvider>
                </ComparisonProvider>
            </PageWrapper>
        )
    }
}

Page.defaultProps = {
    page: 'empty',
}

Page.propTypes = {
    page: PropTypes.string.isRequired,
}

export default Page;
