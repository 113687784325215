import React from 'react';
import MapLabel from './MapLabel';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StaticGoogleMap, Direction } from 'react-static-google-map';

const StyledStaticGoogleMap = styled(StaticGoogleMap)`
    display: block;
    width: 100%;
    min-width: 300px;
`

// Used a pure component because the map kept re-rendering on hover, this mitigates that.
class DirectionMaps extends React.PureComponent {
    render() {
        const { label, size, origin, destination } = this.props;

        return (
            <div>
                <StyledStaticGoogleMap
                    size={ size }
                    apiKey={ process.env.GOOGLE_MAPS_KEY }
                >
                    <Direction
                        origin={ origin }
                        destination={ destination }
                    />
                </StyledStaticGoogleMap>

                { label && <MapLabel title={ label } /> }
            </div>
        )
    }
}

DirectionMaps.propTypes = {
    destination: PropTypes.string,
    label: PropTypes.string,
    origin: PropTypes.string,
    size: PropTypes.string.isRequired,
}

export default DirectionMaps;
