import React from 'react';
import PropTypes from 'prop-types';
import More from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';

const StyledListItem = styled.li`
    display: inline-block;
    height: 30px;
    margin-right: 5px;
    margin-top: 5px;
    vertical-align: top;
    width: 30px;
`;

const StylesMoreIcon = styled(More)`
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    background-color: #A1BCC0;
    height: 30px !important;
    width: 30px !important;
    padding: 0;
`;

const Property = ({ properties }) => {
    if (!properties.length) {
        return null;
    }

    const PropertiesList = properties.map((property, key) => {
        return <div key={ `property_${key}` }>{ property.name }</div>
    });

    return (
        <StyledListItem>
            <Tooltip
                title={ PropertiesList }
            >
                <StylesMoreIcon />
            </Tooltip>
        </StyledListItem>
    );
}

Property.propTypes = {
    properties: PropTypes.array,
}

export default Property;
