import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PropertyIcon from '../../../assets/PropertyIcon';
import Tooltip from '@material-ui/core/Tooltip';

const StyledProperty = styled.li`
    background-color: #F1F3F4;
    display: inline-block;
    line-height: 0;
    margin-right: 5px;
    margin-top: 5px;
    height: 30px;
    width: 30px;
    text-align: center;
    vertical-align: top;
`;

const Property = ({ name, id }) => {
    return (
        <StyledProperty>
            <Tooltip title={ name }>
                {/* This div is need for the placement of the tooltip */}
                <div>
                    <PropertyIcon id={ id } />
                </div>
            </Tooltip>
        </StyledProperty>
    );
}

Property.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
}

export default Property;
