import React from 'react';
import BaseIcon from './BaseIcon';

class CarOutdoorIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="car-outdoor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path fill={ this.props.primary } d="M674.9,184.7c0,40.6-32.9,73.5-73.5,73.5s-73.5-32.9-73.5-73.5s32.9-73.5,73.5-73.5S674.9,144.1,674.9,184.7z"/>
            <path fill={ this.props.primary } d="M651.3,460c-2.9-3.5-7.1-5.6-11.7-5.6h-57.2c-14.1-37.3-32.5-73.7-53.5-87.5c-72.3-47.6-264.3-47.6-336.6,0
                c-20.9,13.8-39.2,50.4-53.5,87.5H81.6c-4.6,0-8.8,2.1-11.7,5.6c-2.8,3.5-4,8.1-3.1,12.6l8.5,41c1.4,7,7.7,12,14.8,12H107
                c-16.3,18.8-24.3,42.7-24.4,66.5c-0.1,29.5,11,56,31.4,74.7c0.3,0.1,0.4,0.3,0.7,0.6v57.5c0,12.4,10.1,22.6,22.6,22.6H190
                c12.4,0,22.6-10.2,22.6-22.6v-23h295.7v23c0,12.4,10.1,22.6,22.6,22.6h52.8c12.4,0,22.6-10.2,22.6-22.6v-56.4
                c21.6-19.8,32-47,32.1-74.4c0.1-24.6-8.2-49.3-25.5-68.5h18.1c7.1,0,13.3-5,14.8-12l8.5-41C655.4,468.2,654.2,463.6,651.3,460z
                M217.3,404.7c57.7-38,229.2-38,286.9,0c11.2,7.4,25.5,37,38.8,73.4H178.5C191.6,441.7,206,412.1,217.3,404.7z M149.7,597.8
                c0-24.6,20-44.4,44.4-44.4c24.6,0,44.4,19.8,44.4,44.4s-19.8,44.4-44.4,44.4S149.7,622.4,149.7,597.8z M527.9,642.2
                c-24.6,0-44.4-19.8-44.4-44.4c0-24.6,20-44.4,44.4-44.4c24.6,0,44.4,19.8,44.4,44.4S552.5,642.2,527.9,642.2z"/>
            <rect fill={ this.props.primary } x="590.9" y="52.6" width="20.9" height="33.3"/>
            <rect fill={ this.props.primary } x="533.2" y="68" transform="matrix(0.866 -0.5 0.5 0.866 30.4796 283.1546)" width="20.9" height="33.3"/>
            <rect fill={ this.props.primary } x="490.9" y="110.3" transform="matrix(0.5 -0.866 0.866 0.5 140.7187 497.6824)" width="20.9" height="33.3"/>
            <rect fill={ this.props.primary } x="469.2" y="174.3" width="33.3" height="20.9"/>
            <rect fill={ this.props.primary } x="484.7" y="232" transform="matrix(0.866 -0.5 0.5 0.866 -54.0735 283.1672)" width="33.3" height="20.9"/>
            <rect fill={ this.props.primary } x="527" y="274.3" transform="matrix(0.5 -0.866 0.866 0.5 25.2027 613.1801)" width="33.3" height="20.9"/>
            <rect fill={ this.props.primary } x="590.9" y="283.6" width="20.9" height="33.3"/>
            <rect fill={ this.props.primary } x="648.7" y="268.1" transform="matrix(0.866 -0.5 0.5 0.866 -54.0715 367.7406)" width="20.9" height="33.3"/>
            <rect fill={ this.props.primary } x="690.9" y="225.8" transform="matrix(0.5 -0.866 0.866 0.5 140.7122 728.6905)" width="20.9" height="33.3"/>
            <rect fill={ this.props.primary } x="700.2" y="174.3" width="33.3" height="20.9"/>
            <rect fill={ this.props.primary } x="684.8" y="116.5" transform="matrix(0.866 -0.5 0.5 0.866 30.4846 367.7248)" width="33.3" height="20.9"/>
            <rect fill={ this.props.primary } x="642.5" y="74.2" transform="matrix(0.5 -0.866 0.866 0.5 256.2163 613.181)" width="33.3" height="20.9"/>
        </svg>);
    }
}

export default CarOutdoorIcon;
