import { hasIcon } from '../../../assets/PropertyIcon';

const propertiesSplit = (unfilteredProperties) => {
    let propertiesWithIcon = unfilteredProperties.filter((property) => {
        return hasIcon(property.id);
    });

    let propertiesWithoutIcon = unfilteredProperties.filter((property) => {
        return !hasIcon(property.id);
    });

    if (propertiesWithIcon.length > 4) {
        // If the propertiesWithIcon has more than 4 properties then the surplus will be pushed onto the propertiesWithoutIcon array.
        propertiesWithoutIcon.push(...propertiesWithIcon.slice(4));

        // Remove the surplus
        propertiesWithIcon = propertiesWithIcon.slice(0, 4);
    }

    return [propertiesWithIcon, propertiesWithoutIcon];
}

export default propertiesSplit;
