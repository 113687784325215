import apiClient from './apiClient';

export default (function () {
    return {
        confirmBooking: (id) => {
            return apiClient.create(`${process.env.API_URL}/bookings/${ id }/confirm`);
        },

        fetchCategory: (params) => {
            return apiClient.read(`${process.env.API_URL}/categories`, params, true);
        },

        fetchParkingCatalogue: (params) => {
            return apiClient.read(`${process.env.API_URL}/catalogue/parking`, params);
        },

        fetchTaxiCatalogue: (params) => {
            return apiClient.read(`${process.env.API_URL}/catalogue/taxi`, params);
        },

        fetchTransferCatalogue: (params) => {
            return apiClient.read(`${process.env.API_URL}/catalogue/transfer`, params);
        },

        fetchPointsOfInterest: (params) => {
            return apiClient.read(`${process.env.API_URL}/points-of-interest`, params, true);
        },

        fetchProfile: () => {
            return apiClient.read(`${process.env.API_URL}/@me/profile`);
        },

        fetchCurrentAccount: () => {
            return apiClient.read(`${process.env.API_URL}/current-account`);
        },

        fetchPropertyGroups: (params) => {
            return apiClient.read(`${process.env.API_URL}/property-groups`, params, true);
        },

        finaliseBooking: (params) => {
            return apiClient.create(`${process.env.API_URL}/bookings`, params);
        },
    };
})();
