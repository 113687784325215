const filterResults = (results, activeFilters, activeSubCategories) => {
    // If there are no active filters, return the initial results.
    if (!activeFilters.length && !activeSubCategories.length) {
        return results;
    }

    // Filter the results
    return results.filter(
        // For every result.
        result => {
            // Default statuses.
            let filterStatus = true;
            let subCategoryStatus = true;

            // Loop through all the active filters.
            if (activeFilters.length) {
                for (let i = 0, max = activeFilters.length; i < max; i++) {
                    const property = result.properties.find(function (property) {
                        return property.id === parseInt(activeFilters[i]);
                    });

                    // If the property is not present on the product skip this result
                    if (!property) {
                        filterStatus = false;
                    }
                }
            }

            // Loop through all the active sub categories. Match any of the selected sub categories.
            if (activeSubCategories.length) {
                subCategoryStatus = false;

                activeSubCategories.forEach(
                    activeSubCategory => {
                        // Reset the status based on the last known status and check if the record sub category ID is equal to the active sub category.
                        subCategoryStatus = (
                            subCategoryStatus || result.subcategory_id === Number(activeSubCategory)
                        );
                    }
                );
            }

            // Combine the two statuses, if either of the two are false the result will be filtered out.
            const status = filterStatus && subCategoryStatus;

            return status;
        }
    );
}

export default filterResults;
