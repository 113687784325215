import React from 'react';
import BaseIcon from './BaseIcon';

class HospitalIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="hospital" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                fill={ this.props.primary }
                d="M385,199.2h-72.4V127c0-11-9-20-20-20h-73.3c-11,0-20,9-20,20v72.2H127c-11,0-20,9-20,20v73.5c0,11,9,20,20,20h72.4V385
                c0,11,9,20,20,20h73.3c11,0,20-9,20-20v-72.4H385c11,0,20-9,20-20v-73.5C405,208.1,396.1,199.2,385,199.2z"
            />
            <path
                fill={ this.props.primary }
                d="M403.8,53.1c30.4,0,55.1,24.7,55.1,55.1v295.5c0,30.4-24.7,55.1-55.1,55.1H108.2c-30.4,0-55.1-24.7-55.1-55.1V108.2
                c0-30.4,24.7-55.1,55.1-55.1H403.8 M403.8,2.8H108.2C50.1,2.8,2.8,50.1,2.8,108.2v295.5c0,58.1,47.3,105.4,105.4,105.4h295.5
                c58.1,0,105.4-47.3,105.4-105.4V108.2C509.2,50.1,461.9,2.8,403.8,2.8L403.8,2.8z"
            />
        </svg>);
    }
}

export default HospitalIcon;
