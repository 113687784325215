import React from 'react';
import DistanceAndBearing from './DistanceAndBearing';
import MapLabel from './MapLabel';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StaticGoogleMap, Marker } from 'react-static-google-map';
import _ from 'lodash';

const StyledStaticGoogleMap = styled(StaticGoogleMap)`
    display: block;
`

const Wrapper = styled.div`
    position: relative;
`;

const Map = ({ label, lng, lat, size, poi }) => {
    return (
        <Wrapper>
            { _.get(poi, 'coordinates') &&
                <DistanceAndBearing
                    firstCoordinates={ poi.coordinates }
                    poiName={ poi.name }
                    secondCoordinates={{
                        latitude: lat,
                        longitude: lng,
                    }}
                />
            }
            <StyledStaticGoogleMap
                size={ size }
                apiKey={ process.env.GOOGLE_MAPS_KEY }
                zoom={ 10 }
            >
                <Marker
                    location={ lat + `,` + lng }
                    label={ label }
                    color="0xC94A59"
                />
            </StyledStaticGoogleMap>

            { label && <MapLabel title={ label } /> }
        </Wrapper>
    )
}

Map.propTypes = {
    label: PropTypes.string,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    poi: PropTypes.object,
    size: PropTypes.string.isRequired,
}

export default Map;
