import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCSSGridItem = styled.div`
    grid-row: 1;

    // IE 10/11 Fallback
    grid-column-span: ${ props => props.size };

    grid-column: span ${ props => props.size };
`;

const CSSGridItem = ({ size, children }) => {
    return (
        <StyledCSSGridItem
            size={ size }
        >
            { children }
        </StyledCSSGridItem>
    );
}

CSSGridItem.defaultProps = {
    size: 1,
}

CSSGridItem.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    size: PropTypes.number
}

export default CSSGridItem;
