import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledProductCategory = styled.p`
    color: grey;
    font-size: 16px;
    margin: 0 0 20px 0;
`;

const ProductCategory = ({ category }) => {
    return (
        <StyledProductCategory>
            { category }
        </StyledProductCategory>
    );
}

ProductCategory.propTypes = {
    category: PropTypes.string.isRequired,
}

export default ProductCategory;
