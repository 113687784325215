import React from 'react';
import BaseIcon from './BaseIcon';

class PlaneIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="plane" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                fill={ this.props.primary }
                d="M500.8,11.7l-0.5-0.5c-25.8-24.6-77.4,19.1-77.4,19.1L329.2,104l-98.6-17.5c14.2-20.6,18.5-41.1,9-50.6
                c-12.3-12.3-43-1.5-68.6,24.1c-4.5,4.5-8.5,9.1-12,13.8L83.2,60.4l-50.4,43l205.6,94.3L106.6,357l-82.1-15.4L3.7,362.4l145.9,145.9
                l20.8-20.8L155,405.4l159.3-131.8l94.3,205.6l43-50.4L438.1,353c4.7-3.5,9.3-7.5,13.8-12c25.6-25.6,36.4-56.3,24.1-68.6
                c-9.5-9.5-30-5.2-50.6,9L408,182.8l73.7-93.7C481.7,89.2,525.4,37.6,500.8,11.7z"
            />
        </svg>);
    }
}

export default PlaneIcon;
