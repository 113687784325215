import React from 'react';
import BaseIcon from './BaseIcon';

class TeslaChargingIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="tesla-charging" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M669.7,174v-55.3h-33.2V174h-44.3v-55.3H559V174h-16.6v62c0,34,23.7,62.5,55.3,70v229.4c0,11.9-9.7,21.6-21.6,21.6
                c-11.9,0-21.6-9.7-21.6-21.6V408.1c0-30.2-24.6-54.8-54.8-54.8h-1.1c-7.7,0-15,1.6-21.6,4.4V168.5c0-27.5-22.3-49.8-49.8-49.8H205.9
                c-27.5,0-49.8,22.3-49.8,49.8v483.7h-36.5v33.2h394.1v-33.2h-36.5V408.1c0-11.9,9.7-21.6,21.6-21.6h1.1c11.9,0,21.6,9.7,21.6,21.6
                v127.3c0,30.2,24.6,54.8,54.8,54.8s54.8-24.6,54.8-54.8V306c31.7-7.5,55.3-36,55.3-70v-62H669.7z M332,574.2l-30.8-12.3l19.7-49.2
                h-57.6l37.9-94.7l30.8,12.4l-19.7,49.1h57.6L332,574.2z M410.7,346.6H222.5V203.5l12-3.4c53.7-15.3,110.5-15.3,164.2,0.1l12,3.4
                V346.6z"
            />
        </svg>);
    }
}

export default TeslaChargingIcon;
