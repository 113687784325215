import React from 'react';
import BaseIcon from './BaseIcon';

class WheelchairIcon extends BaseIcon {
    render() {
        return (<svg version='1.1' id='wheelchair' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 800'>
            <path
                fill={ this.props.primary }
                d='M736.5,584.5l-98.4-180c-7.4-13.5-19.8-22-32.9-24.3c-5.1-1.7-10.5-2.6-16.2-2.6H439.7l-3-45.8h120.7
                c16,0,28.9-14.4,28.9-32.1c0-17.7-12.9-32.1-28.9-32.1H432.5l-3.6-56.8c-2-31-27.8-54.7-58.5-54.7c-1.3,0-2.6,0-3.9,0.1l-12.1,0.8
                c-32.3,2.1-56.8,30-54.7,62.3l13.5,205.8c2.1,31,27.9,54.7,58.5,54.7c0.7,0,1.4,0,2.2,0h1.7c1.2,0.2,2.3,0.2,3.4,0.2h204.7
                l79.2,144.9c8.8,16,24.7,25.3,40.5,25.3c6.5,0,12.7-1.5,18.6-4.8C742.2,634.1,748.7,607,736.5,584.5z'
            />

            <path
                fill={ this.props.primary }
                d='M361.3,140.1c37.6,0,68.1-30.5,68.1-68.1S398.9,4,361.3,4c-37.6,0-68.1,30.5-68.1,68.1C293.2,109.7,323.6,140.1,361.3,140.1
                z'
            />

            <path
                fill={ this.props.primary }
                d='M585.1,627.8c-12.5,19-30.9,67.1-98.6,101.5c-41.6,21.4-96.8,40.4-173.4,17.2C236.5,723.4,141.5,635.5,154,512
                c8.3-65.3,30.3-117,99.2-170.4c14.2-13.1,13.7-22,7.1-29.7c-6.5-7.7-16.6-9.5-26.1-3.6c-9.5,6-15.5,10.7-15.5,10.7
                S105.3,399.8,116,546.5c10.7,146.7,125.9,231,225.7,245.2s210.2-26.9,272-135.8c5.9-13.3,12.5-22.8,0-34.7
                C613.6,621.3,597.6,608.8,585.1,627.8z'
            />
        </svg>);
    }
}

export default WheelchairIcon;
