import React from 'react';
import CardBox from '../../../components/cardBox/CardBox';
import FilterList from './FilterList';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { t } from 'i18next';

const StyledDiv = styled.div`
    padding: 12px 0 8px;
`

const Properties = (props) => {
    const { categories, onSubCategoryCheck, activeSubCategories } = props;

    const subCategories = Object.keys(categories).map((category, key) =>
        <FilterList
            key={ `subCategories_${key}` }
            filters={ categories[key].subcategories }
            onCheck={ onSubCategoryCheck }
            checked={ activeSubCategories }
        />
    )

    if (!categories.length) {
        return null;
    }

    return (
        <CardBox
            title={ t('comparison:subcategories') }
            paddingOff
        >
            <StyledDiv>
                { subCategories }
            </StyledDiv>
        </CardBox>
    )
}

Properties.defaultProps = {
    activeFilters: [],
    categories: [],
    loading: false,
}

Properties.propTypes = {
    activeSubCategories: PropTypes.array,
    categories: PropTypes.array,
    onSubCategoryCheck: PropTypes.func.isRequired,
}

export default Properties;
