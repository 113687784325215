import React from 'react';
import PropTypes from 'prop-types';
import Properties from './filters/Properties';
import SubCategories from './filters/SubCategories';

// filters, onFilterCheck, categories, onSubCategoryCheck
const Filters = (props) => {
    const {
        onFilterCheck,
        onSubCategoryCheck,
        categories,
        activeFilters,
        activeSubCategories,
        results,
        originalResults,
        loading,
        fetchPropertyGroups,
        clearFilters,
    } = props;

    return(
        <div>
            <SubCategories
                categories={ categories }
                onSubCategoryCheck={ onSubCategoryCheck }
                activeSubCategories={ activeSubCategories }
            />

            <Properties
                results={ results }
                originalResults={ originalResults }
                onFilterCheck={ onFilterCheck }
                activeFilters={ activeFilters }
                fetchPropertyGroups={ fetchPropertyGroups }
                loading={ loading }
                clearFilters={ clearFilters }
            />
        </div>
    )
}

Filters.defaultProps = {
    activeFilters: [],
    activeSubCategories: [],
    categories: [],
    loading: false,
    originalResults: [],
    results: [],
}

Filters.propTypes = {
    activeFilters: PropTypes.array,
    activeSubCategories: PropTypes.array,
    categories: PropTypes.array,
    clearFilters: PropTypes.func.isRequired,
    fetchPropertyGroups: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onFilterCheck: PropTypes.func.isRequired,
    onSubCategoryCheck: PropTypes.func.isRequired,
    originalResults: PropTypes.array,
    results: PropTypes.array,
}

export default Filters;
