import React from 'react';
import PropTypes from 'prop-types';
import createReactContext from 'create-react-context';
import mobianApiClient from '../api/mobianApiClient';
import blackList from '../helpers/blackList';
import filterResults from '../helpers/filterResults';

// This initializes a new Context API, with default values to prevent errors and abuse.
const ComparisonContext = createReactContext({
    categories: [],
    clearResults: () => {},
    fetchParkingCatalogue: () => {},
    fetchPropertyGroups: () => {},
    fetchTaxiCatalogue: () => {},
    fetchTransferCatalogue: () => {},
    filters: {
        activeFilters: [],
        activeSubCategories: [],
    },
    originalResults: [],
    results: [],
    setFilters: () => {},
    setLoaderState: () => {},
});

// Define the consumer side to pass through all the data in the app.
export const ComparisonConsumer = ComparisonContext.Consumer;

const initialState = {
    loading: false,
    originalResults: [],
    pristine: true,
    results: [],
    categories: [],
    filters: {
        activeFilters: [],
        activeSubCategories: [],
    }
}

// Define the provider side to host all the logic and update the value of the Context API.
export class ComparisonProvider extends React.Component {
    state = initialState;

    setLoaderState = (loadingStatus) => {
        if ((loadingStatus === this.state.loading)||(!loadingStatus === !this.state.loading)){
            return;
        }

        let loading = true;

        if (!loadingStatus && this.state.loading) {
            loading = false;
        }

        this.setState({
            loading: loading,
            pristine: false,
        });
    }

    resetComparisonState = () => {
        this.setState(initialState);
    }

    clearResults = () => {
        this.setState({
            originalResults: [],
            results: [],
        });
    }

    setLastResults = (results) => {
        this.setState({
            originalResults: blackList.filterPropertyGroups(results),
            results: blackList.filterPropertyGroups(results)
        })
    }

    setFilters = (filters) => {
        this.setState({
            filters: filters,
            results: filterResults(this.state.originalResults, filters.activeFilters, filters.activeSubCategories),
        })
    }

    setCategories = (result) => {
        this.setState({
            categories: result
        })
    }

    fetchPropertyGroups = (propertyGroupIds) => {
        if (!propertyGroupIds.length) {
            return;
        }

        return mobianApiClient.fetchPropertyGroups({ id: propertyGroupIds });
    }

    comparisonPromises = (catalogue, categories) => {
        return Promise.all([catalogue, categories]).then(([res1, res2]) => {
            this.setLastResults(res1)
            this.setCategories(res2.results)
        });
    }

    fetchCategory = (categoryId) => {
        return mobianApiClient.fetchCategory(categoryId);
    }

    fetchParkingCatalogue = (payload) => {
        const categories = this.fetchCategory({ id: [1] });
        const catalogue = mobianApiClient.fetchParkingCatalogue(payload);

        return this.comparisonPromises(catalogue, categories);
    }

    fetchTaxiCatalogue = (payload) => {
        const categories = this.fetchCategory({ id: [2] });
        const catalogue = mobianApiClient.fetchTaxiCatalogue(payload);

        return this.comparisonPromises(catalogue, categories);
    }

    fetchTransferCatalogue = (payload) => {
        const categories = this.fetchCategory({ id: [3] });
        const catalogue = mobianApiClient.fetchTransferCatalogue(payload);

        return this.comparisonPromises(catalogue, categories);
    }

    render() {
        return (
            <ComparisonContext.Provider
                value={{
                    ...this.state,
                    clearResults: this.clearResults,
                    fetchParkingCatalogue: this.fetchParkingCatalogue,
                    fetchPropertyGroups: this.fetchPropertyGroups,
                    fetchTaxiCatalogue: this.fetchTaxiCatalogue,
                    fetchTransferCatalogue: this.fetchTransferCatalogue,
                    resetComparisonState: this.resetComparisonState,
                    setFilters: this.setFilters,
                    setLoaderState: this.setLoaderState,
                }}
            >
                { this.props.children }
            </ComparisonContext.Provider>
        );
    }
}

ComparisonProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

export default ComparisonProvider;
