import React from 'react';
import BookingDetailsTable from './BookingDetailsTable';
import BookingPriceTable from './BookingPriceTable';
import { ConfirmationConsumer } from '../../context/Confirmation';
import CardBox from '../../components/cardBox/CardBox';
import FlexContainer from '../../components/grid/FlexContainer';
import FlexItem from '../../components/grid/FlexItem';
import PropTypes from 'prop-types';
import { DividerVertical } from '../../components/Divider';

const BookingOverview = ( props ) => {
    return (
        <CardBox>
            <FlexContainer>
                <FlexItem size={ 2 }>
                    <BookingDetailsTable handleCheckboxChange={ props.handleCheckboxChange } />
                </FlexItem>

                <DividerVertical marginLeft="16px" />

                <FlexItem>
                    <ConfirmationConsumer>
                        { ({ cart, selectedProduct }) => {
                            return <BookingPriceTable cart={ cart } currency={ selectedProduct.price_currency } />
                        }}
                    </ConfirmationConsumer>
                </FlexItem>
            </FlexContainer>
        </CardBox>
    )
}

BookingOverview.propTypes = {
    handleCheckboxChange: PropTypes.func,
}

export default BookingOverview;
