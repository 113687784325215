import React from 'react';
import BaseIcon from './BaseIcon';

class PortIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="port" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <g>
                <path
                    fill={ this.props.primary }
                    d="M82.7,2.1c11.9,0,23.5,0,35.4,0c1.6,6.9,3.4,13.8,4.7,20.8c0.5,2.8,1.8,3.4,4.3,3.3c5.9-0.2,11.8-0.1,17.8-0.1
                    c11.2,0,18.5,6.8,18.7,18.1c0.2,13.9,0.2,27.8,0,41.7c0,3.2,0.8,5,3.9,6.1c3.3,1.1,6.4,2.7,9.5,4.2c5.7,2.6,7.7,6.3,5.9,12.4
                    c-6.1,20.5-12.4,41-18.7,61.5c-0.2,0.8-1.7,1.7-2.5,1.7c-6-0.2-11.5-2.3-16.1-5.9c-4.9-3.8-9.2-8.3-14-12.7
                    c-7.9,9.6-17.1,18.6-31.3,18.7c-14.4,0.1-23.6-8.8-32.1-19.3c-0.8,1.1-1.5,2-2.3,3c-6.4,8.1-14.2,14-24.6,16
                    c-4.7,0.9-4.9,0.8-6.2-3.6c-5.9-19.6-11.7-39.3-17.6-58.9c-2.2-7.5-0.4-11.3,6.9-14.1c3-1.1,5.9-2.4,8.9-3.3
                    c2.3-0.7,3.1-1.8,3.1-4.4c-0.1-13.6-0.3-27.2,0.1-40.8c0.1-4,1.3-8.3,3.2-11.9c2.8-5.6,7.8-8.4,14.3-8.4c6.5,0.1,13.1-0.1,19.6,0.1
                    c2.5,0.1,3.3-0.9,3.9-3.1C78.9,16.1,80.8,9.1,82.7,2.1z M147.3,83.7c0-13,0-25.5,0-38c-31.7,0-63.1,0-94.7,0c0,12.7,0,25.2,0,38.1
                    c1.3-0.5,2.3-0.9,3.3-1.3C69.8,77,83.7,71.6,97.6,66.3c1.4-0.5,3.4-0.5,4.8,0c13,5,26,10.1,39,15.2
                    C143.3,82.2,145.2,82.9,147.3,83.7z"/>
                <path
                    fill={ this.props.primary }
                    d="M181.2,197.8c-15.4-0.3-29.8-4-43.5-10.7c-1.8-0.9-3-0.9-4.7,0c-21.4,11.4-43,11.4-64.4,0.1c-1.8-1-3.1-1-5-0.1
                    c-14.1,6.9-29,10.7-44.8,10.8c18.4-5.1,33.7-15.7,49.1-26.6c2.1,1.4,4.1,2.9,6.3,4.3c18.8,12,38,11.5,56-1.6c2-1.5,3.2-1.4,5.2,0
                    c11.5,8.2,23.4,15.7,36.7,20.8C175,195.9,178.1,196.8,181.2,197.8z"/>
            </g>
        </svg>);
    }
}

export default PortIcon;
