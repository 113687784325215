import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTableCol = styled.td`
    ${ props => props.primary && 'font-weight: 500;' }
    ${ props => props.topBorder && 'border-top: solid 1px black;' }
    ${ props => props.price && 'text-align: right;' }
    padding: 10px 0 10px;

    &:last-of-type {
        padding-left: 20px;
    }
`;

const TableCol = ({ children, primary, topBorder, price }) => {
    return (
        <StyledTableCol primary={ primary } topBorder={ topBorder } price={ price }>
            { children }
        </StyledTableCol>
    );
}

TableCol.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    price: PropTypes.bool,
    primary: PropTypes.bool,
    topBorder: PropTypes.bool,
}

export default TableCol;
