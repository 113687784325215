import React from 'react';
import BaseIcon from './BaseIcon';

class BinocularsIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="binoculars" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g>
                <path
                    fill={ this.props.primary }
                    d="M117.3,481.1c60.1,0,108.7-48.8,108.7-108.7l3.4-51.9c15.1-11.5,36-13.2,52.9-2.5l3.6,54.3c0,59.9,48.6,108.7,108.7,108.7
                    s108.7-48.8,108.7-108.7c0-17.8-4.7-36.6-13.4-52.3l-64.7-189.4l-0.2-0.2l-0.2-0.6h-0.2c-3.9-32.7-27.8-59.1-59.4-66.3v-9.9
                    c0-12.6-10.2-22.8-22.8-22.8c-12.6,0-22.8,10.2-22.8,22.8V67c-28.8,11.3-49.3,39.3-49.3,72.1c0,0.1,0,0.2,0,0.3
                    c0.1,1.6,0.7,10.6,0.6,9l3.7,55.1c-11.9-2.3-24.7-1.9-37.6,2c0.1-1.6,3.9-61.1,3.7-57.8c0.1-1.6,0.7-7.8,0.7-8.7
                    c0-32-19.4-59.5-47.1-71.3V54.2c0-12.6-10.2-22.8-22.8-22.8s-22.8,10.2-22.8,22.8V63c-28.5,5.6-51,26.8-59.1,54.3h-0.3L21.8,320.5
                    l-0.2,0.3c-8.4,15.1-13,33.1-13,51.6C8.6,432.3,57.4,481.1,117.3,481.1z M472.3,372.4c0,42.9-34.8,77.6-77.6,77.6
                    c-42.7,0-77.5-34.6-77.6-77.3c0-0.2,0-0.2,0-0.3c0-0.8,0-1.7,0.2-2.5c0.8-39.1,33.5-75.2,77.5-75.2
                    C437.8,294.8,472.3,329.8,472.3,372.4z M117.5,294.8c43.1,0,77.6,35.3,77.6,77.6v0.6c-0.3,42.5-35.1,77-77.6,77
                    c-42.9,0-77.6-34.8-77.6-77.6C39.9,329.8,74.4,294.8,117.5,294.8z"
                />
                <path
                    fill={ this.props.primary }
                    d="M84.4,388.5c8.6,0,15.5-7,15.5-15.5c0-8.6,7-15.5,15.5-15.5c8.6,0,15.5-7,15.5-15.5s-7-15.5-15.5-15.5
                    c-25.7,0-46.6,20.9-46.6,46.6C68.8,381.6,75.8,388.5,84.4,388.5z"/>
                <path
                    fill={ this.props.primary }
                    d="M395.6,325.8c-25.7,0-46.6,20.9-46.6,46.6c0,8.6,7,15.5,15.5,15.5s15.5-7,15.5-15.5c0-8.6,7-15.5,15.5-15.5
                    c8.6,0,15.5-7,15.5-15.5C411.1,332.7,404.2,325.8,395.6,325.8z"/>
            </g>
        </svg>);
    }
}

export default BinocularsIcon;
