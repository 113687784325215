import React from 'react';
import BaseIcon from './BaseIcon';

class Clock6Icon extends BaseIcon {
    render() {
        return (<svg version='1.1' id='Clock6Icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 800'>
            <path
                fill={ this.props.primary }
                d='M399.7,173.4c-15.2,0-27.5,12.3-27.5,27.5v197.9v132.7c0,15.2,12.3,27.5,27.5,27.5c15.2,0,27.5-12.3,27.5-27.5V398.8V200.9
                C427.2,185.7,414.9,173.4,399.7,173.4z'
            />

            <path
                fill={ this.props.primary }
                d='M710.7,267.5c-17-40.2-41.3-76.2-72.3-107.2c-31-31-67-55.3-107.2-72.3C489.7,70.5,445.5,61.6,400,61.6
                s-89.7,8.9-131.3,26.5c-40.2,17-76.2,41.3-107.2,72.3c-31,31-55.3,67-72.3,107.2c-17.6,41.6-26.5,85.8-26.5,131.3
                s8.9,89.7,26.5,131.3c17,40.2,41.3,76.2,72.3,107.2s67,55.3,107.2,72.3C310.3,727.1,354.5,736,400,736s89.7-8.9,131.3-26.5
                c40.2-17,76.2-41.3,107.2-72.3s55.3-67,72.3-107.2c17.6-41.6,26.5-85.8,26.5-131.3S728.3,309.1,710.7,267.5z M599.5,598.3
                C546.2,651.6,475.4,681,400,681c-75.4,0-146.2-29.4-199.5-82.7c-53.3-53.3-82.7-124.2-82.7-199.5c0-75.4,29.4-146.2,82.7-199.5
                c53.3-53.3,124.2-82.7,199.5-82.7c75.4,0,146.2,29.4,199.5,82.7c53.3,53.3,82.7,124.2,82.7,199.5
                C682.2,474.2,652.8,545,599.5,598.3z'
            />
        </svg>);
    }
}

export default Clock6Icon;
