import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTabcontent = styled.div`
    padding: 20px;
`;

const TabContent = ({ children }) => {
    return (
        <StyledTabcontent>
            { children }
        </StyledTabcontent>
    )
}

TabContent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

export default TabContent;
