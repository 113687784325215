import React from 'react';
import CheckboxWithPrice from '../../../components/CheckboxWithPrice';
import FlexContainer from '../../../components/grid/FlexContainer';
import FlexItem from '../../../components/grid/FlexItem';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import legalLink from '../../../localisation/legalDocs';
import { ConfirmationConsumer } from '../../../context/Confirmation';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const BookingAdditions = () => {
    return (
        <FlexContainer>
            <FlexItem>
                <Title title={ t('confirmation:cancellationInsurance') } />

                <ConfirmationConsumer>
                    {({ selectedProduct, personal, toggleCancellationInsurance }) => {
                        return (
                            <React.Fragment>
                                <CheckboxWithPrice
                                    checked={ personal.costfreeCancellation }
                                    onChange={ () => toggleCancellationInsurance(t('confirmation:cancellationInsurance'), selectedProduct.cancellation_fee) }
                                    label={ t('confirmation:addCancellationInsurance') }
                                    price={ selectedProduct.cancellation_fee }
                                    currency={ selectedProduct.price_currency }
                                />

                                <SubTitle marginLeft>
                                    <Trans i18nKey={ `confirmation:cancellationInsuranceMessage.${ selectedProduct.category_id }` }>
                                        Cancel your booking with a full refund up to 24h before arrival. Check our <a href={ legalLink('general.termsConditions') } target="_blank" rel="noopener noreferrer" >terms and conditions</a>.
                                    </Trans>
                                </SubTitle>
                            </React.Fragment>
                        )
                    }}
                </ConfirmationConsumer>
            </FlexItem>

            <FlexItem />
        </FlexContainer>
    );
}

export default BookingAdditions;
