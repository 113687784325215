import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardBox from './cardBox/CardBox';

const Loader = () => {
    return (
        <CircularProgress
            size={ 60 }
        />
    )
}

export default Loader;
