import React from 'react';
import styled from 'styled-components';
import PointOfInterestTypeIcon, { hasIcon } from '../../../assets/PointOfInterestTypeIcon';
import { t } from 'i18next';

const PointOfInterestResultWrapper = styled.div`
    padding-top: 5px;
`;

const IconWrapper = styled.div`
    float: left;
    height: 40px;
    margin-right: 10px;
    width: 30px;
`;

const NameWrapper = styled.div`
    float: left;
    position: relative;
    top: -5px;
`

const SubTitle = styled.div`
    position: absolute;
    font-size: 12px;
    color: grey;
    left: 0;
    line-height: 20px;
`;

const Title = styled.div`
    position: absolute;
    font-size: 16px;
    top: 18px;
    left: 0;
    line-height: 20px;
`;

const PointOfInterestResult = ({ result }) => {
    return(
        <PointOfInterestResultWrapper>
            <IconWrapper>
                <PointOfInterestTypeIcon id={ result.type } />
            </IconWrapper>

            <NameWrapper>
                <SubTitle>{ t('common:poiTypes.' + result.type) }</SubTitle>
                <Title>{ result.name }</Title>
            </NameWrapper>
        </PointOfInterestResultWrapper>
    )
};
export default PointOfInterestResult;
