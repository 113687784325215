import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTabButton = styled.li`
    flex: 1;
    text-align: center;
    padding: 1rem;
    cursor: pointer;
    border-right: 1px solid rgba(158, 158, 158, .2);
    border-bottom: 2px solid rgba(158, 158, 158, .2);
    border-left: 1px solid rgba(158, 158, 158, .2);
    transition: all .2s ease-in;
    ${props => props.selected ?
        `background-color: rgba(0, 77, 64, .2);
        border-right-width: 0;
        border-left-width: 0;
        border-bottom: 2px solid rgba(0, 77, 64, 1);`
    :
        `&:hover{
            background-color: rgba(158, 158, 158, .1);
            border-bottom: 2px solid rgba(0, 77, 64, .2);
        };`
    }
`;

const TabButton = (props) => {
    const { onTabSelect, index, ...otherProps } = props;
    return (
        <StyledTabButton
            onClick={ () => {
                return onTabSelect(index)
            } }
            {...otherProps}
        >
            { props.name }
        </StyledTabButton>
    )
}

TabButton.propTypes = {
    name: PropTypes.string.isRequired,
}

export default TabButton;
