import React from 'react';
import BaseIcon from './BaseIcon';

class Open4Icon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="Open4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <g>
                <path fill={ this.props.primary } d="M681.2,534c-12.6-7.4-28.9-3.2-36.3,9.4c-24.6,42-59.8,77.1-101.8,101.7c-43.2,25.3-92.7,38.7-143.1,38.7
                    c-75.8,0-147.1-29.5-200.6-83.1c-53.6-53.6-83.1-124.9-83.1-200.6c0-75.8,29.5-147.1,83.1-200.6c38.5-38.5,86.2-64.6,138.1-76.2
                    c-2.3,9.1,0.3,19.2,7.7,26.1c5.1,4.7,11.5,7,18,7c7.1,0,14.3-2.9,19.5-8.5l36.9-40c0,0,0,0,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2
                    c0.2-0.2,0.3-0.4,0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.2c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.1,0.1-0.2,0.2-0.2
                    c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.3-0.5,0.5-0.7c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.2,0.3-0.5,0.4-0.7c0-0.1,0.1-0.1,0.1-0.2
                    c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.2-0.4,0.4-0.6c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6
                    c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.2,0.1-0.3
                    c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.3,0.1-0.4c0.1-0.2,0.1-0.3,0.2-0.5
                    c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.4c0.1-0.2,0.1-0.4,0.2-0.6c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.3,0.1-0.4
                    c0-0.2,0.1-0.4,0.1-0.6c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0-0.1,0.1-0.3,0.1-0.4c0-0.3,0.1-0.6,0.1-0.8c0-0.2,0-0.3,0.1-0.5
                    c0-0.2,0-0.4,0.1-0.5c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.5c0-0.1,0-0.1,0-0.2c0-0.2,0-0.4,0-0.5
                    c0-0.2,0-0.4,0-0.6c0,0,0-0.1,0-0.1s0-0.1,0-0.1c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.5c0-0.1,0-0.1,0-0.2c0-0.2,0-0.4,0-0.5
                    c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c0-0.2,0-0.3-0.1-0.5c0-0.3-0.1-0.6-0.1-0.8c0-0.1,0-0.3-0.1-0.4
                    c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.4-0.1-0.6c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.2-0.1-0.3c0-0.2-0.1-0.4-0.2-0.6c0-0.1-0.1-0.3-0.1-0.4
                    c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.3-0.2-0.4
                    c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1-0.1-0.2-0.1-0.3
                    c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.2-0.2-0.4-0.4-0.6
                    c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.3-0.5-0.4-0.7c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.2-0.3-0.5-0.5-0.7
                    c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2-0.2-0.3-0.4-0.5-0.6c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.3
                    c-0.2-0.2-0.3-0.4-0.5-0.5c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0-0.1-0.1l-36.9-40c-9.9-10.8-26.7-11.4-37.4-1.5
                    c-10.8,9.9-11.4,26.7-1.5,37.4l0.2,0.2c-25.7,4.3-50.7,11.6-74.9,21.8c-40.1,17-76.1,41.2-107,72.2c-30.9,30.9-55.2,66.9-72.2,107
                    C72.1,310.4,63.2,354.5,63.2,400s8.9,89.6,26.5,131.1c17,40.1,41.2,76.1,72.2,107c30.9,30.9,66.9,55.2,107,72.2
                    c41.5,17.6,85.6,26.5,131.1,26.5c59.8,0,118.5-15.9,169.9-45.9c49.8-29.1,91.5-70.8,120.8-120.6C698,557.6,693.8,541.4,681.2,534z"
                    />
                <path fill={ this.props.primary } d="M456.5,103.3c0,0.4,0.1,0.9,0.2,1.3c0.1,0.4,0.1,0.9,0.2,1.3c0.1,0.4,0.2,0.8,0.3,1.3c0.1,0.4,0.2,0.8,0.3,1.2
                    c0.1,0.4,0.3,0.8,0.4,1.2c0.1,0.4,0.3,0.8,0.5,1.2c0.2,0.4,0.3,0.8,0.5,1.2c0.2,0.4,0.4,0.8,0.6,1.2s0.4,0.8,0.6,1.1
                    c0.2,0.4,0.5,0.7,0.7,1.1s0.5,0.7,0.7,1.1c0.3,0.4,0.5,0.7,0.8,1c0.3,0.3,0.6,0.7,0.8,1s0.6,0.6,0.9,1c0.3,0.3,0.6,0.6,1,0.9
                    c0.3,0.3,0.6,0.6,1,0.9c0.3,0.3,0.7,0.5,1,0.8c0.3,0.3,0.7,0.5,1.1,0.8c0.4,0.2,0.7,0.5,1.1,0.7c0.4,0.2,0.8,0.4,1.1,0.6
                    c0.4,0.2,0.8,0.4,1.2,0.6c0.4,0.2,0.8,0.4,1.2,0.5c0.4,0.2,0.8,0.3,1.2,0.5c0.4,0.1,0.8,0.3,1.2,0.4c0.4,0.1,0.8,0.2,1.2,0.4
                    c0.4,0.1,0.8,0.2,1.3,0.3c0.4,0.1,0.9,0.2,1.3,0.2c0.4,0.1,0.9,0.1,1.3,0.2c0.4,0,0.9,0.1,1.3,0.1c0.4,0,0.9,0,1.3,0
                    c0.4,0,0.9,0,1.3,0c0.4,0,0.9,0,1.3-0.1c0.4-0.1,0.9-0.1,1.3-0.2c0.4-0.1,0.9-0.1,1.3-0.2c0.4-0.1,0.8-0.2,1.3-0.3
                    c0.4-0.1,0.8-0.2,1.2-0.4c0.4-0.1,0.8-0.3,1.2-0.4c0.4-0.1,0.8-0.3,1.2-0.5c0.4-0.2,0.8-0.3,1.2-0.5c0.4-0.2,0.8-0.4,1.2-0.6
                    c0.4-0.2,0.8-0.4,1.1-0.6c0.4-0.2,0.7-0.5,1.1-0.7c0.4-0.2,0.7-0.5,1.1-0.8c0.3-0.2,0.7-0.5,1-0.8c0.3-0.3,0.7-0.6,1-0.9
                    c0.3-0.3,0.6-0.6,0.9-0.9c0.3-0.3,0.6-0.6,0.9-1s0.6-0.6,0.8-1c0.3-0.3,0.5-0.7,0.8-1c0.2-0.3,0.5-0.7,0.7-1.1
                    c0.2-0.4,0.5-0.7,0.7-1.1c0.2-0.4,0.4-0.8,0.6-1.1c0.2-0.4,0.4-0.8,0.6-1.2c0.2-0.4,0.4-0.8,0.5-1.2c0.2-0.4,0.3-0.8,0.5-1.2
                    c0.1-0.4,0.3-0.8,0.4-1.2c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.2-0.8,0.3-1.3c0.1-0.4,0.2-0.9,0.2-1.3c0.1-0.4,0.1-0.9,0.2-1.3
                    c0-0.4,0.1-0.9,0.1-1.3c0-0.4,0-0.9,0-1.3c0-0.4,0-0.9,0-1.3c0-0.4-0.1-0.9-0.1-1.3c0-0.4-0.1-0.9-0.2-1.3
                    c-0.1-0.4-0.1-0.9-0.2-1.3c-0.1-0.4-0.2-0.8-0.3-1.3c-0.1-0.4-0.2-0.8-0.3-1.2c-0.1-0.4-0.3-0.8-0.4-1.2c-0.1-0.4-0.3-0.8-0.5-1.2
                    c-0.2-0.4-0.3-0.8-0.5-1.2c-0.2-0.4-0.4-0.8-0.6-1.2c-0.2-0.4-0.4-0.8-0.6-1.1c-0.2-0.4-0.5-0.7-0.7-1.1c-0.2-0.4-0.5-0.7-0.7-1.1
                    c-0.3-0.4-0.5-0.7-0.8-1c-0.3-0.3-0.6-0.7-0.8-1c-0.3-0.3-0.6-0.6-0.9-0.9c-0.3-0.3-0.6-0.6-0.9-0.9c-0.3-0.3-0.7-0.6-1-0.8
                    c-0.3-0.3-0.7-0.5-1-0.8c-0.3-0.3-0.7-0.5-1.1-0.8c-0.4-0.2-0.7-0.5-1.1-0.7s-0.8-0.4-1.1-0.6c-0.4-0.2-0.8-0.4-1.2-0.6
                    c-0.4-0.2-0.8-0.4-1.2-0.5c-0.4-0.2-0.8-0.3-1.2-0.5c-0.4-0.1-0.8-0.3-1.2-0.4c-0.4-0.1-0.8-0.2-1.2-0.4c-0.4-0.1-0.8-0.2-1.3-0.3
                    c-0.4-0.1-0.8-0.2-1.3-0.2c-0.4-0.1-0.9-0.1-1.3-0.2c-0.4,0-0.9-0.1-1.3-0.1c-0.9,0-1.7,0-2.6,0c-0.4,0-0.9,0-1.3,0.1
                    c-0.4,0-0.9,0.1-1.3,0.2c-0.4,0.1-0.8,0.1-1.3,0.2c-0.4,0.1-0.8,0.2-1.3,0.3c-0.4,0.1-0.8,0.2-1.2,0.4c-0.4,0.1-0.8,0.3-1.2,0.4
                    c-0.4,0.1-0.8,0.3-1.2,0.5c-0.4,0.2-0.8,0.3-1.2,0.5c-0.4,0.2-0.8,0.4-1.2,0.6c-0.4,0.2-0.8,0.4-1.1,0.6s-0.7,0.5-1.1,0.7
                    c-0.4,0.2-0.7,0.5-1.1,0.8c-0.3,0.3-0.7,0.5-1,0.8c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.3-0.6,0.6-1,0.9c-0.3,0.3-0.6,0.6-0.9,0.9
                    c-0.3,0.3-0.6,0.7-0.8,1c-0.3,0.3-0.5,0.7-0.8,1c-0.2,0.3-0.5,0.7-0.7,1.1s-0.5,0.7-0.7,1.1c-0.2,0.4-0.4,0.8-0.6,1.1
                    s-0.4,0.8-0.6,1.2c-0.2,0.4-0.4,0.8-0.5,1.2c-0.2,0.4-0.3,0.8-0.5,1.2c-0.1,0.4-0.3,0.8-0.4,1.2c-0.1,0.4-0.2,0.8-0.3,1.2
                    c-0.1,0.4-0.2,0.9-0.3,1.3c-0.1,0.4-0.2,0.9-0.2,1.3c-0.1,0.4-0.1,0.9-0.2,1.3c0,0.4-0.1,0.9-0.1,1.3c0,0.4,0,0.9,0,1.3
                    c0,0.4,0,0.9,0,1.3C456.5,102.4,456.5,102.8,456.5,103.3z"/>
                <path fill={ this.props.primary } d="M645.2,235.1c5.7,0,11.5-1.9,16.3-5.7c11.5-9,13.5-25.7,4.5-37.2c-9-11.5-25.7-13.5-37.2-4.5c-11.5,9-13.5,25.7-4.5,37.2
                    C629.6,231.6,637.4,235.1,645.2,235.1z"/>
                <path fill={ this.props.primary } d="M558.1,163.6c4.5,3,9.6,4.5,14.7,4.5c8.6,0,17-4.1,22.1-11.8c8.1-12.2,4.8-28.6-7.4-36.8c-12.2-8.1-28.6-4.8-36.8,7.4
                    C542.6,139.1,545.9,155.5,558.1,163.6z"/>
                <path fill={ this.props.primary } d="M668.1,303.9c3.9,10.8,14.1,17.5,24.9,17.5c3,0,6-0.5,9-1.6c13.8-4.9,20.9-20.1,16-33.9c-5-13.8-20.1-20.9-33.9-16
                    C670.3,275,663.1,290.2,668.1,303.9z"/>
                <path fill={ this.props.primary } d="M710.5,365.5c-14.6,0.4-26.2,12.5-25.8,27.2c0.4,14.4,12.1,25.8,26.5,25.8c0.2,0,0.5,0,0.7,0c14.6-0.4,26.2-12.5,25.8-27.2
                    C737.3,376.7,725.1,365.1,710.5,365.5z"/>
                <path fill={ this.props.primary } d="M724.4,487.3c0-0.4-0.1-0.9-0.2-1.3c-0.1-0.4-0.1-0.9-0.2-1.3c-0.1-0.4-0.2-0.8-0.3-1.3c-0.1-0.4-0.2-0.8-0.3-1.2
                    c-0.1-0.4-0.3-0.8-0.4-1.2c-0.2-0.4-0.3-0.8-0.5-1.2c-0.2-0.4-0.3-0.8-0.5-1.2c-0.2-0.4-0.4-0.8-0.6-1.2c-0.2-0.4-0.4-0.8-0.6-1.1
                    c-0.2-0.4-0.5-0.7-0.7-1.1c-0.2-0.4-0.5-0.7-0.7-1.1c-0.3-0.4-0.5-0.7-0.8-1c-0.3-0.3-0.6-0.7-0.8-1c-0.3-0.3-0.6-0.6-0.9-1
                    c-0.3-0.3-0.6-0.6-1-0.9c-0.3-0.3-0.6-0.6-1-0.8c-0.3-0.3-0.7-0.5-1-0.8c-0.3-0.3-0.7-0.5-1.1-0.8c-0.4-0.2-0.7-0.5-1.1-0.7
                    s-0.8-0.4-1.1-0.6c-0.4-0.2-0.8-0.4-1.2-0.6c-0.4-0.2-0.8-0.4-1.2-0.5c-0.4-0.2-0.8-0.3-1.2-0.5c-0.4-0.2-0.8-0.3-1.2-0.4
                    c-0.4-0.1-0.8-0.2-1.2-0.3c-0.4-0.1-0.8-0.2-1.3-0.3c-0.4-0.1-0.9-0.2-1.3-0.2c-0.4-0.1-0.9-0.1-1.3-0.2c-0.4-0.1-0.9-0.1-1.3-0.1
                    c-0.9,0-1.8,0-2.6,0c-0.4,0-0.9,0-1.3,0.1c-0.4,0-0.9,0.1-1.3,0.2c-0.4,0.1-0.8,0.1-1.3,0.2c-0.4,0.1-0.8,0.2-1.3,0.3
                    c-0.4,0.1-0.8,0.2-1.2,0.3c-0.4,0.1-0.8,0.3-1.2,0.4c-0.4,0.1-0.8,0.3-1.2,0.5c-0.4,0.2-0.8,0.3-1.2,0.5c-0.4,0.2-0.8,0.4-1.2,0.6
                    c-0.4,0.2-0.8,0.4-1.1,0.6s-0.7,0.5-1.1,0.7c-0.4,0.2-0.7,0.5-1.1,0.8c-0.3,0.3-0.7,0.5-1,0.8c-0.3,0.3-0.7,0.6-1,0.8
                    c-0.3,0.3-0.6,0.6-1,0.9c-0.3,0.3-0.6,0.6-0.9,1c-0.3,0.3-0.6,0.6-0.8,1c-0.3,0.3-0.5,0.7-0.8,1c-0.2,0.3-0.5,0.7-0.7,1.1
                    c-0.2,0.4-0.5,0.7-0.7,1.1c-0.2,0.4-0.4,0.8-0.6,1.1s-0.4,0.8-0.6,1.2c-0.2,0.4-0.4,0.8-0.5,1.2c-0.2,0.4-0.3,0.8-0.5,1.2
                    c-0.1,0.4-0.3,0.8-0.4,1.2c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.4-0.2,0.8-0.3,1.3s-0.2,0.9-0.2,1.3c-0.1,0.4-0.1,0.9-0.2,1.3
                    c0,0.4-0.1,0.9-0.1,1.3c0,0.4,0,0.9,0,1.3c0,0.4,0,0.9,0,1.3c0,0.4,0.1,0.9,0.1,1.3c0,0.4,0.1,0.9,0.2,1.3c0.1,0.4,0.1,0.9,0.2,1.3
                    c0.1,0.4,0.2,0.8,0.3,1.3c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.4,0.3,0.8,0.4,1.2c0.2,0.4,0.3,0.8,0.5,1.2c0.2,0.4,0.3,0.8,0.5,1.2
                    c0.2,0.4,0.4,0.8,0.6,1.2s0.4,0.8,0.6,1.1c0.2,0.4,0.5,0.7,0.7,1.1c0.2,0.4,0.5,0.7,0.7,1.1c0.3,0.3,0.5,0.7,0.8,1
                    c0.3,0.3,0.6,0.7,0.8,1c0.3,0.3,0.6,0.6,0.9,0.9c0.3,0.3,0.6,0.6,1,0.9c0.3,0.3,0.6,0.6,1,0.9c0.3,0.3,0.7,0.5,1,0.8
                    c0.3,0.3,0.7,0.5,1.1,0.8c0.4,0.2,0.7,0.5,1.1,0.7s0.8,0.4,1.1,0.6s0.8,0.4,1.2,0.6c0.4,0.2,0.8,0.4,1.2,0.5
                    c0.4,0.2,0.8,0.3,1.2,0.5c0.4,0.1,0.8,0.3,1.2,0.4c0.4,0.1,0.8,0.2,1.2,0.3c0.4,0.1,0.8,0.2,1.3,0.3c0.4,0.1,0.9,0.2,1.3,0.2
                    c0.4,0.1,0.9,0.1,1.3,0.2c0.4,0,0.9,0.1,1.3,0.1c0.4,0,0.9,0,1.3,0c0.4,0,0.9,0,1.3,0c0.4,0,0.9-0.1,1.3-0.1c0.4,0,0.9-0.1,1.3-0.2
                    c0.4-0.1,0.9-0.1,1.3-0.2c0.4-0.1,0.8-0.2,1.3-0.3c0.4-0.1,0.8-0.2,1.2-0.3c0.4-0.1,0.8-0.3,1.2-0.4c0.4-0.1,0.8-0.3,1.2-0.5
                    c0.4-0.2,0.8-0.4,1.2-0.5c0.4-0.2,0.8-0.4,1.2-0.6s0.8-0.4,1.1-0.6s0.7-0.5,1.1-0.7c0.4-0.2,0.7-0.5,1.1-0.8c0.3-0.2,0.7-0.5,1-0.8
                    c0.3-0.3,0.7-0.6,1-0.9c0.3-0.3,0.6-0.6,1-0.9c0.3-0.3,0.6-0.6,0.9-0.9c0.3-0.3,0.6-0.7,0.8-1c0.3-0.3,0.5-0.7,0.8-1
                    c0.2-0.3,0.5-0.7,0.7-1.1c0.2-0.4,0.5-0.7,0.7-1.1c0.2-0.4,0.4-0.8,0.6-1.1c0.2-0.4,0.4-0.8,0.6-1.2c0.2-0.4,0.4-0.8,0.5-1.2
                    c0.2-0.4,0.3-0.8,0.5-1.2c0.1-0.4,0.3-0.8,0.4-1.2c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.2-0.8,0.3-1.3c0.1-0.4,0.2-0.9,0.2-1.3
                    c0.1-0.4,0.1-0.9,0.2-1.3s0.1-0.9,0.1-1.3c0-0.4,0-0.9,0-1.3c0-0.4,0-0.9,0-1.3C724.5,488.2,724.4,487.7,724.4,487.3z"/>
                <path fill={ this.props.primary } d="M373.5,403.4c0,0.1,0,0.3,0,0.4c0,0.3,0,0.6,0.1,0.8c0,0.2,0,0.4,0.1,0.6c0,0.2,0,0.4,0.1,0.6c0,0.3,0.1,0.5,0.1,0.8
                    c0,0.1,0,0.2,0.1,0.3c0.1,0.3,0.1,0.7,0.2,1c0,0,0,0,0,0c0.5,2.5,1.4,4.9,2.6,7.1c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.2,0.4,0.3,0.6
                    c0.2,0.3,0.4,0.7,0.7,1c0,0,0,0,0,0c1.2,1.7,2.5,3.3,4.1,4.8c0,0,0,0,0.1,0c0.2,0.2,0.5,0.4,0.8,0.7c0.1,0.1,0.2,0.1,0.3,0.2
                    c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.1,0.3,0.3,0.5,0.4c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.2,0.5,0.3,0.7,0.5c0.1,0.1,0.2,0.1,0.3,0.2
                    c0.3,0.2,0.6,0.4,0.9,0.6c0,0,0.1,0,0.1,0.1l154.2,89c4.2,2.4,8.7,3.6,13.2,3.6c9.2,0,18.1-4.8,23-13.3c7.3-12.7,3-28.9-9.7-36.2
                    l-140.9-81.4V203.2c0-14.6-11.9-26.5-26.5-26.5s-26.5,11.9-26.5,26.5v199c0,0,0,0.1,0,0.1C373.5,402.7,373.5,403,373.5,403.4z"/>
            </g>
        </svg>);
    }
}

export default Open4Icon;
