import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TableRow from '../../table/TableRow';
import { t } from 'i18next';

const StyledTableBody = styled.tbody`
    width: 100%;
`;

const ParkingRows = ({ information }) => {
    return (
        <StyledTableBody>
            <TableRow
                label={ t('confirmation:bookingInformation.parking.destinationArrivalDate') }
                value={ information.destinationArrivalDate.toLocaleString() }
            />

            <TableRow
                label={ t('confirmation:bookingInformation.parking.destinationDepartureDate') }
                value={ information.destinationDepartureDate.toLocaleString() }
            />

            <TableRow
                label={ t('confirmation:bookingInformation.parking.destinationAddress') }
                value={ information.destinationAddress }
            />

            <TableRow
                label={ t('confirmation:bookingInformation.common.numberOfPersons') }
                value={ information.numberOfPersons }
            />
        </StyledTableBody>
    );
}

ParkingRows.propTypes = {
    information: PropTypes.shape({
        destinationAddress: PropTypes.string.isRequired,
        numberOfPersons: PropTypes.string,
        tripDateRange: PropTypes.array.isRequired,
    }),
}

export default ParkingRows;
