import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

const StyledResultPaper = styled(Paper)`
    align-items: stretch;
    display: flex;
    margin-bottom: 20px;
    padding: 16px;
    flex-direction: column;
`;

const ResultPaper = ({ children }) => {
    return (
        <StyledResultPaper>
            { children }
        </StyledResultPaper>
    );
}

ResultPaper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

export default ResultPaper;
