import React from 'react';
import BaseIcon from './BaseIcon';

class KeyGiveIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="key-give" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M394.3,377.8c-60.1-60.1-157.4-60.1-217.5,0c-60.1,60.1-60.1,157.4,0,217.5c60.1,60.1,157.4,60.1,217.5,0
                C454.4,535.2,454.4,437.8,394.3,377.8z M334.3,490.9c16.8,16.6,33.5,33.4,50.3,50.1c2,1.9,2,2.9,0,4.8c-14.1,13.9-28,27.8-41.9,41.9
                c-1.9,2-2.9,2-4.8,0c-16.6-16.8-33.5-33.4-50.1-50.3c-2.3-2.4-3.4-2.2-5.7,0c-17.3,17.5-34.8,34.9-52.5,52.6
                c-14.9-14.9-29.7-29.8-44.6-44.6c-1.9-1.9-0.5-2.7,0.6-3.8c8.4-8.4,16.8-16.8,25.2-25.2c8.6-8.6,17.2-17.3,25.9-25.8
                c1.8-1.7,1.7-2.5,0-4.2c-16.9-16.8-33.6-33.7-50.6-50.4c-2.4-2.4-2.2-3.5,0.1-5.7c13.7-13.4,27.2-27,40.6-40.6
                c2.5-2.5,3.7-2.8,6.3-0.1c16.5,16.8,33.2,33.3,49.8,50c1.9,1.9,2.8,2,4.8,0c16.6-16.8,33.5-33.5,50.1-50.3c2.3-2.3,3.4-2.3,5.7,0
                c14.4,14.5,28.8,28.8,43.5,43.5c-17.6,17.6-35,35.1-52.6,52.4C332.1,487.4,331.9,488.5,334.3,490.9z"
            />
            <path
                fill={ this.props.primary }
                d="M683,262.8c-8.4,39.7-32.7,73.5-66,94.5c0,15.7-12.7,28.5-28.5,28.5h-28h-28c-12.6,0-23.4-8.3-27.1-19.8
                c10.6,2.6,21.7,4,33.1,4c76,0,137.8-61.8,137.8-137.8c0-76-61.8-137.8-137.8-137.8s-137.8,61.8-137.8,137.8c0,24.7,6.6,48,18,68.1
                c-1.7,6.8-2.5,13.8-2.5,20.9v28.9c1,1,2,1.9,3,2.9c36.1,36.1,54.5,83.4,55.2,130.8h32.1v162.6c0,4.8,1.9,9.4,5.3,12.8l36.1,36.1
                c3.5,3.5,8.1,5.3,12.7,5.3c4.6,0,9.2-1.8,12.7-5.3l36.1-36.1c3.4-3.4,5.3-8,5.3-12.8V483.8h36.1c29.9,0,54.2-24.3,54.2-54.2V321.2
                C704.9,299.7,697.1,279,683,262.8z M466.1,241.7c-16.8,7.7-27.6,20.5-27.6,20.5c-2.3,2.6-4.4,5.3-6.3,8c-4.3-11.9-6.6-24.7-6.6-38
                c0-62.2,50.6-112.8,112.8-112.8S651.2,170,651.2,232.2S600.6,345,538.4,345c-10.2,0-20-1.4-29.4-3.9c5.1-7.5,13.7-12.4,23.5-12.4h28
                h13.3c37.8-13.9,65.3-49.5,67.3-91.6c-7-1.7-14.6-2.8-21.7-2.8h-58.9h-58.9C489.4,234.4,475.3,237.6,466.1,241.7z M578.6,638.7
                l-18.1,18.1l-18.1-18.1V483.6h18.1h18.1V638.7z"
            />
        </svg>);
    }
}

export default KeyGiveIcon;
