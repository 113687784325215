import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import geo from '../../helpers/geo';
import styled from 'styled-components';
import { t } from 'i18next';

const StyledDistanceAndBearing = styled.div`
    backdrop-filter: blur(2px);
    background: rgba(255,255,255,.8);
    display: block;
    padding: 5px;
    position: absolute;
    right: 5px;
    top: 5px;
`;

const DistanceAndBearing = ({ firstCoordinates, secondCoordinates, poiName }) => {
    const startBearing = geo.bearing(firstCoordinates.latitude, firstCoordinates.longitude, secondCoordinates.latitude, secondCoordinates.longitude);
    const cardinal = geo.degreesToCardinal(startBearing);
    const distance = geo.distance(
        firstCoordinates.latitude,
        firstCoordinates.longitude,
        secondCoordinates.latitude,
        secondCoordinates.longitude
    );
    const roundDistance = Math.round(distance / 100) / 10; // Round to hectometres and then divide by 10 to create kilometres with 1 decimal.
    const localeRoundDistance = roundDistance.toLocaleString(); // Change to use period or comma as decimal separator based on the users browser.

    return (
        <Tooltip
            title={ t('comparison:distanceAndBearing', {
                distance: localeRoundDistance,
                cardinal: cardinal,
                name: poiName
            } ) }
        >
            <StyledDistanceAndBearing>
                { localeRoundDistance } km
            </StyledDistanceAndBearing>
        </Tooltip>
    );
}

DistanceAndBearing.propTypes = {
    firstCoordinates: PropTypes.object.isRequired,
    poiName: PropTypes.string.isRequired,
    secondCoordinates: PropTypes.object.isRequired,
}

export default DistanceAndBearing;
