import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/**
 * @todo change prop containsLink to get rid of the ESLint error message
 */

const StyledCheckbox = styled(({ containsLink, ...otherProps }) => <Checkbox {...otherProps} />)`
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    & input{
        ${ (props) => props.containsLink && 'width: 30px !important;' }
    }

    & *{
        word-break:break-word;
    }

    & svg{
        fill: #009688 !important;
    }
`;

const StyledComponentsCheckbox = (props) => {
    return (
        <div>
            <FormControlLabel
                control={
                    <StyledCheckbox {...props} />
                }
                label={ props.label }
            />
        </div>
    )
}

StyledComponentsCheckbox.propTypes = {
    containsLink: PropTypes.bool,
}

export default StyledComponentsCheckbox;
