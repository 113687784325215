import ClientError from './errors/ClientError';
import ServerError from './errors/ServerError';
import axios from 'axios';
import soloClient from './soloClient';
import clientToken from './clientToken';
import utils from './utils';

export default (function () {
    /**
    * Create an XHR request to connect to the MOBIAN API.
    * @param {string} url
    * @param {Object} config
    * @todo Find out what we are going to do in the near future.
    * @returns {Promise}
    */
    const request = (url, config, cancelPrevious) => {
        config = config || {};
        cancelPrevious = cancelPrevious || false;

        const requestConfig = {
            url: url,
            method: config.method || 'GET',
            withCredentials: true,
            crossDomain: true,
            headers: {
                'Accept': 'application/json',
                'Client-Application-Token': clientToken.read(),
            },
        };

        if (config.params) {
            requestConfig.url = utils.buildUrl(url, config.params);
        }

        if (config.payload) {
            requestConfig.data = config.payload;
        }

        let client = axios;

        if (cancelPrevious) {
            client = soloClient;
        }

        return client(requestConfig).then(response => {
            // If the response is a 204 and will have no content, we return null.
            if (response.status === 204) {
                return null;
            }

            const statusCode = Math.floor(response.status / 100);

            // Client error
            if (statusCode === 4) {
                return response.json().then(function (response) {
                    throw new ClientError('client-error', response.status, response);
                });
            }

            // Server error
            if (statusCode === 5) {
                return response.json().then(function (response) {
                    throw new ServerError('server-error', response.status, response);
                });
            }

            return response.data;
        }, (error) => {
            // If the call is cancelled.
            if (error.__CANCEL__) {
                return;
            }

            throw new Error(error);
        });
    };

    return {
        create: (url, payload, cancelPrevious) => {
            return request(url, {
                method: 'POST',
                payload,
            }, cancelPrevious);
        },
        read: (url, params, cancelPrevious) => {
            return request(url, {
                method: 'GET',
                params,
            }, cancelPrevious);
        },
        update: (url, params, cancelPrevious) => {
            return request(url, {
                method: 'PUT',
                params,
            }, cancelPrevious);
        },
        delete: (url, cancelPrevious) => {
            return request(url, {
                method: 'DELETE',
            }, cancelPrevious);
        },
    };
})();
