import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SubTextStyled = styled.p`
    color: #757575;
    font-size: 14px;
    margin: 30px 0 0 0;
`;

const SubText = ({ children }) => {
    return (
        <SubTextStyled>
            { children }
        </SubTextStyled>
    )
}

SubText.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

export default SubText;
