import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircle from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import styled from 'styled-components';

const StatusBoxIconStyled = styled.div`
    padding-right: 20px;
`

// Styling for MUI icons is in JSS because it's original styling is all inline. Styled components can only overwrite that with '!important' but that seems a bit much.
const iconStyling = {
    height: '60px',
    width: '60px',
}

function getIcon(status){
    switch(status) {
        case 'loading':
            return (
                <CircularProgress
                    size={ 60 }
                    thickness={ 6 }
                />
            );
        case 'error':
            return (
                <WarningIcon
                    style={ iconStyling }
                    nativeColor={ '#F9A825' }
                />
            );
        case 'completed':
            return (
                <CheckCircle
                    style={ iconStyling }
                    nativeColor={ '#39897D' }
                />
            );
        default:
            return (
                <InfoIcon
                    style={ iconStyling }
                    nativeColor={ '#A1BCC0' }
                />
            );
    }
}

const StatusBoxIcon = ({ status }) => (
    <StatusBoxIconStyled>
        { getIcon(status) }
    </StatusBoxIconStyled>
)

StatusBoxIcon.propTypes = {
    status: PropTypes.string,
}

export default StatusBoxIcon;
