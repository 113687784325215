import React from 'react';
import PropTypes from 'prop-types';
import createReactContext from 'create-react-context';
import mobianApiClient from '../api/mobianApiClient';
import _ from 'lodash';
import utils from '../utils';

const ConfirmationContext = createReactContext({
    cart: [],
    createPersonalDetails: () => {},
    createSearchDetails: () => {},
    personal: [],
    searchDetails: undefined,
    selectProduct: () => {},
    selectedProduct: {},
});

const initialState = {
    bookingLoading: false,
    bookingResult: {},
    bookingMessage: {},
    cart: [],
    createPersonalDetails: {},
    personal: {
        comments: '', //nullable|string
        email: '', //required|email
        firstname: '', //required|string
        licensePlate: '', //string
        phone: '', //required|phone:phone_country
        flight: '', //nullable|string
        surname: '', //required|string
        consumerCommunication: true, //required|bool
        costfreeCancellation: false, //required|bool
        termsAndConditionsAccepted: false, //bool
    },
    searchDetails: {
        destinationAddress: '', //string
        tripDateRange: [
            null,
            null
        ],
        isTestOrder: null, //required|bool
        location: {
            origin: null,
            destination: null,
        },
        numberOfPersons: '2',
        originAddress: '', //string
        radius: 10, //int
        typeOfBooking: '', //string
    },
    selectedProduct: {},
}

export const ConfirmationConsumer = ConfirmationContext.Consumer;

// Define the provider side to host all the logic and update the value of the Context API.
export class ConfirmationProvider extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    resetConfirmationState = () => {
        this.setState(initialState);
    }

    toggleBookingLoader = () => {
        this.setState(
            prevState => ({
                bookingLoading: !prevState.bookingLoading
            })
        )
    }

    toggleCancellationInsurance = (label, cost) =>{
        this.setState(
            prevState => ({
                personal: {
                    ...prevState.personal,
                    costfreeCancellation: !prevState.personal.costfreeCancellation,
                }
            })
        )

        this.updateCart(label, cost)
    }

    updateCart = (label, price, mainProduct) => {
        if (!label || !price) {
            return null;
        }

        let oldCart = [];

        if (mainProduct) {
            // If the main product is set, we reset the cancellation insurance incase that was ticked already.
            this.setState({
                personal: {
                    ...this.state.personal,
                    costfreeCancellation: false,
                }
            });
        } else {
            oldCart = this.state.cart;
        }

        const cartItem = {
            label: label,
            price: price
        }

        // if the cart item is already in the oldCart it returns the index, else -1
        const arrayIndex = _.findIndex(oldCart, cartItem);

        // if the array already has the cartItem, remove it.
        if (arrayIndex >= 0){
            oldCart.splice(arrayIndex);
            return;
        }

        this.setState({
            cart: [
                ...oldCart,
                cartItem
            ]
        })
    }

    selectProduct = (selectedProduct) => {
        this.setState({
            selectedProduct: selectedProduct,
        })

        this.updateCart(selectedProduct.title, selectedProduct.price, true)
    }

    createSearchDetails = (newSearchDetails) => {
        this.setState(
            prevState => ({
                searchDetails: {
                    ...prevState.searchDetails,
                    ...newSearchDetails,
                }
            })
        )
    }

    createPersonalDetails = (key, value) => {
        this.setState(
            prevState => ({
                personal: {
                    ...prevState.personal,
                    [key]: value,
                }
            })
        )
    }

    finaliseBooking = () => {
        const {
            comments,
            consumerCommunication,
            costfreeCancellation,
            email,
            firstname,
            flight,
            licensePlate,
            phone,
            surname,
        } = this.state.personal

        const {
            destinationAddress,
            destinationArrivalDate,
            destinationDepartureDate,
            numberOfPersons,
            originAddress,
        } = this.state.searchDetails;

        /**
         * The `confirm_reservation` property will be set to `false`,
         * so the booking `reservation_status` will not set to `pending`.
         */
        const payload = {
            consumer_communication: consumerCommunication,
            costfree_cancellation: costfreeCancellation,
            customer_remarks: comments,
            confirm_reservation: false,
            destination_address: destinationAddress,
            destination_arrival_date: utils.formatDate(destinationArrivalDate),
            destination_departure_date: utils.formatDate(destinationDepartureDate),
            email: email,
            first_name: firstname,
            is_test_order: process.env.CREATE_TEST_BOOKINGS === 'true',
            last_name: surname,
            license_plate: licensePlate,
            number_of_cars: 1,
            number_of_persons: parseInt(numberOfPersons),
            origin_address: originAddress,
            phone: phone,
            product_id: this.state.selectedProduct.id,
            return_flight_number: flight,
        }

        // If the phone country is set in the localStorage, we add it to the payload.
        const localStoragePhoneCountry = localStorage.getItem('bookingToolPhoneCountry');
        if (localStoragePhoneCountry) {
            payload.phone_country = localStoragePhoneCountry.toUpperCase();
        }

        // Start the booking loader.
        this.toggleBookingLoader();

        mobianApiClient.finaliseBooking(utils.normalizePayload(payload)).then((bookingResult) => {
            const { id } = bookingResult;

            // We will confirm the booking.
            return mobianApiClient.confirmBooking(id).then(() => {
                return bookingResult;
            });
        }).then((bookingResult) => {

            // Handle the state message as a bookingResult message.
            this.setState({
                bookingResult: bookingResult,
            });
        }).catch((error) => {
            let message = error;

            if (error.hasOwnProperty('response')) {
                message = error.response.message;
            }

            this.setState({
                bookingMessage: message,
            });
        }).finally(() => {
            this.toggleBookingLoader();
        });
    }

    render() {
        return (
            <ConfirmationContext.Provider
                value={{
                    ...this.state,
                    updateCart: this.updateCart,
                    selectProduct: this.selectProduct,
                    createPersonalDetails: this.createPersonalDetails,
                    createSearchDetails: this.createSearchDetails,
                    finaliseBooking: this.finaliseBooking,
                    resetConfirmationState: this.resetConfirmationState,
                    toggleCancellationInsurance: this.toggleCancellationInsurance,
                }}
            >
                { this.props.children }
            </ConfirmationContext.Provider>
        )
    }
}

ConfirmationProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

export default ConfirmationProvider;
