import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CSSGridContainer from '../../../components/grid/CSSGridContainer';
import CSSGridItem from '../../../components/grid/CSSGridItem';
import DateTimePicker from '../../../components/input/DateTimePicker';
import FormErrors from '../../../components/form/FormErrors';
import LocationField from '../../../components/input/LocationField';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from '../../../components/input/SelectField';
import TabContent from '../../../components/tab/TabContent';
import TextField from '../../../components/input/TextField';
import _ from 'lodash';
import utils from '../../../utils';
import { ComparisonConsumer } from '../../../context/Comparison';
import { ConfirmationConsumer } from '../../../context/Confirmation';
import { t } from 'i18next';

class ParkingForm extends React.Component {
    constructor(props){
        super(props);

        const {
            destinationAddress,
            destinationArrivalDate,
            destinationDepartureDate,
            location,
            numberOfPersons,
            radius,
        } = props.searchDetails;

        this.state = {
            apiErrors: [],
            location: location,
            search: {
                destinationAddress: destinationAddress,
                destinationArrivalDate: destinationArrivalDate,
                destinationDepartureDate: destinationDepartureDate,
                numberOfPersons: numberOfPersons,
                radius: radius,
            },
            typeOfBooking: '',
            validated: true,
            validElements: {},
        }
    }

    checkElementValidity = (name, value) => {
        this.setState(
            (prevState) => {
                return {
                    validElements: {
                        ...prevState.validElements,
                        [name]: value,
                    }
                };
            }, this.validateForm);
    }

    validateForm = () => {
        const { validElements } = this.state;

        let result = true;

        for (var i in validElements) {
            if (validElements[i] === false) {
                result = false;
                break;
            }
        }

        this.setState({
            validated: result,
        })

        return result;
    }

    setApiErrors = (errors) => {
        this.setState({
            apiErrors: errors || [],
        });
    }

    /**
     * This handles the submit of the form.
     * The state.search object gets updated other search data.
     * The form gets validated and then we will fetch the correct Catalogue data.
     *
     * @param {function} fetchParkingCatalogue
     * @param {function} setLoaderState
     * @param {function} createSearchDetails
     * @param {function} clearResults
     * @param {string} category
     * @todo find out why we need the _cloneDeep function.
     */
    handleSubmit = (fetchParkingCatalogue, setLoaderState, createSearchDetails, clearResults, category, e) => {
        e.preventDefault();

        // Fetch the state.
        const { location, search } = this.state;

        // Until we find a better way, we clone the search object so that context doesn't have a reference to the local state.
        const searchDetails = _.cloneDeep(search);
        const params = _.cloneDeep(search);

        // Add POI id to the search for each leg of the trip.
        for (const leg of Object.keys(location)) {
            if (_.get(location[leg],'type')) {
                const POIKey = `${ leg }PointOfInterestId`;

                if (location[leg].type !== 'google-places') {
                    params[POIKey] = location[leg].id;
                } else {
                    delete params[POIKey];
                }
            }
        }

        // Change the date objects to strings
        params.destinationArrivalDate = utils.formatDate(search.destinationArrivalDate);
        params.destinationDepartureDate = utils.formatDate(search.destinationDepartureDate);

        // Add the type of booking, day and time to the searchDetails, then put all the search form field in the Confirmation context, to use it on another page or to populate the form when going back to the first page.
        searchDetails.typeOfBooking = category;
        searchDetails.location = location;

        // Validate the formValues.
        if (this.validateForm()) {
            // Trigger the loader.
            setLoaderState(true);

            // Clearing the results so that the user never sees the old results.
            clearResults();

            // Fetch the results with the state as payload.
            fetchParkingCatalogue(params).then(() => {
                this.setApiErrors([]);
                return createSearchDetails(searchDetails);
            }, (error) => {
                return this.setApiErrors(error.response.message.split('\n'));
            }).then(() => setLoaderState(false));
        }
    }

    handleAddressChange = (value, prefix, key) => {
        const { location, search } = this.state;
        let newLocation = location[prefix];
        let newSearch = {};

        if (location[prefix] == null || !value.type) {
            newLocation = {
                name: value
            };
        }

        if (value.address) {
            newSearch[key] = value.address;
        }

        if (value.id) {
            newLocation = value;
        }

        this.setState({
            location: {
                ...location,
                [prefix]: newLocation,
            },
            search: {
                ...search,
                ...newSearch
            }
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    handleSearchChange = (e) => {
        const search = this.state.search;
        search[e.target.name] = e.target.value;

        this.setState({
            search: search,
        });
    }

    render() {
        return (
            <ComparisonConsumer>
                {({ clearResults, fetchParkingCatalogue, setLoaderState, loading }) => {
                    return (
                        <ConfirmationConsumer>
                            {({ createSearchDetails }) => {
                                return (
                                    <form onSubmit={ this.handleSubmit.bind(this, fetchParkingCatalogue, setLoaderState, createSearchDetails, clearResults, 'parking') }>
                                        <TabContent>
                                            <CSSGridContainer columns={ 3 }>
                                                <CSSGridItem>
                                                    <DateTimePicker
                                                        errorText={ t('forms:formElements.parking.destinationArrivalDate.error') }
                                                        label={ t('forms:formElements.parking.destinationArrivalDate.label') }
                                                        name="destinationArrivalDate"
                                                        onChange={ this.handleSearchChange }
                                                        required
                                                        valid={ this.checkElementValidity }
                                                        value={ this.state.search.destinationArrivalDate }
                                                        warningText={ t('forms:formValidation.matchFormat', {format: "yyyy-mm-dd"}) }
                                                    />
                                                </CSSGridItem>

                                                <CSSGridItem>
                                                    <DateTimePicker
                                                        errorText={ t('forms:formElements.parking.destinationDepartureDate.error') }
                                                        label={ t('forms:formElements.parking.destinationDepartureDate.label') }
                                                        minDate={ this.state.search.destinationArrivalDate }
                                                        name="destinationDepartureDate"
                                                        onChange={ this.handleSearchChange }
                                                        required
                                                        valid={ this.checkElementValidity }
                                                        value={ this.state.search.destinationDepartureDate }
                                                        warningText={ t('forms:formValidation.matchFormat', {format: "yyyy-mm-dd"}) }
                                                    />
                                                </CSSGridItem>

                                                <CSSGridItem>
                                                    <SelectField
                                                        label={ t('forms:formElements.common.radius.label') }
                                                        name="radius"
                                                        onChange={ this.handleSearchChange }
                                                        value={ this.state.search.radius }
                                                    >
                                                        <MenuItem value={ 1 }>
                                                            &lt; 1 km
                                                        </MenuItem>

                                                        <MenuItem value={ 3 }>&lt; 3 km</MenuItem>

                                                        <MenuItem value={ 5 }>&lt; 5 km</MenuItem>

                                                        <MenuItem value={ 10 }>&lt; 10 km</MenuItem>

                                                        <MenuItem value={ 15 }>&lt; 15 km</MenuItem>

                                                        <MenuItem value={ 25 }>&lt; 25 km</MenuItem>
                                                    </SelectField>
                                                </CSSGridItem>
                                            </CSSGridContainer>

                                            <CSSGridContainer columns={ 3 }>
                                                <CSSGridItem size={ 2 }>
                                                    <LocationField
                                                        autoComplete="off"
                                                        errorText={ t('forms:formElements.parking.destinationAddress.error') }
                                                        label={ t('forms:formElements.parking.destinationAddress.label') }
                                                        fullWidth={ true }
                                                        name="destinationAddress"
                                                        onChange={ (value) => this.handleAddressChange(value, 'destination', 'destinationAddress') }
                                                        required
                                                        types={ [ 'establishment' ] }
                                                        valid={ this.checkElementValidity }
                                                        value={ this.state.location.destination }
                                                    />
                                                </CSSGridItem>

                                                <CSSGridItem>
                                                    <TextField
                                                        label={ t('forms:formElements.common.numberOfPersons.label') }
                                                        name="numberOfPersons"
                                                        onChange={ this.handleSearchChange }
                                                        valid={ this.checkElementValidity }
                                                        constraint={ /^(\d+)$/ }
                                                        value={ this.state.search.numberOfPersons }
                                                        warningText={ t('forms:formElements.common.numberOfPersons.warning') }
                                                    />
                                                </CSSGridItem>
                                            </CSSGridContainer>

                                            <FormErrors errors={ this.state.apiErrors }/>
                                        </TabContent>

                                        <Button
                                            disabled={ !this.state.validated || loading }
                                            fullWidth={ true }
                                            color="secondary"
                                            variant="contained"
                                            type="submit"
                                        >
                                            { t('forms:formElements.parking.searchButton.label') }
                                        </Button>
                                    </form>
                                )
                            }}
                        </ConfirmationConsumer>
                    );
                }}
            </ComparisonConsumer>
        )
    }
}

ParkingForm.propTypes = {
    searchDetails: PropTypes.object.isRequired,
}

export default ParkingForm;
