import React from 'react';
import BaseIcon from './BaseIcon';

class KeyKeepPaidIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="key-keep-paid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M683,262.8c-8.4,39.7-32.7,73.5-66,94.5c0,15.7-12.7,28.5-28.5,28.5h-28h-28c-12.6,0-23.4-8.3-27.1-19.8 c10.6,2.6,21.7,4,33.1,4c76,0,137.8-61.8,137.8-137.8S614.5,94.4,538.5,94.4s-137.8,61.8-137.8,137.8c0,24.7,6.6,48,18,68.1 c-1.7,6.8-2.5,13.8-2.5,20.9v28.9c1,1,2,1.9,3,2.9c36.1,36.1,54.5,83.4,55.2,130.8h32.1v162.6c0,4.8,1.9,9.4,5.3,12.8l36.1,36.1 c3.5,3.5,8.1,5.3,12.7,5.3c4.6,0,9.2-1.8,12.7-5.3l36.1-36.1c3.4-3.4,5.3-8,5.3-12.8V483.8h36.1c29.9,0,54.2-24.3,54.2-54.2V321.2 C704.9,299.7,697.1,279,683,262.8z M466.1,241.7c-16.8,7.7-27.6,20.5-27.6,20.5c-2.3,2.6-4.4,5.3-6.3,8c-4.3-11.9-6.6-24.7-6.6-38 c0-62.2,50.6-112.8,112.8-112.8S651.2,170,651.2,232.2S600.6,345,538.4,345c-10.2,0-20-1.4-29.4-3.9c5.1-7.5,13.7-12.4,23.5-12.4h28 h13.3c37.8-13.9,65.3-49.5,67.3-91.6c-7-1.7-14.6-2.8-21.7-2.8h-58.9h-58.9C489.4,234.4,475.3,237.6,466.1,241.7z M578.6,638.7 l-18.1,18.1l-18.1-18.1V483.6h18.1h18.1V638.7z"
            />
            <path
                fill={ this.props.primary }
                d="M394.3,377.8c-60.1-60.1-157.4-60.1-217.5,0c-60.1,60.1-60.1,157.4,0,217.5c60.1,60.1,157.4,60.1,217.5,0 C454.4,535.2,454.4,437.8,394.3,377.8z M323.2,585.8c-10.5,5.6-22.7,8.4-36.7,8.4c-11.5,0-21.8-1.9-30.9-5.8 c-9.1-3.8-17-9.3-23.7-16.3c-6.7-7-12.2-15.5-16.4-25.4c-4.2-9.9-7.2-21-9-33.3h-18.5v-20h16.9c-0.1-1.1-0.1-2.2-0.1-3.4 s0-2.3,0-3.5c0-1.7,0-3.3,0-4.9c0-1.6,0-3.2,0.1-4.9h-16.9v-20.2h19.4c2.2-11.9,5.8-22.6,10.7-32.2c4.9-9.6,10.8-17.7,17.8-24.5 c7-6.7,14.9-11.9,23.8-15.6c8.9-3.7,18.5-5.5,28.9-5.5c13.5,0,25.1,2.6,34.8,7.8c9.6,5.2,17.7,12.2,24.2,21.1l-12.7,13.7 c-1,1.1-2,2-3.1,2.8c-1.1,0.8-2.6,1.2-4.5,1.2c-1.4,0-2.6-0.4-3.7-1.2c-1.1-0.8-2.4-1.8-3.7-2.9c-1.4-1.2-2.9-2.4-4.5-3.8 c-1.7-1.4-3.6-2.6-5.9-3.8c-2.3-1.2-5-2.1-8.1-2.9c-3.1-0.8-6.8-1.2-11.1-1.2c-11.5,0-21.2,3.9-29.1,11.8 c-7.9,7.9-13.4,19.5-16.5,34.9h73.3v11.1c0,2.2-0.9,4.3-2.7,6.2c-1.8,1.9-4.3,2.8-7.4,2.8h-65.7c-0.1,1.7-0.1,3.3-0.1,4.9 c0,1.6,0,3.2,0,4.9c0,1.2,0,2.3,0,3.5s0,2.3,0.1,3.4H304v11c0,2.2-0.9,4.3-2.8,6.2c-1.9,1.9-4.3,2.8-7.3,2.8h-50.1 c2.7,16.7,8,29.2,15.7,37.5c7.7,8.2,17.4,12.3,28.8,12.3c7,0,12.6-0.9,16.9-2.8c4.2-1.9,7.7-4,10.4-6.4c2.7-2.3,4.9-4.5,6.7-6.4 c1.8-1.9,3.8-2.8,5.9-2.8c1.1,0,2,0.2,2.8,0.5c0.8,0.3,1.6,1,2.5,2l15.6,14.3C342.3,572.1,333.7,580.2,323.2,585.8z"
            />
        </svg>);
    }
}

export default KeyKeepPaidIcon;
