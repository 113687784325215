import React from 'react';
import BaseIcon from './BaseIcon';

class SedanIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="sedan" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M758.6,479.4v-41.5c0-6.7-4.8-12.3-11.4-13.3c-15.8-2.5-43.8-7.1-56.4-11c-18-5.6-87.8-35.1-111.9-40.6
                c-17.2-3.9-77.2-20-147.9-16v-34.6h-33.9l0,38.1c-18.3,2.8-37,7.1-55.6,13.5c-17.7,6.1-97.1,44.1-143.7,63.8
                c-13.7,0.3-149.8,28.7-157.1,48.1c-7.2,19.5-9.5,28.9-10.9,33.8c-1.3,4.9,0,28.6,10.1,45c10.1,5.7,29.3,9.5,51.8,12.2l0,0.1l0.7,0
                c0.2,0,0.4,0,0.6,0.1c0,0,0,0,0-0.1l11.3,0.4C107,609.7,134.1,635,167,635c31.3,0,57.3-22.9,62.2-52.8l319.5-6.4
                c4.3,30.6,30.6,54.2,62.4,54.2c34.7,0,62.8-28.1,63-62.7c7.1-0.7,13-1.5,17-2.3c0.1,0,0.3-0.1,0.4-0.1c0,0,0,0.1,0,0.1l1.4-0.1
                l57.1-10.7c0,0,21.9-11.6,21.9-43.7C771.8,488.9,758.6,479.4,758.6,479.4z M165.8,600.2c-15.3,0-27.7-12.4-27.7-27.7
                s12.4-27.7,27.7-27.7c15.3,0,27.7,12.4,27.7,27.7S181.1,600.2,165.8,600.2z M427.4,440.1l-124.7,3.8c2.3-18.7-8.8-22.9-8.8-22.9
                c49.6-45,149.5-46.5,149.5-46.5L427.4,440.1z M622.1,415.2c7,3.6,10.7,11.2,9.3,18.8h-31.7L568,390.5
                C591.7,400,610.8,409.3,622.1,415.2z M567.4,434.4l-113.6,3.8l10.3-63.7c58.7-0.8,92.3,12.2,92.3,12.2l16.8,27.1L567.4,434.4z
                M609.8,595.2c-15.3,0-27.7-12.4-27.7-27.7s12.4-27.7,27.7-27.7s27.7,12.4,27.7,27.7S625.1,595.2,609.8,595.2z"
            />
        </svg>);
    }
}

export default SedanIcon;
