import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../components/priceTable/Table';
import TableRow from '../../components/priceTable/TableRow';
import { t } from 'i18next';

const BookingPriceTable = ({ cart, currency }) => {
    const List = cart.map(( cartItem, key ) => {
        return(
            <TableRow cartItem={ cartItem } currency={ currency } key={ `result_${key}` } />
        );
    });

    const totalAmount = cart.reduce(( accumulator, currentItem ) => {
        accumulator.price = accumulator.price || 0

        const totalPrice = accumulator.price + currentItem.price;

        return {
            label: t('common:total'),
            price: totalPrice,
            primary: true,
            topBorder: true,
        };
    }, {});

    return (
        <Table>
            { List }

            <TableRow cartItem={ totalAmount } currency={ currency } />
        </Table>
    )
}

BookingPriceTable.defaultProps = {
    cart: [],
}

BookingPriceTable.propTypes = {
    cart: PropTypes.array.isRequired,
    currency: PropTypes.string.isRequired,
}

export default BookingPriceTable;
