import React from 'react';
import PropTypes from 'prop-types';
import FlexContainer from '../../components/grid/FlexContainer';
import FlexItem from '../../components/grid/FlexItem';
import StatusBoxIcon from './StatusBoxIcon';
import StatusBoxMessages from './StatusBoxMessages';
import StatusSubText from './StatusSubText';
import Title from '../../components/Title';
import styled from 'styled-components';

const ContainerStyled = styled(FlexContainer)`
    margin-top: 20px;
    margin-bottom: 20px;
`;

const StatusBox = ({ status, text, title, addendum }) => {
    return (
        <ContainerStyled>
            <StatusBoxIcon status={ status } />

            <FlexItem>
                <Title
                    fontSize="30px"
                    title={ title }
                />

                <StatusBoxMessages
                    text={ text }
                    status={ status }
                />

                { !!addendum && <StatusSubText>{ addendum }</StatusSubText> }
            </FlexItem>
        </ContainerStyled>
    )
}

StatusBox.propTypes = {
    addendum: PropTypes.string,
    status: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
}

export default StatusBox;
