import React from 'react';
import BaseIcon from './BaseIcon';

class KeyKeepIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="key-keep" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M394.3,377.8c-60.1-60.1-157.4-60.1-217.5,0c-60.1,60.1-60.1,157.4,0,217.5c60.1,60.1,157.4,60.1,217.5,0
                C454.4,535.2,454.4,437.8,394.3,377.8z M397.8,453.6L275.6,575.9c-2.9,2.9-6.3,4.3-10.4,4.3c-3.9,0-7.3-1.4-10.1-4.3l-81.6-81.5
                c-2.7-2.7-4.1-6.1-4.1-10.1c0-4.2,1.3-7.7,4.1-10.4l20.5-20.3c2.9-2.9,6.2-4.3,10.1-4.3s7.3,1.4,10.1,4.3l50.9,50.9l91.9-91.7
                c2.9-2.9,6.2-4.3,10.1-4.3s7.3,1.4,10.1,4.3l20.5,20.3c2.7,2.7,4.1,6.2,4.1,10.4C401.9,447.6,400.6,450.9,397.8,453.6z"
            />
            <path
                fill={ this.props.primary }
                d="M683,262.8c-8.4,39.7-32.7,73.5-66,94.5c0,15.7-12.7,28.5-28.5,28.5h-28h-28c-12.6,0-23.4-8.3-27.1-19.8
                c10.6,2.6,21.7,4,33.1,4c76,0,137.8-61.8,137.8-137.8c0-76-61.8-137.8-137.8-137.8s-137.8,61.8-137.8,137.8c0,24.7,6.6,48,18,68.1
                c-1.7,6.8-2.5,13.8-2.5,20.9v28.9c1,1,2,1.9,3,2.9c36.1,36.1,54.5,83.4,55.2,130.8h32.1v162.6c0,4.8,1.9,9.4,5.3,12.8l36.1,36.1
                c3.5,3.5,8.1,5.3,12.7,5.3c4.6,0,9.2-1.8,12.7-5.3l36.1-36.1c3.4-3.4,5.3-8,5.3-12.8V483.8h36.1c29.9,0,54.2-24.3,54.2-54.2V321.2
                C704.9,299.7,697.1,279,683,262.8z M466.1,241.7c-16.8,7.7-27.6,20.5-27.6,20.5c-2.3,2.6-4.4,5.3-6.3,8c-4.3-11.9-6.6-24.7-6.6-38
                c0-62.2,50.6-112.8,112.8-112.8S651.2,170,651.2,232.2S600.6,345,538.4,345c-10.2,0-20-1.4-29.4-3.9c5.1-7.5,13.7-12.4,23.5-12.4h28
                h13.3c37.8-13.9,65.3-49.5,67.3-91.6c-7-1.7-14.6-2.8-21.7-2.8h-58.9h-58.9C489.4,234.4,475.3,237.6,466.1,241.7z M578.6,638.7
                l-18.1,18.1l-18.1-18.1V483.6h18.1h18.1V638.7z"
            />
        </svg>);
    }
}

export default KeyKeepIcon;
