import React from 'react';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import BookingDetails from '../containers/bookingDetails/BookingDetails';
import BookingOverview from '../containers/bookingOverview/BookingOverview';
import BookingStatus from '../containers/bookingStatus/BookingStatus';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepbuttons from '../components/StepButtons';
import Stepper from '@material-ui/core/Stepper';
import ViewBookingIcon from '@material-ui/icons/DoneAll';
import styled from 'styled-components';
import { ComparisonConsumer } from '../context/Comparison';
import { ConfirmationConsumer } from '../context/Confirmation';
import { GlobalConsumer } from '../context/Global';
import { t } from 'i18next';

const StyledStepper = styled(Stepper)`
    background: transparent !important;
`;

class FunnelStepper extends React.Component {
    state = {
        validated: false,
        stepIndex: 0,
    };

    handleFormValidation = value => {
        this.setState({
            validated: value
        })
    }

    handleChangePage = (changePage, value) => {
        changePage(value);
    }

    handlePagination = (mutation) => {
        this.setState({
            stepIndex: this.state.stepIndex + mutation,
        });
    };

    getStepContent(stepIndex) {
        switch ( stepIndex ) {
            case 0:
                return <BookingDetails handleFormValidation={ this.handleFormValidation }/>
            case 1:
                return <BookingOverview />
            case 2:
                return <BookingStatus />
            default:
                return t('confirmation:selectBookingFirst');
        }
    }

    makeAnotherBooking = ( changePage, resetConfirmationState, resetComparisonState ) => {
        resetConfirmationState();

        resetComparisonState();

        this.handleChangePage(changePage, 'comparison');
    }

    goToBooking = (id) => {
        // This should be redone, so that the react router handles it.
        window.open( `${process.env.DASHBOARD_URL}/#/orders/${ id }/show`, `_blank` );
    }

    getStepButtons(stepIndex, validated, changePage) {
        switch (stepIndex) {
            case 0:
                return (
                    <Stepbuttons>
                        <Button onClick={ () => { this.handleChangePage(changePage, 'comparison') } }>
                            <ArrowLeft />
                            { t('confirmation:backToOverview') }
                        </Button>

                        <Button
                            color="primary"
                            disabled={ !validated }
                            onClick={ () => { this.handlePagination(+1) } }
                            variant="contained"
                        >
                            { t('common:next') }
                            <ArrowRight />
                        </Button>
                    </Stepbuttons>
                )
            case 1:
                return (
                    <ConfirmationConsumer>
                        {({ finaliseBooking, personal }) => {
                            return (
                                <Stepbuttons>
                                    <Button onClick={ () => { this.handlePagination(-1) } }>
                                        <ArrowLeft />

                                        { t('common:back') }
                                    </Button>

                                    <Button
                                        color="primary"
                                        disabled={ !personal.termsAndConditionsAccepted }
                                        onClick={ () => { finaliseBooking(this.handlePagination(+1)) } }
                                        variant="contained"
                                    >
                                        { t('confirmation:bookNow') }

                                        <ArrowRight />
                                    </Button>
                                </Stepbuttons>
                            )
                        }}
                    </ConfirmationConsumer>
                )
            case 2:
                return (
                    <ConfirmationConsumer>
                        {({ resetConfirmationState, bookingResult, bookingLoading }) => {
                            if ( bookingLoading ) {
                                return null;
                            }

                            if ( bookingResult.reservation_status ){
                                return (
                                    <ComparisonConsumer>
                                        {({ resetComparisonState }) => {
                                            return (
                                                <Stepbuttons>
                                                    <Button onClick={ () => { this.goToBooking(bookingResult.id) } }>
                                                        <ViewBookingIcon />

                                                        { t('confirmation:viewBooking') }
                                                    </Button>

                                                    <Button
                                                        color="primary"
                                                        onClick={ () => { this.makeAnotherBooking(changePage, resetConfirmationState, resetComparisonState ) } }
                                                        variant="contained"
                                                    >
                                                        { t('confirmation:makeAnotherBooking') }

                                                        <ArrowRight />
                                                    </Button>
                                                </Stepbuttons>
                                            )
                                        }}
                                    </ComparisonConsumer>
                                )
                            }

                            return (
                                <Stepbuttons>
                                    <Button
                                        color="primary"
                                        onClick={ () => { this.handlePagination(-1) } }
                                        variant="contained"
                                    >
                                        <ArrowLeft />

                                        { t('common:back') }
                                    </Button>
                                </Stepbuttons>
                            )
                        }}
                    </ConfirmationConsumer>
                )
            default:
                return (
                    <Stepbuttons>
                        <Button onClick={ () => { this.handlePagination(-1) } }>
                            <ViewBookingIcon />

                            { t('common:back') }
                        </Button>

                        <Button
                            color="primary"
                            onClick={ () => { this.handlePagination(+1) } }
                            variant="contained"
                        >
                            { t('common:next') }

                            <ArrowRight />
                        </Button>
                    </Stepbuttons>
                )
        }
    }

    renderContent() {
        const { stepIndex, validated } = this.state;

        return (
            <div>
                { this.getStepContent(stepIndex) }

                <GlobalConsumer>
                    {({ changePage }) => {
                        return this.getStepButtons(stepIndex, validated, changePage)
                    }}
                </GlobalConsumer>
            </div>
        );
    }

    render() {
        return (
            <div>
                <StyledStepper activeStep={ this.state.stepIndex }>
                    <Step>
                        <StepLabel>{ t('confirmation:customerDetails') }</StepLabel>
                    </Step>

                    <Step>
                        <StepLabel>{ t('confirmation:bookingOverview') }</StepLabel>
                    </Step>

                    <Step>
                        <StepLabel>{ t('confirmation:confirmBooking') }</StepLabel>
                    </Step>
                </StyledStepper>

                { this.renderContent() }
            </div>
        );
    }
}

export default FunnelStepper;
