import React from 'react';
import Filters from '../containers/comparison/Filters';
import FlexContainer from '../components/grid/FlexContainer';
import FlexItem from '../components/grid/FlexItem';
import Results from '../containers/comparison/Results';
import TripDetails from '../containers/comparison/tripDetails/TripDetails';
import Search from '../containers/comparison/Search';
import { ComparisonConsumer } from '../context/Comparison';
import { ConfirmationConsumer } from '../context/Confirmation';

class Comparison extends React.Component {
    clearFilters = (setFilters) => {
        setFilters({
            activeFilters: [],
            activeSubCategories: [],
        })
    }

    onFilterCheck = (event, filters, setFilters) => {
        if (!event) {
            return null;
        }

        const name = event.target.name;
        let oldFilters = filters.activeFilters;

        // Add filter
        if (!oldFilters.includes(name)) {
            oldFilters = [ ...oldFilters, name ]
        }

        // Remove filter
        else {
            oldFilters = oldFilters.filter(item => item !== name)
        }

        setFilters({
            ...filters,
            activeFilters: oldFilters
        });
    };

    onSubCategoryCheck = (event, filters, setFilters) => {
        if (!event) {
            return null;
        }

        const name = event.target.name;
        let newSubCategoryFilters = filters.activeSubCategories;

        //Add filter
        if (!newSubCategoryFilters.includes(name)) {
            newSubCategoryFilters = [ ...newSubCategoryFilters, name ]
        }

        //Remove filter
        else {
            newSubCategoryFilters = newSubCategoryFilters.filter(item => item !== name)
        }

        setFilters({
            ...filters,
            activeSubCategories: newSubCategoryFilters
        });
    };

    render() {
        return (
            <div>
                <Search />

                <ComparisonConsumer>
                    {({ categories, fetchPropertyGroups, filters, loading, originalResults, pristine, results, setFilters }) => {

                        if (pristine) {
                            return null;
                        }

                        return (
                            <FlexContainer>
                                <FlexItem size={ 2 }>
                                    <Results
                                        activeFilters={ filters.activeFilters }
                                        activeSubCategories={ filters.activeSubCategories }
                                        loading={ loading }
                                        results={ results }
                                    />
                                </FlexItem>

                                <FlexItem>
                                    <ConfirmationConsumer>
                                        {({ searchDetails }) => {
                                            const { typeOfBooking, originAddress, destinationAddress } = searchDetails;

                                            if (typeOfBooking === "parking" || originAddress === "" || destinationAddress === "") {
                                                return null;
                                            }

                                            return (
                                                <TripDetails
                                                    originAddress={ originAddress }
                                                    destinationAddress={ destinationAddress }
                                                />
                                            )
                                        }}
                                    </ConfirmationConsumer>

                                    <Filters
                                        categories={ categories }
                                        clearFilters={ () => this.clearFilters(setFilters) }
                                        fetchPropertyGroups={ fetchPropertyGroups }
                                        loading={ loading }
                                        onFilterCheck={ (e) => this.onFilterCheck(e, filters, setFilters) }
                                        onSubCategoryCheck={ (e) => this.onSubCategoryCheck(e, filters, setFilters) }
                                        originalResults={ originalResults }
                                        activeFilters={ filters.activeFilters }
                                        activeSubCategories={ filters.activeSubCategories }
                                        results={ results }
                                    />
                                </FlexItem>
                            </FlexContainer>
                        )
                    }}
                </ComparisonConsumer>
            </div>
        )
    }
}

export default Comparison;
