import React from 'react';
import BaseIcon from './BaseIcon';

class SecurityCamIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="security-cam" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M567.9,386l59.1-6.2c6.4-0.7,12-4.4,15.2-9.9l27.1-47.2c5.5-9.6,2.2-21.9-7.4-27.3L328.7,105.2c-9.6-5.5-21.8-2.1-27.3,7.5
                l-44.2,77.5c-5.5,9.6-2.1,21.8,7.5,27.3l291.2,166.1C559.5,385.6,563.7,386.5,567.9,386z M350.9,192.4L350.9,192.4
                c9.2,5.3,12.4,16.9,7.2,26.1l0,0c-5.2,9.1-16.8,12.3-26,7.1l-0.1,0c-9.2-5.2-12.3-16.9-7.1-26l0,0
                C330.2,190.4,341.8,187.2,350.9,192.4z"
            />
            <path
                fill={ this.props.primary }
                d="M541.1,495.3l28.7-50.3c5.2-9.1-2.1-20.2-12.5-19.1h0c-2.7,0.3-5.4-0.3-7.7-1.6L260.2,259.2c-6.1-3.5-14-1.4-17.5,4.8
                l-26.3,46.1c-3.5,6.1-1.4,14,4.8,17.5l140,79.9c6.1,3.5,8.3,11.3,4.8,17.5l-18.5,32.4c-3.6,6.3-10.4,10.1-17.7,10.1
                c-0.2,0-0.3,0-0.5,0c-37.2,0-67.5,30.3-67.5,67.5c0,1.1,0,2.2,0.1,3.2h-18.8c-6.9,0-12.5-5.6-12.5-12.5v-88c0-11-9-20-20-20h-92.2
                c-11,0-20,9-20,20v245.9c0,11,9,20,20,20h92.2c11,0,20-9,20-20v-94.4c0-6.9,5.6-12.5,12.5-12.5h24.3c5.3,0,10.4,2.2,14.2,6
                c13,13,31.3,20.8,51.3,19.8c33.6-1.8,61.1-28.5,63.7-62.1c1.3-17-3.7-32.7-12.9-45.3c-4.6-6.3-5-14.8-1.1-21.6l16.8-29.4
                c3.5-6.1,11.3-8.3,17.5-4.8L523.7,500C529.8,503.6,537.6,501.4,541.1,495.3z"
            />
            <path
                fill={ this.props.primary }
                d="M685.5,372.1l-19.6,34.1c-3.2,5.6-8.9,9.3-15.2,9.9l-11,1.2c-6.4,0.7-12.1,4.4-15.3,10l-25.4,44.5
                c-5.5,9.6-2.1,21.8,7.5,27.3l47.7,27.2c9.6,5.5,21.8,2.1,27.3-7.5l64.2-112.4c5.5-9.6,2.1-21.8-7.5-27.3l-25.3-14.4
                C703.1,359.2,691,362.5,685.5,372.1z"
            />
        </svg>);
    }
}

export default SecurityCamIcon;
