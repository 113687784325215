import React from 'react';
import PropTypes from 'prop-types';
import FlexContainer from '../../../components/grid/FlexContainer';
import FlexItem from '../../../components/grid/FlexItem';
import TextField from '../../../components/input/TextField';
import Title from '../../../components/Title';
import { ConfirmationConsumer } from '../../../context/Confirmation';

import PhoneField from '../../../components/input/PhoneField';

import { t } from 'i18next';

/**
 * @todo Add form validation
 * @todo Add form data to the confirmation api
 */

class BookingPersonalDetails extends React.Component {
    state = {
        validated: true,
        validElements: {},
    }

    componentDidMount() {
        const { handleFormValidation } = this.props;

        if (handleFormValidation && this.validateForm()) {
            const { validated } = this.state;

            handleFormValidation(validated);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { handleFormValidation } = this.props;
        const { validated } = this.state;
        if (handleFormValidation && validated !== prevState.validated) {
            handleFormValidation(validated);
        }
    }

    checkElementValidity = (name, value) => {
        this.setState(
            (prevState) => {
                return {
                    validElements: {
                        ...prevState.validElements,
                        [name]: value,
                    }
                };
            }, this.validateForm);
    }

    validateForm = () => {
        const { validElements } = this.state;

        let result = true;

        for (var i in validElements) {
            if (validElements[i] === false) {
                result = false;
                break;
            }
        }

        this.setState({
            validated: result,
        })

        return result;
    }

    handleChange = (createPersonalDetails, e) => {
        const { name, value } = e.target;
        createPersonalDetails(name, value);
    }

    render() {
        return (
        <ConfirmationConsumer>
            {({ createPersonalDetails, personal, searchDetails }) => {
                return (
                    <div>
                        <Title title={ t('confirmation:personalDetails') } />

                        <FlexContainer>
                            <FlexItem>
                                <TextField
                                    label={ t('forms:formElements.personalDetails.firstname.label') }
                                    name="firstname"
                                    onChange={ (e) => this.handleChange(createPersonalDetails, e) }
                                    required
                                    valid={ this.checkElementValidity }
                                    value={ personal.firstname }
                                />
                            </FlexItem>

                            <FlexItem>
                                <TextField
                                    label={ t('forms:formElements.personalDetails.surname.label') }
                                    name="surname"
                                    onChange={ (e) => this.handleChange(createPersonalDetails, e) }
                                    required
                                    valid={ this.checkElementValidity }
                                    value={ personal.surname }
                                />
                            </FlexItem>

                            <FlexItem size={ 2 } >
                                <TextField
                                    constraint={ /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,10})$/ }
                                    label={ t('forms:formElements.personalDetails.email.label') }
                                    name="email"
                                    onChange={ (e) => this.handleChange(createPersonalDetails, e) }
                                    required
                                    valid={ this.checkElementValidity }
                                    value={ personal.email }
                                    warningText={ t('forms:formElements.personalDetails.email.warning') }
                                />
                            </FlexItem>
                        </FlexContainer>

                        <FlexContainer>
                            <FlexItem>
                                <FlexContainer>
                                    <FlexItem>
                                        <PhoneField
                                            label={ t('forms:formElements.personalDetails.phone.label') }
                                            name="phone"
                                            onChange={ (e) => this.handleChange(createPersonalDetails, e) }
                                            required
                                            valid={ this.checkElementValidity }
                                            value={ personal.phone }
                                        />
                                    </FlexItem>
                                </FlexContainer>

                                <FlexContainer>
                                    { searchDetails.typeOfBooking === 'parking' &&
                                        <FlexItem>
                                            <TextField
                                                label={ t('forms:formElements.personalDetails.licensePlate.label') }
                                                name="licensePlate"
                                                onChange={ (e) => this.handleChange(createPersonalDetails, e) }
                                                required
                                                valid={ this.checkElementValidity }
                                                value={ personal.licensePlate }
                                            />
                                        </FlexItem>
                                    }
                                    <FlexItem>
                                        <TextField
                                            constraint={ /^([a-z][a-z]|[a-z][0-9]|[0-9][a-z])[a-z]?[0-9]{1,4}[a-z]?$/i }
                                            label={ searchDetails.typeOfBooking === 'parking' ? t('forms:formElements.personalDetails.flightReturn.label') : t('forms:formElements.personalDetails.flight.label') }
                                            name="flight"
                                            onChange={ (e) => this.handleChange(createPersonalDetails, e) }
                                            valid={ this.checkElementValidity }
                                            value={ personal.flight }
                                        />
                                    </FlexItem>
                                </FlexContainer>
                            </FlexItem>

                            <FlexItem size={ 2 } >
                                <TextField
                                    label={ t('forms:formElements.personalDetails.comments.label') }
                                    multiLine={ true }
                                    name="comments"
                                    onChange={ (e) => this.handleChange(createPersonalDetails, e) }
                                    rows={ 5 }
                                    valid={ this.checkElementValidity }
                                    value={ personal.comments }
                                />
                            </FlexItem>
                        </FlexContainer>
                    </div>
                )
            }}
        </ConfirmationConsumer>
        )
    }
}

BookingPersonalDetails.propTypes = {
    handleFormValidation: PropTypes.func,
}

export default BookingPersonalDetails;
