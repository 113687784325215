import React from 'react';
import BaseIcon from './BaseIcon';

class Open5Icon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="open5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <g>
                <path fill={ this.props.primary } d="M426.5,203.2c0-14.6-11.9-26.5-26.5-26.5s-26.5,11.9-26.5,26.5v199c0,0,0,0.1,0,0.1c0,0.4,0,0.7,0,1.1c0,0.1,0,0.1,0,0.2
                    c0,0.4,0,0.7,0.1,1.1c0,0.1,0,0.2,0,0.2c0,0.3,0.1,0.7,0.1,1c0,0.1,0,0.2,0,0.3c0,0.3,0.1,0.6,0.2,0.9c0,0.2,0.1,0.3,0.1,0.5
                    c0.1,0.2,0.1,0.5,0.2,0.7c0,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.1,0.5,0.2,0.7c0,0.1,0.1,0.3,0.1,0.4
                    c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0.1,0.2,0.1,0.3c0.1,0.3,0.3,0.6,0.4,0.9c0,0.1,0.1,0.2,0.1,0.2c0.2,0.3,0.3,0.7,0.5,1
                    c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.3,0.7,0.5,1c0,0,0,0.1,0.1,0.1l89,154.2c4.9,8.5,13.8,13.3,23,13.3c4.5,0,9.1-1.1,13.2-3.6
                    c12.7-7.3,17-23.5,9.7-36.2l-85.5-148V203.2z"/>
                <path fill={ this.props.primary } d="M541,646.3c-42.7,24.5-91.5,37.4-141,37.4c-75.8,0-147.1-29.5-200.6-83.1c-53.6-53.6-83.1-124.9-83.1-200.6
                    c0-75.8,29.5-147.1,83.1-200.6c38.5-38.5,86.2-64.6,138.1-76.2c-2.3,9.1,0.3,19.2,7.7,26.1c5.1,4.7,11.5,7,18,7
                    c7.1,0,14.3-2.9,19.5-8.5l36.9-40c0,0,0,0,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.3-0.4,0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.2
                    c0-0.1,0.1-0.1,0.1-0.2c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.1,0.1-0.2,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.3-0.5,0.5-0.7
                    c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.2,0.3-0.5,0.4-0.7c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.2-0.4,0.4-0.6
                    c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3
                    c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.2,0.1-0.3,0.2-0.5
                    c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.3,0.1-0.4c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.4
                    c0.1-0.2,0.1-0.4,0.2-0.6c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0.1-0.4,0.1-0.6c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0
                    c0-0.1,0.1-0.3,0.1-0.4c0-0.3,0.1-0.6,0.1-0.8c0-0.2,0-0.3,0.1-0.5c0-0.2,0-0.4,0.1-0.5c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3,0-0.5
                    c0-0.2,0-0.4,0-0.5c0-0.1,0-0.1,0-0.2c0-0.2,0-0.4,0-0.5c0-0.2,0-0.4,0-0.6c0,0,0-0.1,0-0.1s0-0.1,0-0.1c0-0.2,0-0.4,0-0.6
                    c0-0.2,0-0.4,0-0.5c0-0.1,0-0.1,0-0.2c0-0.2,0-0.4,0-0.5c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5
                    c0-0.2,0-0.3-0.1-0.5c0-0.3-0.1-0.6-0.1-0.8c0-0.1,0-0.3-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.4-0.1-0.6c0-0.1-0.1-0.3-0.1-0.4
                    c0-0.1,0-0.2-0.1-0.3c0-0.2-0.1-0.4-0.2-0.6c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.3-0.2-0.5
                    c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.3-0.2-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.1-0.1-0.2-0.1-0.3
                    c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.3-0.6
                    c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.2-0.2-0.4-0.4-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2
                    c-0.1-0.2-0.3-0.5-0.4-0.7c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.2-0.3-0.5-0.5-0.7c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
                    c-0.2-0.2-0.3-0.4-0.5-0.6c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.3-0.4-0.5-0.5c-0.1-0.1-0.1-0.1-0.2-0.2
                    c0,0,0,0-0.1-0.1l-36.9-40c-9.9-10.8-26.7-11.4-37.4-1.5c-10.8,9.9-11.4,26.7-1.5,37.4l0.2,0.2c-25.7,4.3-50.7,11.6-74.9,21.8
                    c-40.1,17-76.1,41.2-107,72.2c-30.9,30.9-55.2,66.9-72.2,107C72.1,310.4,63.2,354.5,63.2,400s8.9,89.6,26.5,131.1
                    c17,40.1,41.2,76.1,72.2,107c30.9,30.9,66.9,55.2,107,72.2c41.5,17.6,85.6,26.5,131.1,26.5c58.8,0,116.7-15.4,167.4-44.5
                    c12.7-7.3,17.1-23.5,9.8-36.2C569.9,643.4,553.7,639,541,646.3z"/>
                <path fill={ this.props.primary } d="M456.1,103.4c0,0.4,0.1,0.9,0.2,1.3c0.1,0.4,0.1,0.9,0.2,1.3c0.1,0.4,0.2,0.9,0.3,1.3c0.1,0.4,0.2,0.8,0.3,1.2
                    c0.1,0.4,0.3,0.8,0.4,1.2c0.1,0.4,0.3,0.8,0.5,1.2c0.2,0.4,0.3,0.8,0.5,1.2c0.2,0.4,0.4,0.8,0.6,1.2c0.2,0.4,0.4,0.8,0.6,1.1
                    s0.5,0.7,0.7,1.1c0.2,0.4,0.5,0.7,0.8,1.1c0.3,0.4,0.5,0.7,0.8,1c0.3,0.3,0.6,0.7,0.8,1c0.3,0.3,0.6,0.6,0.9,0.9
                    c0.3,0.3,0.6,0.6,0.9,0.9c0.3,0.3,0.7,0.6,1,0.8c0.3,0.3,0.7,0.5,1,0.8c0.3,0.2,0.7,0.5,1.1,0.7c0.4,0.2,0.7,0.5,1.1,0.7
                    c0.4,0.2,0.8,0.4,1.1,0.6c0.4,0.2,0.8,0.4,1.2,0.6c0.4,0.2,0.8,0.4,1.2,0.5c0.4,0.2,0.8,0.3,1.2,0.5c0.4,0.1,0.8,0.3,1.2,0.4
                    c0.4,0.1,0.8,0.2,1.2,0.3c0.4,0.1,0.8,0.2,1.3,0.3c0.4,0.1,0.8,0.2,1.3,0.2c0.4,0.1,0.9,0.1,1.3,0.2c0.4,0,0.9,0.1,1.3,0.1
                    s0.9,0,1.3,0c0.4,0,0.9,0,1.3,0c0.4,0,0.9-0.1,1.3-0.1c0.4,0,0.9-0.1,1.3-0.2c0.4-0.1,0.9-0.1,1.3-0.2c0.4-0.1,0.9-0.2,1.3-0.3
                    c0.4-0.1,0.8-0.2,1.2-0.3c0.4-0.1,0.8-0.3,1.2-0.4c0.4-0.1,0.8-0.3,1.2-0.5c0.4-0.2,0.8-0.3,1.2-0.5c0.4-0.2,0.8-0.4,1.2-0.6
                    c0.4-0.2,0.8-0.4,1.1-0.6c0.4-0.2,0.7-0.5,1.1-0.7c0.4-0.2,0.7-0.5,1.1-0.7c0.3-0.3,0.7-0.5,1-0.8c0.3-0.3,0.7-0.6,1-0.8
                    c0.3-0.3,0.6-0.6,0.9-0.9c0.3-0.3,0.6-0.6,0.9-0.9c0.3-0.3,0.6-0.6,0.9-1c0.3-0.3,0.5-0.7,0.8-1c0.3-0.3,0.5-0.7,0.8-1.1
                    s0.5-0.7,0.7-1.1c0.2-0.4,0.4-0.8,0.6-1.1s0.4-0.8,0.6-1.2s0.4-0.8,0.5-1.2c0.2-0.4,0.3-0.8,0.5-1.2c0.1-0.4,0.3-0.8,0.4-1.2
                    c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.2-0.8,0.3-1.3c0.1-0.4,0.2-0.9,0.2-1.3c0.1-0.4,0.1-0.9,0.2-1.3c0-0.4,0.1-0.9,0.1-1.3
                    c0-0.4,0-0.9,0-1.3c0-0.4,0-0.9,0-1.3c0-0.4,0-0.9-0.1-1.3c0-0.4-0.1-0.9-0.2-1.3c-0.1-0.4-0.1-0.9-0.2-1.3
                    c-0.1-0.4-0.2-0.8-0.3-1.3s-0.2-0.8-0.3-1.2c-0.1-0.4-0.3-0.8-0.4-1.2c-0.1-0.4-0.3-0.8-0.5-1.2c-0.2-0.4-0.4-0.8-0.5-1.2
                    c-0.2-0.4-0.4-0.8-0.6-1.2s-0.4-0.8-0.6-1.1c-0.2-0.4-0.5-0.7-0.7-1.1c-0.2-0.4-0.5-0.7-0.8-1.1c-0.2-0.4-0.5-0.7-0.8-1
                    c-0.3-0.3-0.6-0.7-0.9-1c-0.3-0.3-0.6-0.6-0.9-1c-0.3-0.3-0.6-0.6-0.9-0.9c-0.3-0.3-0.7-0.6-1-0.9c-0.3-0.3-0.7-0.5-1-0.8
                    c-0.4-0.2-0.7-0.5-1.1-0.7c-0.4-0.2-0.7-0.5-1.1-0.7c-0.4-0.2-0.8-0.4-1.1-0.6c-0.4-0.2-0.8-0.4-1.2-0.6c-0.4-0.2-0.8-0.4-1.2-0.5
                    c-0.4-0.2-0.8-0.3-1.2-0.5c-0.4-0.1-0.8-0.3-1.2-0.4c-0.4-0.1-0.8-0.2-1.2-0.3c-0.4-0.1-0.8-0.2-1.3-0.3c-0.4-0.1-0.8-0.2-1.3-0.2
                    c-0.4-0.1-0.9-0.1-1.3-0.2c-0.4,0-0.9-0.1-1.3-0.1c-0.9,0-1.7,0-2.6,0c-0.4,0-0.9,0.1-1.3,0.1c-0.4,0-0.9,0.1-1.3,0.2
                    c-0.4,0.1-0.9,0.1-1.3,0.2c-0.4,0.1-0.8,0.2-1.3,0.3c-0.4,0.1-0.8,0.2-1.2,0.3c-0.4,0.1-0.8,0.3-1.2,0.4c-0.4,0.1-0.8,0.3-1.2,0.5
                    c-0.4,0.2-0.8,0.3-1.2,0.5c-0.4,0.2-0.8,0.4-1.2,0.6c-0.4,0.2-0.8,0.4-1.1,0.6c-0.4,0.2-0.7,0.5-1.1,0.7c-0.4,0.2-0.7,0.5-1.1,0.7
                    c-0.4,0.3-0.7,0.5-1,0.8c-0.3,0.3-0.7,0.6-1,0.9c-0.3,0.3-0.6,0.6-0.9,0.9c-0.3,0.3-0.6,0.6-0.9,1s-0.6,0.6-0.8,1
                    c-0.3,0.3-0.5,0.7-0.8,1c-0.3,0.3-0.5,0.7-0.8,1.1c-0.2,0.4-0.5,0.7-0.7,1.1c-0.2,0.4-0.4,0.8-0.6,1.1c-0.2,0.4-0.4,0.8-0.6,1.2
                    c-0.2,0.4-0.4,0.8-0.5,1.2c-0.2,0.4-0.3,0.8-0.5,1.2c-0.2,0.4-0.3,0.8-0.4,1.2c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.4-0.2,0.8-0.3,1.3
                    c-0.1,0.4-0.2,0.9-0.2,1.3c-0.1,0.4-0.1,0.9-0.2,1.3c0,0.4-0.1,0.9-0.1,1.3c0,0.4,0,0.9,0,1.3c0,0.4,0,0.9,0,1.3
                    C456,102.5,456,103,456.1,103.4z"/>
                <path fill={ this.props.primary } d="M593.5,124.4c-12-8.4-28.5-5.5-36.9,6.4c-8.4,12-5.5,28.5,6.4,36.9c4.6,3.3,9.9,4.8,15.2,4.8c8.3,0,16.5-3.9,21.7-11.3
                    C608.4,149.3,605.5,132.8,593.5,124.4z"/>
                <path fill={ this.props.primary } d="M673.4,323.6c3.3,11.7,13.9,19.4,25.5,19.4c2.4,0,4.8-0.3,7.1-1c14.1-3.9,22.3-18.5,18.4-32.6
                    c-3.9-14.1-18.6-22.3-32.6-18.4C677.7,294.9,669.4,309.5,673.4,323.6z"/>
                <path fill={ this.props.primary } d="M711.3,395.5c-14.6-1-27.3,10-28.3,24.6c-1,14.6,10,27.3,24.6,28.3c0.6,0,1.2,0.1,1.9,0.1c13.8,0,25.4-10.7,26.4-24.7
                    C737,409.1,725.9,396.5,711.3,395.5z"/>
                <path fill={ this.props.primary } d="M675,205.5c-8.5-11.9-25-14.8-36.9-6.3c-12,8.5-14.8,25-6.3,36.9c5.2,7.3,13.3,11.2,21.6,11.2c5.3,0,10.7-1.6,15.3-4.9
                    C680.6,234,683.4,217.5,675,205.5z"/>
                <path fill={ this.props.primary } d="M694.8,500.5c-13.4-5.9-29,0.2-34.9,13.6c-5.9,13.4,0.2,29,13.6,34.9c3.5,1.5,7.1,2.2,10.7,2.2c10.2,0,19.9-5.9,24.3-15.8
                    c0,0,0,0,0,0C714.3,522.1,708.2,506.4,694.8,500.5z"/>
                <path fill={ this.props.primary } d="M651.8,610.5c0-0.4-0.1-0.9-0.2-1.3c-0.1-0.4-0.1-0.9-0.2-1.3c-0.1-0.4-0.2-0.8-0.3-1.3c-0.1-0.4-0.2-0.8-0.3-1.2
                    c-0.1-0.4-0.3-0.8-0.4-1.2c-0.2-0.4-0.3-0.8-0.5-1.2c-0.2-0.4-0.3-0.8-0.5-1.2c-0.2-0.4-0.4-0.8-0.6-1.2c-0.2-0.4-0.4-0.8-0.6-1.1
                    s-0.5-0.7-0.7-1.1c-0.2-0.4-0.5-0.7-0.8-1.1c-0.3-0.3-0.5-0.7-0.8-1c-0.3-0.3-0.6-0.7-0.8-1c-0.3-0.3-0.6-0.6-0.9-0.9
                    c-0.3-0.3-0.6-0.6-1-0.9c-0.3-0.3-0.6-0.6-1-0.8c-0.3-0.3-0.7-0.5-1-0.8c-0.3-0.2-0.7-0.5-1.1-0.7c-0.4-0.2-0.7-0.5-1.1-0.7
                    c-0.4-0.2-0.8-0.4-1.1-0.6c-0.4-0.2-0.8-0.4-1.2-0.6c-0.4-0.2-0.8-0.4-1.2-0.5c-0.4-0.2-0.8-0.3-1.2-0.5c-0.4-0.1-0.8-0.3-1.2-0.4
                    c-0.4-0.1-0.8-0.2-1.2-0.3c-0.4-0.1-0.8-0.2-1.3-0.3c-0.4-0.1-0.9-0.2-1.3-0.2c-0.4-0.1-0.9-0.1-1.3-0.2s-0.9-0.1-1.3-0.1
                    c-0.9,0-1.8,0-2.6,0c-0.4,0-0.9,0.1-1.3,0.1s-0.9,0.1-1.3,0.2c-0.4,0.1-0.9,0.1-1.3,0.2c-0.4,0.1-0.8,0.2-1.3,0.3
                    c-0.4,0.1-0.8,0.2-1.2,0.3c-0.4,0.1-0.8,0.3-1.2,0.4c-0.4,0.1-0.8,0.3-1.2,0.5c-0.4,0.2-0.8,0.3-1.2,0.5c-0.4,0.2-0.8,0.4-1.2,0.6
                    c-0.4,0.2-0.8,0.4-1.1,0.6c-0.4,0.2-0.7,0.5-1.1,0.7c-0.4,0.2-0.7,0.5-1.1,0.7c-0.4,0.3-0.7,0.5-1,0.8c-0.3,0.3-0.7,0.6-1,0.8
                    c-0.3,0.3-0.6,0.6-0.9,0.9c-0.3,0.3-0.6,0.6-0.9,0.9c-0.3,0.3-0.6,0.7-0.8,1c-0.3,0.3-0.5,0.7-0.8,1c-0.2,0.3-0.5,0.7-0.7,1.1
                    c-0.2,0.4-0.5,0.7-0.7,1.1s-0.4,0.8-0.6,1.1c-0.2,0.4-0.4,0.8-0.6,1.2c-0.2,0.4-0.4,0.8-0.5,1.2c-0.2,0.4-0.3,0.8-0.5,1.2
                    c-0.1,0.4-0.3,0.8-0.4,1.2c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.4-0.2,0.8-0.3,1.3s-0.2,0.8-0.2,1.3c-0.1,0.4-0.1,0.9-0.2,1.3
                    c0,0.4-0.1,0.9-0.1,1.3c0,0.4,0,0.9,0,1.3c0,0.4,0,0.9,0,1.3c0,0.4,0.1,0.9,0.1,1.3c0.1,0.4,0.1,0.9,0.2,1.3
                    c0.1,0.4,0.1,0.8,0.2,1.3c0.1,0.4,0.2,0.8,0.3,1.3c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.4,0.3,0.8,0.4,1.2c0.1,0.4,0.3,0.8,0.5,1.2
                    c0.2,0.4,0.3,0.8,0.5,1.2c0.2,0.4,0.4,0.8,0.6,1.2c0.2,0.4,0.4,0.8,0.6,1.1s0.5,0.7,0.7,1.1c0.2,0.4,0.5,0.7,0.7,1.1
                    c0.3,0.3,0.5,0.7,0.8,1c0.3,0.3,0.6,0.7,0.8,1c0.3,0.3,0.6,0.6,0.9,1c0.3,0.3,0.6,0.6,0.9,0.9c0.3,0.3,0.7,0.6,1,0.8
                    c0.3,0.3,0.7,0.5,1,0.8c0.3,0.2,0.7,0.5,1.1,0.7c0.4,0.2,0.7,0.5,1.1,0.7c0.4,0.2,0.8,0.4,1.1,0.6s0.8,0.4,1.2,0.6
                    c0.4,0.2,0.8,0.4,1.2,0.5c0.4,0.2,0.8,0.3,1.2,0.5c0.4,0.1,0.8,0.3,1.2,0.4c0.4,0.1,0.8,0.2,1.2,0.4c0.4,0.1,0.8,0.2,1.3,0.3
                    c0.4,0.1,0.9,0.2,1.3,0.2c0.4,0.1,0.9,0.1,1.3,0.2s0.9,0.1,1.3,0.1c0.4,0,0.9,0,1.3,0c0.4,0,0.9,0,1.3,0c0.4,0,0.9-0.1,1.3-0.1
                    s0.9-0.1,1.3-0.2c0.4-0.1,0.9-0.1,1.3-0.2c0.4-0.1,0.8-0.2,1.3-0.3c0.4-0.1,0.8-0.2,1.2-0.4c0.4-0.1,0.8-0.3,1.2-0.4
                    c0.4-0.2,0.8-0.3,1.2-0.5c0.4-0.2,0.8-0.3,1.2-0.5c0.4-0.2,0.8-0.4,1.2-0.6s0.8-0.4,1.1-0.6c0.4-0.2,0.7-0.5,1.1-0.7
                    c0.4-0.2,0.7-0.5,1.1-0.7c0.3-0.3,0.7-0.5,1-0.8c0.3-0.3,0.7-0.6,1-0.8c0.3-0.3,0.6-0.6,1-0.9c0.3-0.3,0.6-0.6,0.9-1
                    c0.3-0.3,0.6-0.6,0.8-1c0.3-0.3,0.5-0.7,0.8-1c0.2-0.3,0.5-0.7,0.8-1.1c0.2-0.4,0.5-0.7,0.7-1.1s0.4-0.8,0.6-1.1
                    c0.2-0.4,0.4-0.8,0.6-1.2c0.2-0.4,0.4-0.8,0.5-1.2c0.2-0.4,0.3-0.8,0.5-1.2c0.1-0.4,0.3-0.8,0.4-1.2c0.1-0.4,0.2-0.8,0.3-1.2
                    c0.1-0.4,0.2-0.8,0.3-1.3c0.1-0.4,0.2-0.9,0.2-1.3c0.1-0.4,0.1-0.9,0.2-1.3c0-0.4,0.1-0.9,0.1-1.3c0-0.4,0-0.9,0-1.3
                    c0-0.4,0-0.9,0-1.3C651.9,611.4,651.9,610.9,651.8,610.5z"/>
            </g>
        </svg>);
    }
}

export default Open5Icon;
