import _ from 'lodash';

export default (function () {
    const objProto = Object.prototype;
    const objToString = objProto.toString;

    /**
     * This adds a leading zero if the number is less then 10,
     * this function is mainly used for date and time.
     * @param {int} number
     */
    const formatNumber = (number) => {
        return number < 10 ? `0${number}` : number;
    }

    const utils = {
        /**
         * Is given value undefined?
         *
         * @param {*} obj - Object to be checked.
         * @returns {boolean} Whether given object is `undefined`.
         */
        isUndefined: function (obj) {
            return typeof obj === 'undefined';
        },

        /**
         * Is given value an Array?
         *
         * @param {*} obj - Object to be checked.
         * @returns {boolean} Whether given object is an Array.
         */
        isArray: function (obj) {
            return objToString.call(obj) === '[object Array]';
        },

        /**
         * Is a given value a object?
         *
         * @param {*} obj - Object to be checked.
         * @param {boolean} [literal=false] - Check if object is a literal object.
         * @returns {boolean} - Whether given object is an Object.
         */
        isObject: function (obj, literal) {
            return Object(obj) === obj && (!literal || obj.constructor === Object);
        },

        /**
         * Is a given value empty?
         *
         * @param {Object} obj - Object to be checked if it's empty.
         * @returns {boolean}
         */
        isEmpty: function (obj) {
            if (obj === '' || obj === null || utils.isUndefined(obj)) {
                return true;
            }

            // Check if it's an empty array
            if (utils.isArray(obj) && obj.length === 0) {
                return true;
            }

            // Check if it's an empty object
            if (utils.isObject(obj)) {
                for (let key in obj) {
                    if (hasOwnProperty.call(obj, key)) {
                        return false;
                    }
                }

                return true;
            }

            return false;
        },

        /**
         * Format a date to a string with yyyy-mm-dd hh:mm:ss
         * @param {Date} dateObject
         */
        formatDate: (dateObject) => {
            if (!dateObject) {
                return '';
            }

            let year = dateObject.getFullYear(),
                month = formatNumber(dateObject.getMonth() + 1),
                date = formatNumber(dateObject.getDate()),
                hours = formatNumber(dateObject.getHours()),
                minutes = formatNumber(dateObject.getMinutes()),
                seconds= formatNumber(dateObject.getSeconds());

            return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
        },

        /**
         * Adding a certain number of days to a date object.
         * @param {Date} date
         * @param {Number} days
         */
        addDays: (date, days) => {
            let result = new Date(date);

            result.setDate(result.getDate() + days);

            return result;
        },

        /**
         * Adding a certain number of hours to a date object.
         * @param {Date} date
         * @param {Number} hours
         */
        addHours: (date, hours) => {
            let result = new Date(date);

            result.setHours(result.getHours() + hours);

            return result;
        },

        /**
         * Rounding time to the nearest preset minutes.
         * @param {number} minutes
         * @param {Date} date
         * @returns {Date}
         */
        roundTime: (minutes, date = new Date()) => {
            const ms = 1000 * 60 * minutes; // convert minutes to ms

            let roundedDate = new Date(Math.round(date.getTime() / ms) * ms);

            roundedDate.setSeconds(0);

            return roundedDate;
        },

        /**
         * Normalizes the payload.
         * @param {Object} payload
         */
        normalizePayload: (payload) => {
            let result = {};

            Object.entries(payload).forEach(([
                key, val,
            ]) => {
                if (val instanceof File) {
                    result[ key ] = val;
                    return;
                }

                if (val === null || val === '') {
                    return;
                }

                if (_.isArray(val)) {
                    _.forEach(val, (subValue) => {
                        return subValue !== '' || subValue !== undefined;
                    });
                }

                if (_.isPlainObject(val)) {
                    val = this.normalizePayload(val);
                }

                if (_.isString(val)) {
                    val = val.trim();
                }

                result[ key ] = val;
            });

            return result;
        },
    }

    return utils;
})();
