import React from 'react';
import CardBox from '../../components/cardBox/CardBox';
import FlexContainer from '../../components/grid/FlexContainer';
import FlexItem from '../../components/grid/FlexItem';
import PropTypes from 'prop-types';
import Table from '../../components/bookingInformationTable/Table';
import Title from '../../components/Title';
import { ConfirmationConsumer } from '../../context/Confirmation';
import BookingInformationMap from './BookingInformationMap';

const BookingInformation = ({ selectedProduct }) => {
    return (
        <CardBox>
            <ConfirmationConsumer>
                {({ searchDetails }) => {
                    return (
                        <FlexContainer>
                            <FlexItem size={ 3 }>
                                <Title title={ `${ selectedProduct.subcategory ? selectedProduct.subcategory.name + ': ': '' }${ selectedProduct.title }` } uppercase={ true } />

                                <Table information={ searchDetails } />
                            </FlexItem>

                            <BookingInformationMap
                                originAddress={ searchDetails.originAddress }
                                destinationAddress={ searchDetails.destinationAddress }
                                typeOfBooking={ searchDetails.typeOfBooking }
                                selectedProduct={ selectedProduct }
                                size="350x175"
                            />
                        </FlexContainer>
                    )
                }}
            </ConfirmationConsumer>
        </CardBox>
    );
}

BookingInformation.propTypes = {
    selectedProduct: PropTypes.object,
}

export default BookingInformation;
