import React, { Component } from "react";
import Page from '../containers/Page';
import { GlobalProvider, GlobalConsumer } from '../context/Global';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import identifier from '../api/identifier';
import { version } from '../../package.json';

/**
 * @todo Add a global Loader
 * @todo Add a Modal
 */

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#39897D',
        },
        secondary: {
            main: '#39897D',
        },
    },
    typography: {
        useNextVariants: true,
    },
});

class App extends Component {
    state = {
        error: null,
        profile: null,
    }

    componentDidMount = () => {
        // eslint-disable-next-line no-console
        console.info('Booking tool version: ', version);

        identifier.identify().then(
            (profile) => {
                this.setState({
                    profile: profile,
                });
            }, (error) => {
                this.setState({
                    error: error,
                });
            }
        );
    }

    render() {
        if (!this.state.profile) {
            return null;
        }

        return (
            <MuiThemeProvider theme={ theme }>
                <GlobalProvider profile={ this.state.profile }>
                    <GlobalConsumer>
                        {({ page }) => {
                            return (
                                <Page page={ page } />
                            )
                        }}
                    </GlobalConsumer>
                </GlobalProvider>
            </MuiThemeProvider>
        );
    }
}

export default App;
