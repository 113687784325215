import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import _ from 'lodash';
import styled from 'styled-components';
import { t } from 'i18next';

const StyleWrapper = styled.div`
    position: relative;
    padding-top: 16px;
    margin-bottom: 25px;

    .react-tel-input {
        input[type="tel"] {
            width: 100% !important;
            border: 0;
            border-radius: 0;
            box-shadow: initial;
            border-bottom: ${ props => props.color ? '2px solid ' + props.color : '1px solid rgba(0, 0, 0, 0.42)' };
            font-size: 16px;
            transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

            &:focus{
                outline: 0;
                border-bottom: 2px solid ${ props => props.color || 'rgb(92, 179, 173)' };
            }
        }

        .flag-dropdown {
            border: 0;
            background: transparent;

            &:hover{
                border: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.42);
            }
        }
    }
`;

const Label = styled.label`
    color: ${ props => props.color || 'rgba(0, 0, 0, 0.54)' };
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.75rem;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    z-index: 1;
`;

const ErrorText = styled.div`
    margin-top: 10px;
    font-size: 12px;
    line-height: 12px;
    height: 12px;
    color: ${ props => props.color || 'rgba(0, 0, 0, 0.3)' };
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
`;

class PhoneField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            errorStyle: {},
            valid: !props.required,
        }
    }

    componentDidMount() {
        const { valid, name, value } = this.props;
        if (value) {
            this.handleInputValidation(value);
        }

        if (valid) {
            valid(name, this.state.valid);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.valid && this.state.valid !== prevState.valid) {
            this.props.valid(this.props.name, this.state.valid);
        }

        if (this.state.countryCode !== prevState.countryCode) {
            // We set the last selected country to the localstorage.so that is set as default the next time the user visits the booking tool.
            localStorage.setItem('bookingToolPhoneCountry', this.state.countryCode);
        }
    }

    handleInputValidation = (value, country) => {
        const { required, onChange, name } = this.props;
        const countryCode = _.get(country,'countryCode') || localStorage.getItem('bookingToolPhoneCountry') || 'nl';

        const constraint = (/^\+\d{6,}$/g);

        const e = {
            target: {
                name: name,
                value: value.replace(/[^0-9+.]/g,''),
            }
        };


        const warningText = this.props.warningText || t('forms:formValidation.somethingWrong');
        const errorText = this.props.errorText || t('forms:formValidation.required');
        const targetValue = e.target.value;

        // Default
        let error = '';
        let errorStyle = {};
        let valid = true;

        if (!required) {
            // Is not required.
            if (targetValue && constraint && !targetValue.match(constraint)) {
                // Has value and validation rules but fails the verification.
                error = warningText;
                errorStyle = {
                    color: '#FF8D05',
                };
                valid = false;
            }
        } else if (!targetValue) {
            // Is required and doesn't have a value.
            error = errorText;
            valid = false;
        } else if (constraint && !targetValue.match(constraint)) {
            // Is required, has value and validation rules but fails the verification.
            error = warningText;
            errorStyle = {
                color: '#FF8D05',
            };
            valid = false;
        }

        return this.setState({
            error: error,
            errorStyle: errorStyle,
            countryCode: countryCode,
            valid: valid,
        }, onChange(e));
    }

    render(){
        const { label, value } = this.props;

        return (
            <StyleWrapper color={ this.state.errorStyle.color }>
                <Label color={ this.state.errorStyle.color }>
                    { label }
                </Label>

                <PhoneInput
                    defaultCountry={ localStorage.getItem('bookingToolPhoneCountry') || 'nl' }
                    onChange={ this.handleInputValidation }
                    preferredCountries={ ['de','es','fr','gb','it','nl'] }
                    style={{ width: '100%' }}
                    value={ value }
                />

                { this.state.error &&
                    <ErrorText color={ this.state.errorStyle.color }>
                        { this.state.error }
                    </ErrorText>
                }
            </StyleWrapper>
        );
    }
}

PhoneField.defaultProps = {
    required: false
}

PhoneField.propTypes = {
    errorText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    valid: PropTypes.func,
    value: PropTypes.string,
    warningText: PropTypes.string,
}

export default PhoneField;
