import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledFlexItem = styled.div`
    flex: ${ props => props.size };

    ~ * {
        margin-left: 20px;
    }
`;

const FlexItem = ({ size, children }) => {
    return (
        <StyledFlexItem
            size={ size }
        >
            { children }
        </StyledFlexItem>
    );
}

FlexItem.defaultProps = {
    size: 1,
}

FlexItem.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    size: PropTypes.number
}

export default FlexItem;
