import React from 'react';
import BaseIcon from './BaseIcon';

class CarIndoorIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="car-indoor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M668,383.2c-2.6-3.2-6.5-5-10.5-5H606c-12.7-33.6-29.3-66.4-48.1-78.8c-65.2-42.9-238-42.9-303.2,0
                c-18.9,12.4-35.4,45.3-48.1,78.8h-51.5c-4.1,0-7.9,1.8-10.5,5c-2.6,3.1-3.6,7.3-2.8,11.3l7.6,37c1.3,6.3,6.9,10.8,13.3,10.8H178
                c-14.7,17-21.9,38.4-22,59.8c-0.1,26.5,10,50.4,28.3,67.3c0.2,0.2,0.4,0.3,0.6,0.5v51.8c0,11.2,9.1,20.3,20.3,20.3h47.5
                c11.2,0,20.3-9.1,20.3-20.3v-20.8h266.4v20.8c0,11.2,9.1,20.3,20.3,20.3h47.5c11.2,0,20.3-9.1,20.3-20.3V571
                c19.5-17.9,28.8-42.4,28.9-67c0.1-22.2-7.4-44.4-23-61.7h16.3c6.4,0,12-4.5,13.3-10.8l7.6-37C671.5,390.5,670.5,386.4,668,383.2z
                M276.9,333.4c51.9-34.2,206.5-34.2,258.4,0c10.1,6.6,23.1,33.4,35,66.1H242C253.9,366.7,266.8,340,276.9,333.4z M216.1,507.3
                c0-22.1,17.9-40,40-40s40,17.9,40,40c0,22.1-17.9,40-40,40S216.1,529.4,216.1,507.3z M556.8,547.3c-22.1,0-40-17.9-40-40
                s17.9-40,40-40s40,17.9,40,40C596.8,529.4,578.8,547.3,556.8,547.3z"/>
            <path
                fill={ this.props.primary }
                d="M149.4,242.1l9,19.4c4.6,10,16.5,14.4,26.5,9.7l214.2-99.1c5.3-2.5,11.5-2.5,16.8,0l214.2,99.1c10,4.6,21.9,0.3,26.5-9.7
                l9-19.4c4.6-10,0.3-21.9-9.7-26.6l-239.9-111c-5.3-2.5-11.5-2.5-16.8,0l-239.9,111C149.1,220.2,144.8,232.1,149.4,242.1z"
            />
        </svg>);
    }
}

export default CarIndoorIcon;
