import React from 'react';
import BaseIcon from './BaseIcon';

class MeetGreetIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="meet-greet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M183.5,112.8c-48.4,0-87.8,40.6-87.8,89s39.4,87.8,87.8,87.8s87.8-39.4,87.8-87.8S231.9,112.8,183.5,112.8z"
            />
            <path
                fill={ this.props.primary }
                d="M724.5,474.9c-1.6,0-3.3,0.2-5,0.7l-17.5,4.8l-18.8-45.7c-5.6-13.7-22.2-24.9-37-24.9h-12.8v-37.9c0-6.6-5.4-12.1-12.1-12.1
                h-64.2h-64.3c-6.7,0-12.1,5.4-12.1,12.1v37.9H468c-14.9,0-31.5,11.1-37,24.9l-18.8,45.7l-17.5-4.8c-1.7-0.5-3.4-0.7-5-0.7
                c-9,0-15.6,6.9-15.6,16.5v9.7c0,11.1,9,20.2,20.2,20.2h1.3l-2.6,6.5c-5.2,12.8-9.5,34.3-9.5,48.1v91.2c0,11.1,9,20.2,20.2,20.2h24.2
                c11.1,0,20.2-9,20.2-20.2v-22.5h109.1h109.1v22.5c0,11.1,9,20.2,20.2,20.2h24.2c11.1,0,20.2-9,20.2-20.2v-91.3
                c0-13.8-4.2-35.3-9.5-48.1l-2.6-6.5h1.3c11.1,0,20.2-9,20.2-20.2v-9.7C740.1,481.8,733.5,474.9,724.5,474.9z M606.2,374.2h7.5v35.9
                h-7.5V374.2L606.2,374.2z M565.2,374.2h7.4c0.5,0,0.9,0,1.1,0.2c0.2,0.1,0.4,0.3,0.6,0.6l7.7,12.7c0.1-0.2,0.2-0.4,0.3-0.6
                c0.1-0.2,0.2-0.4,0.3-0.6l6.9-11.3c0.2-0.3,0.4-0.6,0.7-0.7c0.2-0.1,0.5-0.2,0.9-0.2h7.1l-11.4,17.1l11.7,18.8h-7.4
                c-0.5,0-0.9-0.1-1.2-0.4s-0.5-0.5-0.7-0.9l-7.7-13.2c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.1,0.3-0.2,0.5l-7.4,12.2
                c-0.2,0.3-0.5,0.6-0.8,0.9c-0.3,0.2-0.7,0.4-1.1,0.4h-7l11.7-18.5L565.2,374.2z M539.8,374.2h7.6l14,35.9h-5.8
                c-0.6,0-1.2-0.1-1.6-0.5c-0.4-0.3-0.7-0.7-0.9-1.2l-2.4-6.8h-14.3l-2.4,6.8c-0.1,0.4-0.4,0.8-0.9,1.1c-0.4,0.3-0.9,0.5-1.5,0.5h-5.8
                L539.8,374.2z M496.6,374.2H525v6h-10.5v29.9h-7.5v-29.9h-10.5L496.6,374.2L496.6,374.2z M490.8,599.4c0,4.5-3.7,8-8,8h-54.5
                c-4.5,0-8-3.7-8-8v-25.8c0-4.5,3.7-8,8-8h54.4c4.5,0,8,3.7,8,8L490.8,599.4L490.8,599.4z M436.4,524.8c-8.9,0-13.4-6.7-10-14.9
                l28.5-69.5c3.3-8.2,13.4-14.9,22.2-14.9h80h80c8.9,0,18.9,6.7,22.2,14.9l28.5,69.5c3.3,8.2-1.1,14.9-10,14.9H557.1H436.4z
                M693.9,599.4c0,4.4-3.6,8-8,8h-54.4c-4.4,0-8-3.6-8-8v-25.8c0-4.4,3.6-8,8-8h54.4c4.4,0,8,3.6,8,8V599.4z"
            />
            <path
                fill={ this.props.primary }
                d="M545,385.5c-0.2-0.6-0.5-1.3-0.7-2c-0.2-0.8-0.5-1.6-0.7-2.5c-0.2,0.9-0.5,1.8-0.7,2.6c-0.2,0.8-0.5,1.4-0.7,2l-3.8,10.9
                H549L545,385.5z"
            />
            <path
                fill={ this.props.primary }
                d="M269.8,277.6c-22.2,21.7-52.4,37.1-85.8,37.1s-63.6-15.5-85.8-37.1c-22.9,22.3-38.3,55.4-38.3,89.8v35.1
                c0,9.7,7.9,17.6,17.6,17.6h211.9c9.7,0,17.6-7.9,17.6-17.6v-35.1C306.9,333,292.6,300,269.8,277.6z"
            />
            <path
                fill={ this.props.primary }
                d="M291.3,201.8c0,6.9-0.7,13.7-1.9,20.2c21.7-8.6,45.4-13.3,70.2-13.3c84.6,0,156.5,55.1,181.9,131.2h36.7
                c-10.3-37.4-30.1-71.7-58.3-99.8c-42.8-42.8-99.7-66.4-160.2-66.4c-24,0-47.4,3.7-69.6,10.9C290.8,190.2,291.3,195.9,291.3,201.8z"
                />
            <path
                fill={ this.props.primary }
                d="M363.4,591.9c-1.3,0-2.6,0-3.9,0c-92,0-169.1-65.2-187.5-151.9h-35.7c8,45.4,29.6,87.2,62.9,120.5
                c42.8,42.8,99.7,66.4,160.2,66.4c1.3,0,2.6,0,3.9,0V591.9z"
            />
        </svg>);
    }
}

export default MeetGreetIcon;
