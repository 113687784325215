import React from 'react';
import styled from 'styled-components';

const StyledDivider = styled.hr`
    background-color: ${ props => ( props.color || 'rgb(224, 224, 224)' ) };
    border: none;
    height: 1px;
    margin-bottom: ${ props => ( props.marginBottom || '0' ) };
    margin-left: ${ props => ( props.marginLeft || '0' ) };
    margin-right: ${ props => ( props.marginRight || '0' ) };
    margin-top: ${ props => ( props.marginTop || '0' ) };
`

const StyledDividerVertical = styled.div`
    background-color: ${ props => ( props.color || 'rgb(224, 224, 224)' ) };
    width: 1px;
    margin-bottom: ${ props => ( props.marginBottom || '0' ) };
    margin-left: ${ props => ( props.marginLeft || '0' ) };
    margin-right: ${ props => ( props.marginRight || '0' ) };
    margin-top: ${ props => ( props.marginTop || '0' ) };
`

export const Divider = props => {
    return (
        <StyledDivider {...props} />
    );
}

export const DividerVertical = props => {
    return (
        <StyledDividerVertical {...props} />
    );
}

export default Divider;
