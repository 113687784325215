import React from 'react';
import BaseIcon from './BaseIcon';

class LicensePlaceIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="license-plate" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M269.4,471.6H255c-1.6,0-2.9-0.6-3.9-1.8c-1-1.2-1.8-2.7-2.3-4.5l-6.1-26.3H207l-6.1,26.3c-0.4,1.6-1.1,3-2.2,4.3
                c-1,1.3-2.3,1.9-3.9,1.9h-14.5l35.1-137.8h19L269.4,471.6z M238.2,419.3l-9.7-42.2c-0.6-2.2-1.2-4.8-1.8-7.8s-1.2-6.2-1.8-9.7
                c-0.6,3.5-1.2,6.8-1.8,9.8s-1.2,5.6-1.8,7.8l-9.6,42h26.5V419.3z"
            />
            <path
                fill={ this.props.primary }
                d="M319.4,333.7c6,0,11.2,0.9,15.4,2.6c4.3,1.7,7.7,4.2,10.4,7.4c2.7,3.2,4.7,7.1,5.9,11.6c1.2,4.6,1.9,9.7,1.9,15.3
                c0,3.2-0.3,6.2-0.9,9.1s-1.5,5.7-2.8,8.2c-1.3,2.6-2.9,4.9-4.9,7s-4.3,3.9-7,5.3c6.1,2.2,10.6,5.8,13.5,10.8s4.4,11.5,4.4,19.5
                c0,5.8-0.7,11.2-2.2,16.3c-1.5,5-3.6,9.3-6.4,13s-6.3,6.5-10.4,8.6s-8.8,3.1-14.1,3.1h-34.8V333.7H319.4z M306.1,355.4v37.3h12.2
                c2.6,0,4.9-0.3,6.9-1s3.7-1.7,5.1-3.2s2.4-3.4,3.1-5.7c0.7-2.4,1.1-5.2,1.1-8.6s-0.3-6.3-0.9-8.7c-0.6-2.4-1.5-4.3-2.8-5.8
                c-1.2-1.5-2.8-2.5-4.7-3.2c-1.9-0.7-4.1-1-6.7-1L306.1,355.4L306.1,355.4z M322,449.8c3,0,5.4-0.6,7.3-1.7s3.5-2.6,4.6-4.4
                c1.2-1.8,2-3.9,2.4-6.3c0.5-2.4,0.7-4.8,0.7-7.4c0-2.8-0.3-5.3-0.8-7.5s-1.4-4.1-2.6-5.7s-2.8-2.7-4.7-3.6c-1.9-0.8-4.3-1.2-7.1-1.2
                H306v37.7h16V449.8z"
            />
            <path
                fill={ this.props.primary }
                d="M440.6,437.7c1,0,1.9,0.6,2.6,1.7l7.4,12.1c-3.6,7.1-8.1,12.5-13.5,16.2s-11.8,5.5-19.2,5.5c-6.7,0-12.8-1.8-18.1-5.3
                c-5.4-3.5-9.9-8.4-13.7-14.7c-3.8-6.3-6.6-13.7-8.7-22.3c-2-8.6-3-18-3-28.2c0-6.8,0.5-13.4,1.5-19.5c1-6.2,2.4-11.9,4.2-17.1
                c1.9-5.2,4.1-9.9,6.7-14.1s5.6-7.7,8.9-10.7c3.3-2.9,6.9-5.2,10.8-6.7c3.9-1.6,8-2.3,12.5-2.3c3.3,0,6.4,0.4,9.4,1.3
                c2.9,0.9,5.7,2.1,8.3,3.7c2.6,1.6,4.9,3.5,7.1,5.7s4.1,4.7,5.8,7.4l-6.2,13.1c-0.4,0.8-0.8,1.6-1.4,2.2s-1.4,0.9-2.3,0.9
                s-2-0.5-3-1.6s-2.2-2.3-3.7-3.6c-1.5-1.3-3.3-2.5-5.5-3.6s-5-1.6-8.4-1.6c-3.8,0-7.2,1-10.3,3.1c-3.1,2.1-5.8,5.1-8.1,9.1
                c-2.2,4-4,8.8-5.2,14.6c-1.2,5.8-1.9,12.3-1.9,19.7c0,7.4,0.7,14,2,19.8s3.2,10.7,5.5,14.7s5,7,8.2,9.1c3.1,2.1,6.4,3.1,10,3.1
                c2.1,0,4-0.2,5.7-0.5s3.3-0.9,4.8-1.6c1.5-0.8,2.8-1.7,4.1-2.9c1.3-1.2,2.6-2.7,3.9-4.6c0.5-0.6,0.9-1,1.4-1.4
                C439.5,437.9,440,437.7,440.6,437.7z"
            />
            <path
                fill={ this.props.primary }
                d="M468.3,403.3h32.6v21.5h-32.6V403.3z"
            />
            <path
                fill={ this.props.primary }
                d="M582,452.3v19.3h-50.3v-19.3h17.8v-75.5c0-1.6,0-3.3,0.1-5c0-1.7,0.1-3.5,0.2-5.2l-11.7,15c-0.8,1-1.6,1.6-2.4,1.8
                c-0.8,0.3-1.5,0.3-2.2,0c-0.7-0.2-1.3-0.6-1.9-1.1c-0.5-0.5-0.9-1.1-1.2-1.7l-5.4-11.1l27.6-35.9h14v118.7L582,452.3L582,452.3z"
            />
            <path
                fill={ this.props.primary }
                d="M659.8,447.6c1.9,0,3.3,0.8,4.4,2.4c1.1,1.6,1.6,3.7,1.6,6.3v15.4h-62.2v-8.6c0-1.6,0.2-3.4,0.7-5.4
                c0.5-1.9,1.2-3.7,2.3-5.3l26.6-41c2.3-3.5,4.3-6.8,6-10s3.2-6.3,4.3-9.4c1.2-3.1,2-6.2,2.6-9.4c0.6-3.2,0.9-6.5,0.9-10
                c0-6.1-1-10.8-3.1-14s-5-4.8-8.7-4.8c-1.6,0-3.2,0.4-4.5,1.1c-1.4,0.7-2.6,1.7-3.7,3c-1.1,1.3-2,2.8-2.8,4.5s-1.4,3.6-1.7,5.6
                c-0.7,3.2-1.7,5.3-3,6.3s-3,1.3-5.3,0.7l-9-2.5c0.7-6.7,1.9-12.6,3.7-17.7s4.1-9.3,6.8-12.6c2.7-3.4,5.8-5.9,9.3-7.6
                s7.3-2.5,11.3-2.5c4.2,0,8.1,1,11.6,2.9s6.5,4.6,8.9,8.1c2.5,3.5,4.4,7.6,5.8,12.5c1.4,4.9,2,10.3,2,16.2c0,5.1-0.5,9.8-1.4,14.1
                c-0.9,4.3-2.2,8.4-3.9,12.3s-3.5,7.7-5.7,11.4c-2.1,3.7-4.4,7.4-6.8,11.1l-19,29.9c2.1-1,4.2-1.7,6.2-2.2c2.1-0.5,4-0.8,5.8-0.8
                H659.8z"
            />
            <path
                fill={ this.props.primary }
                d="M687,372.6c0.7-6.7,1.9-12.6,3.7-17.7s4.1-9.3,6.8-12.6c2.7-3.4,5.8-5.9,9.3-7.6s7.2-2.5,11.3-2.5c4.3,0,8.1,0.9,11.5,2.8
                s6.3,4.4,8.6,7.7c2.3,3.2,4.2,7,5.4,11.3c1.3,4.3,1.9,8.9,1.9,13.9c0,4.4-0.3,8.2-0.9,11.5c-0.6,3.3-1.5,6.2-2.7,8.7
                s-2.6,4.6-4.3,6.3c-1.7,1.7-3.7,3.1-5.9,4.3c10.3,5.2,15.5,15.7,15.5,31.6c0,7-0.8,13.2-2.5,18.6s-4,9.8-6.8,13.4s-6.2,6.3-9.9,8.1
                c-3.8,1.8-7.7,2.7-11.8,2.7c-4.4,0-8.2-0.8-11.6-2.3c-3.3-1.5-6.3-3.8-8.8-6.8s-4.7-6.9-6.5-11.5s-3.4-10-4.8-16.1l7.5-4.8
                c2-1.3,3.8-1.6,5.5-1s2.9,2,3.7,4.2c0.8,2.4,1.7,4.6,2.6,6.7c0.9,2,2,3.8,3.1,5.3c1.2,1.5,2.5,2.6,3.9,3.5c1.5,0.8,3.1,1.2,5,1.2
                c2.4,0,4.5-0.6,6.2-1.8c1.8-1.2,3.3-2.8,4.5-4.7c1.2-1.9,2.1-4.1,2.7-6.6c0.6-2.4,0.9-4.9,0.9-7.3c0-3.2-0.2-6-0.6-8.6
                c-0.4-2.6-1.2-4.8-2.6-6.6c-1.3-1.8-3.3-3.2-5.9-4.2c-2.6-1-6.2-1.5-10.7-1.5v-18.4c3.7,0,6.8-0.5,9.2-1.4c2.4-1,4.3-2.3,5.6-4
                c1.4-1.7,2.3-3.8,2.8-6.2c0.5-2.4,0.8-5,0.8-7.9c0-6-1-10.6-3.1-13.8c-2-3.2-4.9-4.8-8.7-4.8c-1.6,0-3.2,0.4-4.5,1.1
                c-1.4,0.7-2.6,1.7-3.7,3c-1.1,1.3-2,2.8-2.8,4.5s-1.4,3.6-1.7,5.6c-0.8,3.2-1.8,5.3-3,6.3s-3,1.3-5.4,0.7L687,372.6z"
            />
            <path
                fill={ this.props.primary }
                d="M736,282.2H161.1h-20H64c-29.5,0-53.6,24-53.6,53.6v128.6c0,29.5,24,53.6,53.6,53.6h77.1h20H736c29.5,0,53.6-24,53.6-53.6
                V335.7C789.5,306.2,765.5,282.2,736,282.2z M123.2,365.9c0,0.6,0,1.1,0,1.5c-0.1,2.6-2.2,4.6-4.8,4.6c-0.1,0-0.1,0-0.2,0
                c-2.7-0.1-4.7-2.3-4.6-5c0-0.3,0-0.7,0-1.1v-0.1c0-2.7,2.2-4.8,4.8-4.8C121,361.1,123.2,363.3,123.2,365.9z M120,381.2
                c-0.2,0.4-0.4,0.9-0.6,1.3c-0.8,1.7-2.5,2.7-4.3,2.7c-0.7,0-1.4-0.2-2.1-0.5c-2.4-1.2-3.4-4.1-2.2-6.4c0.2-0.3,0.3-0.6,0.4-0.9
                c1.1-2.4,3.9-3.5,6.3-2.4C120,375.9,121,378.7,120,381.2z M119.9,350.8c0.2,0.4,0.4,0.9,0.6,1.3c1,2.5-0.2,5.3-2.6,6.3
                c-0.6,0.2-1.2,0.4-1.8,0.4c-1.9,0-3.7-1.1-4.5-3c-0.1-0.3-0.3-0.6-0.4-1c-1.1-2.4,0-5.3,2.4-6.3C116,347.3,118.8,348.4,119.9,350.8z
                M104,338.8c1.8-2,4.8-2.1,6.8-0.4c0.4,0.3,0.7,0.6,1,1c1.9,1.8,2,4.9,0.1,6.8c-0.9,1-2.2,1.5-3.5,1.5c-1.2,0-2.4-0.4-3.3-1.3
                c-0.3-0.2-0.5-0.5-0.8-0.7C102.4,343.8,102.2,340.7,104,338.8z M103.7,387.2c0.3-0.2,0.5-0.5,0.8-0.7c2-1.8,5-1.6,6.8,0.3
                c1.8,2,1.6,5-0.3,6.8c-0.3,0.3-0.7,0.6-1.1,0.9c-0.9,0.8-2,1.1-3.1,1.1c-1.4,0-2.7-0.6-3.7-1.7C101.4,392,101.6,388.9,103.7,387.2z
                M91.3,333.8c0.8-2.5,3.5-3.9,6-3.1c0.5,0.1,0.9,0.3,1.3,0.5c2.5,0.9,3.8,3.7,2.9,6.2c-0.7,2-2.6,3.2-4.5,3.2
                c-0.5,0-1.1-0.1-1.6-0.3c-0.3-0.1-0.7-0.2-1-0.3C91.9,339,90.5,336.3,91.3,333.8z M93.5,392.6c0.3-0.1,0.7-0.2,1-0.3
                c2.5-0.8,5.2,0.6,6.1,3.1c0.9,2.5-0.6,5.2-3.1,6.1c-0.4,0.1-0.9,0.3-1.4,0.4c-0.4,0.1-0.9,0.2-1.3,0.2c-2.1,0-4-1.4-4.6-3.5
                C89.5,396,91,393.3,93.5,392.6z M82,329.1c0.5,0,0.9-0.1,1.4-0.1c2.7-0.2,4.9,1.8,5.1,4.5c0.2,2.6-1.8,4.9-4.5,5.1
                c-0.4,0-0.7,0.1-1,0.1c-0.2,0-0.3,0-0.5,0c-2.4,0-4.5-1.8-4.8-4.3C77.5,331.8,79.4,329.4,82,329.1z M67.4,333.9
                c0.4-0.2,0.8-0.5,1.2-0.7c2.3-1.2,5.2-0.4,6.5,2c1.2,2.3,0.4,5.2-2,6.5c-0.3,0.2-0.6,0.3-0.9,0.5c-0.8,0.4-1.6,0.7-2.4,0.7
                c-1.7,0-3.3-0.9-4.2-2.4C64.3,338.2,65.1,335.3,67.4,333.9z M64.8,390.9c1.4-2.2,4.4-2.9,6.6-1.5c0.3,0.2,0.6,0.4,0.9,0.5
                c2.3,1.3,3.1,4.3,1.8,6.6c-0.9,1.5-2.5,2.4-4.2,2.4c-0.8,0-1.6-0.2-2.4-0.6c-0.4-0.2-0.8-0.5-1.2-0.7
                C64,396.1,63.3,393.2,64.8,390.9z M62.7,389c-0.9,0.6-1.8,0.9-2.8,0.9c-1.5,0-2.9-0.7-3.9-2c-0.3-0.4-0.5-0.8-0.8-1.2
                c-1.5-2.2-0.9-5.2,1.3-6.7s5.2-0.9,6.7,1.3c0.2,0.3,0.4,0.6,0.6,0.9C65.3,384.4,64.8,387.4,62.7,389z M55.9,344.3
                c0.3-0.4,0.6-0.8,0.9-1.1c1.6-2.1,4.7-2.5,6.7-0.8c2.1,1.6,2.5,4.7,0.8,6.7c-0.2,0.3-0.4,0.6-0.6,0.8c-0.9,1.3-2.4,2-3.9,2
                c-1,0-2-0.3-2.8-0.9C54.8,349.4,54.4,346.4,55.9,344.3z M49.7,358.4c0.1-0.5,0.2-0.9,0.3-1.4c0.6-2.6,3.2-4.1,5.8-3.5
                c2.6,0.6,4.1,3.2,3.5,5.8c-0.1,0.3-0.2,0.7-0.2,1c-0.5,2.3-2.5,3.8-4.7,3.8c-0.3,0-0.7,0-1-0.1C50.8,363.6,49.1,361,49.7,358.4z
                M53.3,366.9c2.6-0.5,5.1,1.3,5.6,3.9c0.1,0.3,0.1,0.7,0.2,1c0.6,2.6-1.1,5.1-3.7,5.7c-0.3,0.1-0.7,0.1-1,0.1
                c-2.2,0-4.2-1.5-4.7-3.8c-0.1-0.5-0.2-0.9-0.3-1.4C49,369.8,50.7,367.4,53.3,366.9z M78.6,430.5H57.3v12.8h16.6v7.8H57.3v13h21.3v8
                H47v-49.6h31.6V430.5z M82.2,403c-0.5,0-0.9-0.1-1.4-0.2c-2.6-0.4-4.5-2.8-4.1-5.4c0.4-2.6,2.8-4.5,5.4-4.1c0.3,0,0.7,0.1,1,0.1
                c2.6,0.3,4.6,2.6,4.3,5.3c-0.3,2.5-2.3,4.3-4.8,4.3C82.5,403,82.3,403,82.2,403z M127.5,452c0,3-0.5,5.8-1.5,8.3s-2.4,4.7-4.2,6.5
                s-4,3.3-6.6,4.3c-2.6,1-5.5,1.5-8.7,1.5s-6.2-0.5-8.7-1.5c-2.6-1-4.8-2.5-6.6-4.3s-3.2-4-4.2-6.5s-1.5-5.3-1.5-8.3v-29.6h10.3V452
                c0,1.8,0.2,3.5,0.7,4.9c0.5,1.5,1.2,2.7,2.1,3.7c0.9,1,2,1.8,3.4,2.4c1.3,0.6,2.8,0.8,4.5,0.8s3.2-0.3,4.5-0.8
                c1.3-0.6,2.4-1.4,3.4-2.4c0.9-1,1.6-2.3,2.1-3.7c0.5-1.5,0.7-3.1,0.7-4.9v-29.6h10.3V452z M769.5,464.3c0,18.5-15.1,33.6-33.6,33.6
                H161.1V302.2H736c18.5,0,33.6,15.1,33.6,33.6V464.3z"
            />
        </svg>);
    }
}

export default LicensePlaceIcon;
