import React from 'react';
import Checkbox from '../../components/Checkbox';
import Divider from '../../components/Divider';
import PropTypes from 'prop-types';
import Table from '../../components/table/Table';
import TableRow from '../../components/table/TableRow';
import legalLink from '../../localisation/legalDocs';
import { ConfirmationConsumer } from '../../context/Confirmation';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

class BookingDetailsTable extends React.Component {
    render() {
        return (
            <ConfirmationConsumer>
                {({ createPersonalDetails, personal, searchDetails }) => {
                    const { typeOfBooking } = searchDetails;
                    const isParking = typeOfBooking === 'parking';
                    const isTaxi = typeOfBooking === 'taxi';
                    const isTransfer = typeOfBooking === 'transfer';

                    return (
                        <div>
                            <Table>
                                <TableRow
                                    label={ t('confirmation:bookingDetailsTable.name') }
                                    value={ `${ personal.firstname || '-' } ${personal.surname || '-' }` }
                                />

                                <TableRow
                                    label={ t('confirmation:bookingDetailsTable.email') }
                                    value={ personal.email || '-' }
                                />

                                <TableRow
                                    label={ t('confirmation:bookingDetailsTable.phone') }
                                    value={ personal.phone || '-' }
                                />

                                { isParking &&
                                    <TableRow
                                        label={ t('confirmation:bookingDetailsTable.licensePlate') }
                                        value={ personal.licensePlate || '-' }
                                    />
                                }

                                { isParking &&
                                    <TableRow
                                        label={ t('confirmation:bookingDetailsTable.flightNumberReturn') }
                                        value={ personal.flight || '-' }
                                    />
                                }

                                { (isTaxi || isTransfer) &&
                                    <TableRow
                                        label={ t('confirmation:bookingDetailsTable.flightNumber') }
                                        value={ personal.flight || '-' }
                                    />
                                }

                                <TableRow
                                    label={ t('confirmation:bookingDetailsTable.comments') }
                                    value={ personal.comments || '-' }
                                />
                            </Table>

                            <Divider marginTop="20px" marginBottom="20px" />

                            <Checkbox
                                checked={ personal.termsAndConditionsAccepted }
                                onChange={ (event, value) => { createPersonalDetails(event.target.name, value) } }
                                name="termsAndConditionsAccepted"
                                containsLink
                                label={
                                    <Trans i18nKey="confirmation:acceptTermsAndConditions">
                                        <span>I accept the MOBIAN </span>
                                        <a href={ legalLink('general.termsConditions') } target="_blank" rel="noopener noreferrer">terms and conditions</a>
                                    </Trans>
                                }
                            />
                        </div>
                    )
                }}
            </ConfirmationConsumer>
        )
    }
}

BookingDetailsTable.propTypes = {
    handleCheckboxChange: PropTypes.func
}

export default BookingDetailsTable;
