import React from 'react';
import BaseIcon from './BaseIcon';

class EventCentreIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="event-centre" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 491 491">
            <path
                fill={ this.props.primary }
                d="M477.3,399H467c1.9-2,3.1-4.7,3.1-7.6c0-6-4.9-10.9-10.9-10.9h-12.9V260.2h0.5c6,0,10.9-4.9,10.9-10.9c0-2.7-1-5.2-2.7-7.2
                h4.1c5.3,0,9.9-3.8,10.8-9.1c0.9-5.2-2.1-10.4-7.1-12.1c-46.1-16.4-92.6-27.7-139.1-33.6v-62.2c7.5-1.3,13.5,0.7,19.3,2.5
                c4.3,1.4,8.7,2.8,13.5,2.8c2.6,0,5.3-0.4,8.2-1.4c0.7-0.2,1.1-0.8,1.1-1.5v-29c0-0.5-0.3-1-0.7-1.3c-0.4-0.3-1-0.4-1.5-0.2
                c-7.3,2.6-13,0.7-19.6-1.4c-5.8-1.9-12.3-4-20.5-2.7c-0.5-1.6-1.9-2.7-3.6-2.7c-2.1,0-3.8,1.7-3.8,3.8v92.5
                c-22.4-2.6-44.9-4-67.2-4.1v-73.5c8.7-2,15.3,0.1,22.2,2.3c4.7,1.5,9.5,3.1,14.9,3.1c2.9,0,5.9-0.4,9.1-1.6c1.1-0.4,1.8-1.4,1.8-2.6
                V78.7c0-0.9-0.4-1.7-1.2-2.2c-0.7-0.5-1.6-0.6-2.5-0.3c-7.6,2.7-13.6,0.7-20.5-1.6c-7-2.3-14.8-4.8-25-2.5c-0.9-1.1-2.3-1.9-3.8-1.9
                c-2.7,0-4.9,2.2-4.9,4.9v107.2c-21.9,0.2-43.9,1.7-65.9,4.3v-61.5c7.5-1.3,13.5,0.7,19.3,2.5c4.3,1.4,8.7,2.8,13.5,2.8
                c2.6,0,5.3-0.4,8.2-1.4c0.7-0.2,1.1-0.8,1.1-1.5v-29c0-0.5-0.3-1-0.7-1.3c-0.4-0.3-1-0.4-1.5-0.2c-7.3,2.6-13,0.7-19.6-1.4
                c-5.8-1.9-12.3-4-20.5-2.7c-0.5-1.6-1.9-2.7-3.6-2.7c-2.1,0-3.8,1.7-3.8,3.8v93.6c-45.9,6-91.9,17.2-137.5,33.4
                c-5,1.8-8,6.9-7.1,12.1c0.9,5.2,5.4,9.1,10.8,9.1h4.1c-1.7,1.9-2.7,4.4-2.7,7.2c0,5.2,3.7,9.6,8.6,10.7v120.4h-10
                c-6,0-10.9,4.9-10.9,10.9c0,3,1.2,5.7,3.1,7.6H13.7c-6,0-10.9,4.9-10.9,10.9c0,6,4.9,10.9,10.9,10.9h463.6c6,0,10.9-4.9,10.9-10.9
                C488.2,403.8,483.3,399,477.3,399z M142.9,380.4H63.6v-54.6h79.3V380.4z M142.9,314.9H63.6v-54.6h79.3V314.9z M238.6,380.4h-84.8
                v-54.6h84.8V380.4z M238.6,314.9h-84.8v-54.6h84.8V314.9z M345.2,260.2h79.3v54.6h-79.3V260.2z M334.3,380.4h-84.8v-54.6h84.8V380.4
                z M334.3,314.9h-84.8v-54.6h84.8V314.9z M345.2,325.8h79.3v54.6h-79.3V325.8z"
            />
        </svg>);
    }
}

export default EventCentreIcon;
