import React from 'react';
import BaseIcon from './BaseIcon';

class Open247Icon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="open-24-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M366,431.1c3.7-3.9,5.6-9.2,5.6-16c0-3.1-0.5-5.9-1.4-8.4s-2.4-4.7-4.3-6.5s-4.3-3.2-7.2-4.2c-2.9-1-6.4-1.5-10.4-1.5H332
		        V437h16.3C356.4,437,362.3,435,366,431.1z"
            />
            <path
                fill={ this.props.primary }
                d="M239.4,476.3c4.6-1.9,8.5-4.7,11.7-8.3c3.2-3.6,5.7-8,7.4-13.2s2.6-11,2.6-17.6c0-6.5-0.9-12.4-2.6-17.5s-4.2-9.6-7.4-13.2
                s-7.1-6.4-11.7-8.3c-4.6-1.9-9.8-2.9-15.7-2.9c-5.8,0-11.1,1-15.7,2.9c-4.6,1.9-8.6,4.7-11.8,8.3c-3.2,3.6-5.7,8-7.4,13.2
                s-2.6,11-2.6,17.5c0,6.6,0.9,12.4,2.6,17.6s4.2,9.6,7.4,13.2s7.2,6.4,11.8,8.3s9.9,2.9,15.7,2.9
                C229.6,479.2,234.8,478.2,239.4,476.3z"
            />
            <path
                fill={ this.props.primary }
                d="M364.1,609.1v-37c0-1.9,0.1-4,0.2-6.2s0.4-4.5,0.6-6.9l-36,50.2L364.1,609.1L364.1,609.1z"
            />
            <path
                fill={ this.props.primary }
                d="M746.5,347.2c0-0.1,0-0.3,0-0.4c0-0.4-0.1-0.8-0.1-1.2c0-0.1,0-0.2,0-0.3c-0.1-0.5-0.2-0.9-0.3-1.4c0-0.2-0.1-0.4-0.1-0.5
                c-0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.3-0.2-0.6-0.3-0.9s-0.3-0.7-0.4-1c-0.1-0.1-0.1-0.3-0.2-0.4
                c-0.1-0.3-0.3-0.6-0.4-0.9l0,0c-2.3-4.8-6.1-8.7-10.9-11l0,0L482,204.5c1.9-6.9,2.9-14.1,2.9-21.6c0-45.3-36.8-82.1-82.1-82.1
                s-82.1,36.8-82.1,82.1c0,6.6,0.8,13,2.3,19.2L66.6,327.4l0,0c-4.7,2.3-8.6,6.2-10.9,11l0,0c-0.1,0.3-0.3,0.6-0.4,0.9
                c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.3-0.3,0.7-0.4,1c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.3-0.2,0.6-0.2,0.9
                c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.5-0.2,0.9-0.3,1.4c0,0.1,0,0.2,0,0.3c-0.1,0.4-0.1,0.8-0.1,1.2c0,0.1,0,0.3,0,0.4
                c0,0.5-0.1,1-0.1,1.5v326.8c0,0.8,0,1.6,0.1,2.4c0.1,0.8,0.2,1.6,0.4,2.4c0.6,3.1,1.9,6,3.6,8.5c0.5,0.7,0.9,1.3,1.5,1.9
                c0.5,0.5,0.9,1.1,1.4,1.6s1,1,1.6,1.4c0.6,0.5,1.3,1,1.9,1.5c2.5,1.7,5.4,2.9,8.5,3.6c0.8,0.2,1.6,0.3,2.4,0.4
                c0.8,0.1,1.6,0.1,2.4,0.1H723c0.8,0,1.6,0,2.4-0.1c0.8-0.1,1.6-0.2,2.4-0.4c3.1-0.6,6-1.9,8.5-3.6c0.7-0.5,1.3-0.9,1.9-1.5
                c0.5-0.5,1.1-0.9,1.6-1.4s1-1,1.4-1.6c0.5-0.6,1-1.3,1.5-1.9c1.7-2.5,2.9-5.4,3.6-8.5c0.2-0.8,0.3-1.6,0.4-2.4
                c0.1-0.8,0.1-1.6,0.1-2.4V348.7C746.6,348.2,746.6,347.7,746.5,347.2z M402.8,150.8c17.7,0,32.1,14.4,32.1,32.1
                S420.5,215,402.8,215s-32.1-14.4-32.1-32.1S385,150.8,402.8,150.8z M177.5,482.7c-5.6-5.7-9.9-12.4-13-20.2
                c-3.1-7.8-4.6-16.2-4.6-25.3c0-9.1,1.5-17.5,4.6-25.3c3.1-7.7,7.4-14.4,13-20.1s12.3-10.1,20.2-13.3c7.8-3.2,16.5-4.8,26.1-4.8
                c9.5,0,18.2,1.6,26.1,4.8c7.8,3.2,14.5,7.7,20.1,13.4c5.6,5.7,9.9,12.4,12.9,20.2c3.1,7.7,4.6,16.1,4.6,25.2s-1.5,17.5-4.6,25.3
                s-7.4,14.5-12.9,20.2c-5.6,5.7-12.3,10.1-20.1,13.3c-7.8,3.2-16.5,4.8-26.1,4.8c-9.5,0-18.2-1.6-26.1-4.8
                C189.8,492.8,183.1,488.3,177.5,482.7z M296.5,656.6h-86.3v-7.7c0-1.5,0.3-3.1,0.9-4.8c0.6-1.7,1.7-3.3,3.2-4.8l36.9-37
                c3.1-3.1,5.9-6.2,8.3-9c2.4-2.9,4.4-5.7,6-8.5s2.8-5.6,3.6-8.4c0.8-2.9,1.2-5.9,1.2-9c0-5.5-1.4-9.8-4.3-12.6
                c-2.9-2.9-6.9-4.3-12.1-4.3c-2.3,0-4.4,0.3-6.3,1c-1.9,0.7-3.6,1.6-5.1,2.7c-1.5,1.1-2.8,2.5-3.9,4c-1.1,1.5-1.9,3.2-2.4,5.1
                c-1,2.9-2.4,4.8-4.2,5.7c-1.7,0.9-4.2,1.2-7.4,0.6l-12.5-2.2c0.9-6.1,2.6-11.4,5.1-16s5.7-8.4,9.4-11.4c3.8-3,8.1-5.3,12.9-6.8
                c4.9-1.5,10.1-2.3,15.7-2.3c5.9,0,11.2,0.9,16.1,2.6c4.8,1.7,9,4.2,12.4,7.3s6.1,6.9,8,11.3c1.9,4.4,2.8,9.3,2.8,14.6
                c0,4.6-0.7,8.8-2,12.7s-3.1,7.6-5.4,11.1s-4.9,6.9-7.8,10.2c-3,3.3-6.1,6.7-9.4,10l-26.4,27c2.9-0.9,5.8-1.5,8.7-2s5.5-0.7,8.1-0.7
                h28c2.6,0,4.6,0.7,6,2.1c1.5,1.4,2.2,3.3,2.2,5.7V656.6z M399.1,622.5c0,1.3-0.4,2.3-1.2,3.2c-0.8,0.9-2,1.3-3.5,1.3h-9.7v29.6
                h-20.6V627h-51.4c-1.5,0-2.8-0.5-4.1-1.4c-1.2-0.9-2-2.1-2.3-3.6l-2.5-11.8l58.3-78.1h22.5v77H399v13.4H399.1z M306.3,499.5V375h42
                c8.5,0,15.9,1,22.1,3s11.3,4.8,15.3,8.4s7,7.8,8.9,12.7c1.9,4.9,2.9,10.3,2.9,16c0,6.1-1,11.7-3,16.8c-2,5.1-5,9.5-9.1,13.1
                c-4.1,3.7-9.2,6.5-15.4,8.5c-6.2,2-13.4,3-21.7,3H332v42.8h-25.7V499.5z M430.4,656.2c-1.1,2.9-2.8,5-5.2,6.5
                c-2.4,1.5-4.8,2.2-7.2,2.2h-10l49.5-128c1-2.6,2.5-4.6,4.6-6s4.5-2.1,7.3-2.1h10.1L430.4,656.2z M481.2,427.2v19.5h-41.5v32.7H493
                v20.2h-79.2V375H493v20.2h-53.3v32H481.2z M578.3,542.3c0,3-0.3,5.4-1,7.2s-1.3,3.4-1.9,4.6l-45.3,94.6c-1.1,2.2-2.7,4-4.6,5.6
                c-1.9,1.5-4.6,2.3-7.9,2.3h-17.2l46.4-92.4c1.1-2.2,2.3-4.1,3.5-5.9c1.2-1.8,2.5-3.5,4-5.1H497c-1.4,0-2.6-0.5-3.7-1.6
                s-1.7-2.3-1.7-3.7v-15.8h86.7V542.3z M618.9,499.5h-13.3c-2,0-3.7-0.3-5.1-1c-1.4-0.7-2.7-1.8-4-3.4l-62-78.9c0.2,2,0.3,4,0.4,5.9
                c0.1,1.9,0.1,3.7,0.1,5.4v71.9h-22.7V375h13.5c1.1,0,2,0,2.8,0.1s1.5,0.3,2.1,0.6c0.6,0.3,1.2,0.7,1.8,1.2s1.2,1.2,1.9,2.1
                l62.4,79.2c-0.2-2.2-0.4-4.3-0.5-6.3s-0.2-4-0.2-5.8V375h22.7L618.9,499.5L618.9,499.5z M648,325h-1.1H153.1H152h-12.2l195.4-95.5
                c14.8,21.4,39.6,35.5,67.6,35.5c27.1,0,51.2-13.2,66.1-33.5L660.2,325H648z"
            />
        </svg>);
    }
}

export default Open247Icon;
