import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTitle = styled.p`
    font-family: Roboto, sans-serif;
    font-size: ${ props => props.fontSize };
    font-weight: 500;
    margin: 0 0 10px 0;
    ${ props => props.uppercase && 'text-transform: uppercase;' }
`;

const Title = ({ title, uppercase, fontSize }) => {
    return (
        <StyledTitle
            uppercase={ uppercase }
            fontSize={ fontSize }
        >
            { title }
        </StyledTitle>
    );
}

Title.defaultProps = {
    fontSize: '20px',
}

Title.propTypes = {
    fontSize: PropTypes.string,
    title: PropTypes.string.isRequired,
    uppercase: PropTypes.bool
}

export default Title;
