import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledProductName = styled.p`
    font-size: 25px;
    font-weight: 500;
    margin: 0;
`;

const ProductName = ({ title }) => {
    return (
        <StyledProductName>
            { title }
        </StyledProductName>
    );
}

ProductName.propTypes = {
    title: PropTypes.string.isRequired,
}

export default ProductName;
