import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledGrid = styled.div`
    grid-template-columns: repeat(${ props => props.columns }, 1fr);
    display: grid;
    grid-column-gap: 20px;
`;

const CSSGridContainer = ({ children, columns, className }) => {
    // Added a prop to all the grid container children so they can check if it is a css grid.
    const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, { cssGrid: !!columns })
    );

    return (
        <StyledGrid
            columns={ columns }
            // The line below is needed for styled components
            className={ className }
        >
            { childrenWithProps }
        </StyledGrid>
    );
}

CSSGridContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    columns: PropTypes.number.isRequired,
    className: PropTypes.string,
}

export default CSSGridContainer;
