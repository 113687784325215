import React from 'react';
import BaseIcon from './BaseIcon';

class CallIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="call" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M649.3,541.1L571.7,462c-15.5-15.7-41-15.2-57,1.1l-39.1,39.8c-2.5-1.4-5-2.8-7.7-4.4c-24.7-13.9-58.5-33.1-94-69.3
                c-35.7-36.3-54.4-70.8-68.1-96c-1.4-2.7-2.8-5.2-4.2-7.7l26.2-26.7l12.9-13.2c16-16.3,16.5-42.4,1-58.2l-77.6-79.1
                c-15.4-15.7-41-15.2-57,1.1l-21.9,22.4l0.6,0.6c-7.3,9.5-13.5,20.5-18,32.4c-4.2,11.3-6.8,22.1-8,32.9
                c-10.2,86.6,28.6,165.7,133.9,273.1c145.6,148.4,262.9,137.2,268,136.7c11-1.3,21.6-4,32.3-8.3c11.5-4.6,22.3-10.8,31.7-18.3
                l0.5,0.4l22.1-22.1C664.2,583,664.7,556.9,649.3,541.1z"
            />
        </svg>);
    }
}

export default CallIcon;
