import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCardTitle = styled.p`
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    ${ props => props.titleSpaceOff ? '' : 'margin: -30px 0 10px;' }
`;

const CardTitle = ({ title, titleSpaceOff }) => {
    return (
        <StyledCardTitle titleSpaceOff={ titleSpaceOff } >{ title }</StyledCardTitle>
    );
}

CardTitle.propTypes = {
    title: PropTypes.string,
    titleSpaceOff: PropTypes.bool,
}

export default CardTitle;
