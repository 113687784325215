import React from 'react';
import BaseIcon from './BaseIcon';

class NeighbourhoodIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="neighbourhood" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                fill={ this.props.primary }
                d="M207.4,131.3c-4.8-11.9-2.4-25.4,6.4-35l1.6-1.6l8.7-5.6l-88.2-54.8L7.2,117c-5.6,5.6-1.6,15.9,6.4,15.9h15.9v148.5H97
                l133.4-85.8v-45.3C220.1,148.8,211.4,141.6,207.4,131.3z M165.3,193.2h-58v-58h58V193.2z"/>
            <path
                fill={ this.props.primary }
                d="M504.5,114.6L364.7,27.2l-135.8,87.4c-6.4,6.4-1.6,16.7,7.1,16.7h15.9v63.5L402,288.6h79.4V131.3h15.9
                C506,131.3,510.8,121,504.5,114.6z M396.4,193.2h-57.2v-58h58v58H396.4z"/>
            <path
                fill={ this.props.primary }
                d="M401.2,312.4l-158.9-99.3L87.4,312.4c-7.1,7.1-1.6,19.1,7.9,19.1h18.3v153.3H375V331.5h18.3
                C402.8,331.5,408.3,318.8,401.2,312.4z M288.4,429.2h-92.9v-92.9h92.9C288.4,336.2,288.4,429.2,288.4,429.2z"/>
        </svg>);
    }
}

export default NeighbourhoodIcon;
