const geo = {
    /**
     * Calculate the bearing between two positions as a value from 0-360
     *
     * @param {number} lat1 - The latitude of the first position
     * @param {number} lng1 - The longitude of the first position
     * @param {number} lat2 - The latitude of the second position
     * @param {number} lng2 - The longitude of the second position
     * @returns {number} - The bearing between 0 and 360
     */
    bearing : function (lat1,lng1,lat2,lng2) {
        var dLon = (lng2-lng1);
        var y = Math.sin(dLon) * Math.cos(lat2);
        var x = Math.cos(lat1)*Math.sin(lat2) - Math.sin(lat1)*Math.cos(lat2)*Math.cos(dLon);
        var brng = this.toDeg(Math.atan2(y, x));
        return 360 - ((brng + 360) % 360);
    },

    /**
     * Since not all browsers implement this we use this utility that will
     * convert from degrees into radians
     *
     * @param {number} - The degrees to be converted into radians
     * @returns {number} radians
     */
    toRad : function(deg) {
        return deg * Math.PI / 180;
    },

    /**
     * Since not all browsers implement this use this utility that will
     * convert from radians into degrees
     *
     * @param {number} rad - The radians to be converted into degrees
     * @returns {number} degrees
     */
    toDeg : function (rad) {
        return rad * 180 / Math.PI;
    },

    /**
     * We transform a bearing to cardinal directions
     *
     * @param {number} num
     * @returns {string}
     */
    degreesToCardinal : function (num) {
        const val = Math.floor((num / 22.5) + 0.5);
        const arr = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"];

        return arr[(val % 16)];
    },

    /**
     * Calculate the distance in metres between two lat/lon combinations.
     *
     * @param {number} lat1
     * @param {number} lng1
     * @param {number} lat2
     * @param {number} lng2
     * @returns {number} - Distance in metres.
     */
    distance : function (lat1,lng1,lat2,lng2) {
        var R = 6371e3; //  Radius of the earth in metres
        var pLat1 = this.toRad(lat1);
        var pLat2 = this.toRad(lat2);
        var dLat = this.toRad(lat2-lat1);
        var dLng = this.toRad(lng2-lng1);

        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                Math.cos(pLat1) * Math.cos(pLat2) *
                Math.sin(dLng/2) * Math.sin(dLng/2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        return R * c; // Distance in metres.
    },
}

export default geo;
