import React from 'react';
import BaseIcon from './BaseIcon';

class WaitingRoomIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="waiting-room" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path
                fill={ this.props.primary }
                d="M152.9,39.6l-14.4,14.4l-8.7-8.7c-1.4-1.4-3.7-1.4-5.2,0c-1.4,1.4-1.4,3.8,0,5.2l11.3,11.3c0.7,0.7,1.7,1.1,2.6,1.1
                s1.9-0.4,2.6-1.1l17-17c1.4-1.4,1.4-3.8,0-5.2C156.7,38.1,154.3,38.1,152.9,39.6z"
            />
            <path
                fill={ this.props.primary }
                d="M140.4,16.2c-21.2,0-38.5,17.3-38.5,38.5c0,21.2,17.3,38.5,38.5,38.5c21.2,0,38.5-17.3,38.5-38.5
                C178.9,33.5,161.6,16.2,140.4,16.2z M140.4,82.2c-15.2,0-27.5-12.3-27.5-27.5c0-15.2,12.3-27.5,27.5-27.5
                c15.2,0,27.5,12.3,27.5,27.5C167.9,69.9,155.5,82.2,140.4,82.2z"
            />
            <circle fill={ this.props.primary } cx="50.7" cy="35.2" r="16.5"/>
            <path
                fill={ this.props.primary }
                d="M119.2,108.4H81.7c-1.3,0-3.7-1.8-4-3.1L68.3,68c-2-7.9-9.9-12.6-17.8-10.6c-7.9,2-12.6,9.9-10.6,17.8l9.4,37.3
                c3.6,14.4,17.6,25.3,32.5,25.3h30.1v35c0,6.1,4.9,11,11,11c6.1,0,11-4.9,11-11v-49.7C133.8,115,127.3,108.4,119.2,108.4z"
            />
            <path
                fill={ this.props.primary }
                d="M96.6,145.1H78.5c-16.5,0-33.8-13.8-37.9-30.1L32,80.8c-0.7-2.9-3.7-4.7-6.7-4c-2.9,0.7-4.7,3.7-4,6.7l8.6,34.2
                c5.3,21.2,27.1,38.4,48.6,38.4h18.1c3,0,5.5-2.5,5.5-5.5C102.1,147.6,99.7,145.1,96.6,145.1z"
            />
        </svg>);
    }
}

export default WaitingRoomIcon;
