import React from 'react';
import BaseIcon from './BaseIcon';

class ShuttleIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="shuttle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M672.8,149.4c-3.8-19.6-11.3-34.6-22.5-44.9c-11.3-10.3-27.6-19.6-49.2-28.1c-21.6-9.4-52.5-17.8-92.8-25.3
                C468.9,43.7,432.7,40,400,39.8c-32.7,0.1-68.9,3.8-108.3,11.2c-40.3,7.6-71.2,15.9-92.8,25.3c-21.6,8.5-37.9,17.8-49.2,28.1
                c-11.3,10.3-18.7,25.3-22.5,44.9L99.1,374.3v293.4c0,11,9,20,20,20h41.7c0,0.1,0,0.3,0,0.4v41.3c0,17,13.8,30.8,30.8,30.8h15.9
                c17,0,30.8-13.8,30.8-30.8c0,0,0-41.5,0-41.6h323.5c0,0.1,0,41.6,0,41.6c0,17,13.8,30.8,30.8,30.8h15.9c17,0,30.8-13.8,30.8-30.8
                v-41.3c0-0.1,0-0.3,0-0.4h41.7c11,0,20-9,20-20V374.3L672.8,149.4z M246.9,122.4c0-12.6,10.3-22.9,22.9-22.9h260.4
                c12.6,0,22.9,10.3,22.9,22.9v0c0,12.6-10.3,22.9-22.9,22.9H269.8C257.2,145.3,246.9,135.1,246.9,122.4L246.9,122.4z M224.2,578.9
                c-9.4,8.9-20.4,13.4-33,13.4s-23.7-4.5-33-13.4c-9.4-8.9-14-19.6-14-32.3c0-12.6,4.6-23.5,14-32.3c9.4-8.9,20.4-13.4,33-13.4
                s23.7,4.5,33,13.4c9.4,8.9,14,19.6,14,32.3C238.3,559.2,233.6,569.9,224.2,578.9z M173.7,399.6c-7.6,0-13.6-2.4-18.3-7
                c-4.6-4.6-7-10.7-7-18.3c0-1.9,25.2-177,25.2-177c1.9-9.4,9.8-14,23.9-14h405.1c14,0,22,4.6,23.9,14c0,0,25.2,175.2,25.2,177
                c0,7.6-2.4,13.6-7,18.3c-4.6,4.6-10.7,7-18.3,7H173.7z M641.8,578.9c-9.3,8.9-20.4,13.4-33,13.4s-23.6-4.5-33-13.4
                c-9.4-9-14-19.7-14-32.3c0-12.7,4.6-23.5,14-32.3c9.3-8.9,20.4-13.4,33-13.4s23.6,4.5,33,13.4c9.4,8.9,14,19.7,14,32.3
                C655.8,559.3,651.2,570,641.8,578.9z"
            />
        </svg>);
    }
}

export default ShuttleIcon;
