import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";
import resources from './../localisation/localisations';

i18n
    .use(detector)
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        fallbackLng: "en",
        ns: ['common', 'forms', 'confirmation', 'comparison'],
        defaultNS: 'common',
        detection: {
            // order and from where user language should be detected
            order: ['navigator'],
        },
        resources,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            defaultTransParent: 'span' // a valid react element - required before react 16
        }
    });

export default i18n;
