import React from 'react';
import PropTypes from 'prop-types';

// Import mapped SVG icons
import AsphaltedIcon from './icons/AsphaltedIcon';
import BreakfastIcon from './icons/BreakfastIcon';
import CallIcon from './icons/CallIcon';
import CarIcon from './icons/CarIcon';
import CarIndoorIcon from './icons/CarIndoorIcon';
import CarOutdoorIcon from './icons/CarOutdoorIcon';
import Clock6Icon from './icons/Clock6Icon';
import Clock7Icon from './icons/Clock7Icon';
import Clock8Icon from './icons/Clock8Icon';
import DoubleRoomIcon from './icons/DoubleRoomIcon';
import EnclosedIcon from './icons/EnclosedIcon';
import EntryCodeIcon from './icons/EntryCodeIcon';
import KeyGiveIcon from './icons/KeyGiveIcon';
import KeyKeepIcon from './icons/KeyKeepIcon';
import KeyKeepPaidIcon from './icons/KeyKeepPaidIcon';
import LicensePlateIcon from './icons/LicensePlateIcon';
import LuggageIcon from './icons/LuggageIcon';
import MeetGreetIcon from './icons/MeetGreetIcon';
import MinivanIcon from './icons/MinivanIcon';
import NoShuttleIcon from './icons/NoShuttleIcon';
import Open247Icon from './icons/Open247Icon';
import Open4Icon from './icons/Open4Icon';
import Open5Icon from './icons/Open5Icon';
import ParkingTicketIcon from './icons/ParkingTicketIcon';
import PluginChargingIcon from './icons/PluginChargingIcon';
import SecurityCamIcon from './icons/SecurityCamIcon';
import SecurityIcon from './icons/SecurityIcon';
import SedanIcon from './icons/SedanIcon';
import ShuttleIcon from './icons/ShuttleIcon';
import SmsIcon from './icons/SmsIcon';
import TaxiElectricalIcon from './icons/TaxiElectricalIcon';
import TaxiIcon from './icons/TaxiIcon';
import TeslaChargingIcon from './icons/TeslaChargingIcon';
import TripleRoomIcon from './icons/TripleRoomIcon';
import UnpavedIcon from './icons/UnpavedIcon';
import WaitingRoomIcon from './icons/WaitingRoomIcon';
import WheelchairIcon from './icons/WheelchairIcon';
import LocationPinIcon from './icons/LocationPinIcon';


// Map icons to property ID's
const iconMapping = {
    4: KeyKeepIcon,
    5: KeyGiveIcon,
    6: AsphaltedIcon,
    7: EnclosedIcon,
    8: Open247Icon,
    9: CarIndoorIcon,
    10: CarOutdoorIcon,
    11: SecurityIcon,
    12: SecurityCamIcon,
    13: LicensePlateIcon,
    14: EntryCodeIcon,
    15: WaitingRoomIcon,
    16: ShuttleIcon,
    17: NoShuttleIcon,
    18: ShuttleIcon,
    19: TeslaChargingIcon,
    20: PluginChargingIcon,
    21: ShuttleIcon,
    22: CarIcon,
    23: MinivanIcon,
    24: SedanIcon,
    25: TaxiElectricalIcon,
    26: SmsIcon,
    27: CallIcon,
    28: CallIcon,
    29: MeetGreetIcon,
    31: UnpavedIcon,
    32: Open4Icon,
    33: Open5Icon,
    34: DoubleRoomIcon,
    35: TripleRoomIcon,
    36: BreakfastIcon,
    37: KeyKeepPaidIcon,
    45: WheelchairIcon,
    47: Clock7Icon,
    48: LuggageIcon,
    49: LuggageIcon,
    50: LuggageIcon,
    51: LuggageIcon,
    52: LuggageIcon,
    53: ParkingTicketIcon,
    54: LocationPinIcon,
    55: LocationPinIcon,
    56: Clock8Icon,
    57: ShuttleIcon,
    59: CallIcon,
    61: Clock6Icon,
    63: TaxiIcon,
    64: LocationPinIcon,
}

export const hasIcon = (id) => {
    return !!iconMapping[id];
};

const PropertyIcon = ({ id }) => {
    if (!id) {
        return null;
    }

    const IconComponent = iconMapping[id];

    return <IconComponent primary="#EF3D5C" />;
};

PropertyIcon.propTypes = {
    id: PropTypes.number,
}

export default PropertyIcon;
