const ERROR_NAME = 'ClientError';

class ClientError extends Error {
    constructor(message, status, response) {
        super(message);
        this.name = ERROR_NAME;
        this.status = status;
        this.response = response;
    }
}

ClientError.ERROR_NAME = ERROR_NAME;

export default ClientError;
