import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCardContainer = styled.div`
    ${ props => props.titleSpaceOff ? '' : 'padding-top: 30px' }
`;

const CardContainer = ({ children, titleSpaceOff }) => {
    return (
        <StyledCardContainer titleSpaceOff={titleSpaceOff} >
            { children }
        </StyledCardContainer>
    );
}

CardContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    titleSpaceOff: PropTypes.bool,
}

export default CardContainer;
