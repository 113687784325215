import React from 'react';
import PropTypes from 'prop-types';
import DirectionsMap from '../../components/map/DirectionsMap';
import Map from '../../components/map/Map';

const BookingInformationMap = ({ label, originAddress, destinationAddress, typeOfBooking, selectedProduct, size }) => {
    const { location_latitude, location_longitude, location_address  } = selectedProduct;

    if (!originAddress && !location_latitude) {
        return null;
    }

    if (typeOfBooking === "parking") {
        if (!location_latitude || !location_longitude) {
            return null;
        }

        return(
            <Map
                size={ size }
                lat={ location_latitude }
                lng={ location_longitude }
                label={ label || location_address }
            />
        )
    }

    if (originAddress && destinationAddress) {
        return(
            <DirectionsMap
                size={ size }
                origin={ originAddress }
                destination={ destinationAddress }
                label={ label }
            />
        )
    }

    return null;
}

BookingInformationMap.defaultProps = {
    size: "350x175"
}

BookingInformationMap.propTypes = {
    destinationAddress: PropTypes.string,
    label: PropTypes.string,
    originAddress: PropTypes.string,
    selectedProduct: PropTypes.object.isRequired,
    size: PropTypes.string,
    typeOfBooking: PropTypes.string.isRequired,
}

export default BookingInformationMap
