import React from 'react';
import BaseIcon from './BaseIcon';

class CityIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="city" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 491 491">
            <path
                fill={ this.props.primary }
                d="M320.3,184.9v245.5h121.2V20.2c0-7.8-6.3-14.1-14.1-14.1H245.5c-7.8,0-14.1,6.3-14.1,14.1v122.3h46.5
                C301.2,142.4,320.3,161.5,320.3,184.9z M352.6,80.8c0-7.8,6.3-14.1,14.1-14.1c7.8,0,14.1,6.3,14.1,14.1V96
                c0,7.8-6.3,14.1-14.1,14.1c-7.8,0-14.1-6.3-14.1-14.1V80.8z M352.6,156.6c0-7.8,6.3-14.1,14.1-14.1c7.8,0,14.1,6.3,14.1,14.1v15.2
                c0,7.8-6.3,14.1-14.1,14.1c-7.8,0-14.1-6.3-14.1-14.1V156.6z M352.6,232.4c0-7.8,6.3-14.1,14.1-14.1c7.8,0,14.1,6.3,14.1,14.1v15.2
                c0,7.8-6.3,14.1-14.1,14.1c-7.8,0-14.1-6.3-14.1-14.1V232.4z M352.6,308.1c0-7.8,6.3-14.1,14.1-14.1c7.8,0,14.1,6.3,14.1,14.1v15.2
                c0,7.8-6.3,14.1-14.1,14.1c-7.8,0-14.1-6.3-14.1-14.1V308.1z M292,80.8c0-7.8,6.3-14.1,14.1-14.1c7.8,0,14.1,6.3,14.1,14.1V96
                c0,7.8-6.3,14.1-14.1,14.1c-7.8,0-14.1-6.3-14.1-14.1V80.8z"/>
            <path
                fill={ this.props.primary }
                d="M48.5,138.4h106.1c24.5,0,44.5-19.9,44.5-44.5c0-24.8-20.1-44.5-44.5-44.5h-1.4C149.9,23.9,128,4,101.5,4
                S53.1,23.9,49.9,49.5h-1.4C23.6,49.5,4,69.6,4,93.9C4,118.5,24,138.4,48.5,138.4z"/>
            <path
                fill={ this.props.primary }
                d="M472.8,458.7H18.2C10.3,458.7,4,465,4,472.8S10.3,487,18.2,487h454.7c7.8,0,14.1-6.3,14.1-14.1S480.7,458.7,472.8,458.7z"
                />
            <path
                fill={ this.props.primary }
                d="M292,430.4V184.9c0-7.8-6.3-14.1-14.1-14.1H63.6c-7.8,0-14.1,6.3-14.1,14.1v245.5h76.8v-47.5c0-7.8,6.3-14.1,14.1-14.1H201
                c7.8,0,14.1,6.3,14.1,14.1v47.5H292z M124.3,322.3c0,7.8-6.3,14.1-14.1,14.1c-7.8,0-14.1-6.3-14.1-14.1v-15.2
                c0-7.8,6.3-14.1,14.1-14.1c7.8,0,14.1,6.3,14.1,14.1V322.3z M124.3,246.5c0,7.8-6.3,14.1-14.1,14.1c-7.8,0-14.1-6.3-14.1-14.1
                v-15.2c0-7.8,6.3-14.1,14.1-14.1c7.8,0,14.1,6.3,14.1,14.1V246.5z M184.9,322.3c0,7.8-6.3,14.1-14.1,14.1
                c-7.8,0-14.1-6.3-14.1-14.1v-15.2c0-7.8,6.3-14.1,14.1-14.1c7.8,0,14.1,6.3,14.1,14.1V322.3z M184.9,246.5
                c0,7.8-6.3,14.1-14.1,14.1c-7.8,0-14.1-6.3-14.1-14.1v-15.2c0-7.8,6.3-14.1,14.1-14.1c7.8,0,14.1,6.3,14.1,14.1V246.5z
                M217.2,231.4c0-7.8,6.3-14.1,14.1-14.1c7.8,0,14.1,6.3,14.1,14.1v15.2c0,7.8-6.3,14.1-14.1,14.1c-7.8,0-14.1-6.3-14.1-14.1V231.4z
                M217.2,307.1c0-7.8,6.3-14.1,14.1-14.1c7.8,0,14.1,6.3,14.1,14.1v15.2c0,7.8-6.3,14.1-14.1,14.1c-7.8,0-14.1-6.3-14.1-14.1V307.1z
                "/>
            <path
                fill={ this.props.primary }
                d="M154.6,397.1h32.3v33.3h-32.3V397.1z"/>
        </svg>);
    }
}

export default CityIcon;
