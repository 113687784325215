import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledGridRow = styled.div`
    display: flex;
    flex: ${ props => props.size };
    flex-direction: ${ props => props.flexDirection };
    justify-content: ${ props => props.justifyContent };

    & ~ * {
        margin-left: 20px;
    }
`;

const GridRow = ({ children, flexDirection, justifyContent, size }) => {
    return (
        <StyledGridRow
            flexDirection={ flexDirection }
            justifyContent={ justifyContent }
            size={ size }
        >
            { children }
        </StyledGridRow>
    );
}

GridRow.defaultProps = {
    flexDirection: 'initial',
    justifyContent: 'initial',
    size: 1,
}

GridRow.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    flexDirection: PropTypes.string,
    justifyContent: PropTypes.string,
    size: PropTypes.number,
}

export default GridRow;
