import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardBox from '../../../components/cardBox/CardBox';
import FilterList from './FilterList';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CollapsibleListItem from '../../../components/listItem/CollapsibleListItem';
import { t } from 'i18next';

class Properties extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            filtersLoading: props.loading,
        };
    }

    componentDidMount(){
        this.createFilters();
    }

    componentDidUpdate(prevProps){
        // If Filter gets new results we are ditching the old activeFilters/subCats.
        if (this.props.originalResults !== prevProps.originalResults) {
            this.props.clearFilters();
        }

        // And we'll create the filters.
        if (this.props.results !== prevProps.results) {
            this.createFilters();
        }
    }

    createFilters(){
        const { results } = this.props;

        if (results.length) {
            let propertyGroupIds = [];
            let filters = [];

            results.forEach(result => {
                if (!result.properties) {
                    return;
                }
                // Create an array of unique properties.
                filters = _.unionBy(filters, result.properties, "id");
            });

            if (filters.length) {
                // Change the property array into an object with unique property_group_id as key.
                filters = _.groupBy(filters, "property_group_id");

                // Create array with solely property groups
                propertyGroupIds = Object.keys(filters);

                return this.fetchPropertyGroups(filters, propertyGroupIds);
            }
        }

        this.setState({
            filters: [],
            filtersLoading: false,
        })
    }

    fetchPropertyGroups(filters, propertyGroupIds){
        return this.props.fetchPropertyGroups(propertyGroupIds).then(
            (propertyGroups) => {
                if (propertyGroups) {
                    this.relabelFilters(filters, propertyGroups.results)
                }
            }
        );
    }

    relabelFilters(filters, groups){
        const newFilters = {};

        groups.forEach(
            (value) => {
                const oldGroup = filters[value.id];
                newFilters[value.name] = oldGroup;
            }
        );

        this.setState({
            filters: newFilters,
            filtersLoading: false,
        })
    }

    render(){
        const { onFilterCheck, activeFilters } = this.props;
        const { filters } = this.state;

        const filterGroups = Object.keys(filters).map((filterGroup, key) => {
            const initiallyOpen = filters[filterGroup].some(filter => {
                return activeFilters.some(activeFilter => {
                    // This should be == because the filter value set by MUI is a string and the actual filter id is a number.
                    return activeFilter == filter.id
                })
            })

            return(
                <div key={ `filterGroup_${key}` }>
                    <CollapsibleListItem
                        label={ filterGroup }
                        initiallyOpen={ initiallyOpen }
                    >
                        <FilterList
                            filters={ filters[filterGroup] }
                            onCheck={ onFilterCheck }
                            checked={ activeFilters }
                        />
                    </CollapsibleListItem>
                </div>
            )
        })

        if (Object.keys(filters).length !== 0) {
            return (
                <CardBox
                    title={ t('comparison:properties') }
                    paddingOff
                >
                    { this.state.filtersLoading ? <CircularProgress /> : filterGroups }
                </CardBox>
            )
        }

        return null;
    }
}

Properties.propTypes = {
    activeFilters: PropTypes.array.isRequired,
    clearFilters: PropTypes.func.isRequired,
    fetchPropertyGroups: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    onFilterCheck: PropTypes.func.isRequired,
    originalResults: PropTypes.array.isRequired,
    results: PropTypes.array.isRequired,
}

export default Properties;
