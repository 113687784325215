import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TabButton from './TabButton';

const StyledTabButtons = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    li:first-child{
        border-left: 0;
    }
    li:last-child{
        border-right: 0;
    }
`;

const labels = (labels) => labels.map((label, index) => {
    return <TabButton key={ index } { ...label } />
})

const TabButtons = (props) => (
    <StyledTabButtons>
        { labels(props.labels) }
    </StyledTabButtons>
)

TabButtons.propTypes = {
    labels: PropTypes.array.isRequired,
}

export default TabButtons;
