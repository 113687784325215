import React from 'react';
import PropTypes from 'prop-types';
import TabButtons from './TabButtons';

class TabContainer extends React.Component {
    state = {
        //Default open tab
        selectedTab: 0,
    }

    handleTabSelect = (index) => {
        this.setState({
            selectedTab: index,
        })
    }

    render(){
        const labels = [];

        const tabs = this.props.children.map(
            (child, index)  => {
                const selected = index === this.state.selectedTab;

                labels.push({
                    index: index,
                    name: child.props.label,
                    onTabSelect: this.handleTabSelect,
                    selected: selected,
                })

                return React.cloneElement(child, {
                    key: index + child.props.label,
                    selected: selected,
                });
            }
        )

        return (
            <div>
                <TabButtons labels={ labels } />

                { tabs }
            </div>
        )
    }
}

TabContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
}

export default TabContainer;
