import React from 'react';
import BaseIcon from './BaseIcon';

class CarIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="car" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M724,311.5c-3.2-3.9-8-6.2-13-6.2h-63.8c-15.8-41.5-36.3-82.2-59.6-97.5c-80.6-53.1-294.5-53.1-375.2,0
                c-23.3,15.4-43.8,56.1-59.6,97.6H89c-5.1,0-9.8,2.3-13,6.2c-3.2,3.9-4.5,9-3.4,14l9.4,45.8c1.6,7.8,8.5,13.4,16.4,13.4h18.9
                c-18.2,21-27.1,47.5-27.2,74.1c-0.1,32.8,12.3,62.4,35.1,83.3c0.3,0.2,0.5,0.4,0.7,0.6v64.1c0,13.9,11.3,25.2,25.2,25.2h58.8
                c13.9,0,25.2-11.3,25.2-25.2v-25.7h329.6v25.7c0,13.9,11.3,25.2,25.2,25.2h58.8c13.9,0,25.2-11.3,25.2-25.2v-62.8
                c24.1-22.1,35.7-52.5,35.8-82.9c0.1-27.4-9.1-54.9-28.5-76.4h20.2c8,0,14.9-5.6,16.4-13.4l9.5-45.8
                C728.4,320.6,727.2,315.5,724,311.5z M240,249.8c64.3-42.3,255.6-42.3,319.8,0c12.5,8.2,28.5,41.3,43.3,81.9H196.8
                C211.5,291.1,227.6,258,240,249.8z M164.8,465.1c0-27.4,22.2-49.5,49.5-49.5c27.4,0,49.5,22.2,49.5,49.5s-22.2,49.5-49.5,49.5
                C186.9,514.6,164.8,492.4,164.8,465.1z M586.3,514.6c-27.3,0-49.5-22.2-49.5-49.5s22.2-49.5,49.5-49.5c27.4,0,49.5,22.2,49.5,49.5
                S613.7,514.6,586.3,514.6z"
            />
        </svg>);
    }
}

export default CarIcon;
