import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TableCol from './TableCol';

const StyledTableRow = styled.tr`
    width: 100%;
`;

const TableRow = ({ label, value }) => {
    return (
        <StyledTableRow>
            <TableCol value={ label } primary />
            <TableCol value={ value } />
        </StyledTableRow>
    );
}

TableRow.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    label: PropTypes.string,
    value: PropTypes.string,
}

export default TableRow;