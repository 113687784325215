import apiClient from './apiClient';
import mobianApiClient from './mobianApiClient';

export default (function () {
    return {

        /**
         * Tries to identify a user of the booking tool.
         *
         * @returns Promise
         */
        identify: () => {
            return mobianApiClient.fetchProfile().then(null, function (error) {

                // Handle the failed profile fetch
                const username = process.env.USERNAME;
                const password = process.env.PASSWORD;

                if (!username || !password) {
                    throw error;
                }

                // Try to log in with local credentials
                return apiClient.create(`${process.env.API_URL}/auth/login`, {
                    username: username,
                    password: password,
                }).then(mobianApiClient.fetchProfile, function () {
                    throw error;
                });
            }).then(mobianApiClient.fetchCurrentAccount).catch(function (error) {
                throw error;
            });
        }
    };
})();
