import React from 'react';
import PropTypes from 'prop-types';

/**
 * @todo The defaultProps need to defined by the GlobalConsumer.
 */
const Price = ({ amount, currency, locale }) => {
    return <span>{ amount.toLocaleString(locale, { style: 'currency', currency: currency }) }</span>
}

Price.defaultProps = {
    currency: 'EUR',
    locale: 'nl-NL',
}

Price.propTypes = {
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string,
    locale: PropTypes.string,
}

export default Price;
