import React from 'react';
import BaseIcon from './BaseIcon';

class TrainIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="train" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 491 491">
            <path
                fill={ this.props.primary }
                d="M348.5,377l-1-2c0-0.7,0.2-1,0.5-1s0.5-0.3,0.5-1c10.7-4,20.3-9.3,29-16s14.7-16.3,18-29l0.5-3.5c0.3-2.3,0.8-4.5,1.5-6.5
                V57c0-6.7-1.8-13.3-5.5-20c-3.7-6.7-8.3-12.7-14-18c-5.7-5.3-12-9.8-19-13.5S345.5,0,339.5,0h-187c-6,0-12.5,1.7-19.5,5
                s-13.3,7.7-19,13s-10.3,11.3-14,18c-3.7,6.7-5.5,13-5.5,19v265c0,4.7,1,9.3,3,14s4.7,9.2,8,13.5s7,8.3,11,12s8,6.5,12,8.5
                c2,0.7,5.2,1.8,9.5,3.5c4.3,1.7,6.5,2.8,6.5,3.5l-77,116h45l56-81h154l56,81h45L348.5,377z M201.5,23c0-2,0.8-4,2.5-6s3.5-3,5.5-3
                h72c0.7,0,2,0.7,4,2s3,2.7,3,4v28c0,2-0.8,3.7-2.5,5s-3.2,2-4.5,2h-72c-1.3-0.7-2.3-1.3-3-2c-1.3-0.7-2.3-1.7-3-3
                c-1.3-1.3-2-2.7-2-4V23z M129.5,99c0-3.3,0.7-7,2-11c2-3.3,4.3-6.7,7-10c2-2.7,4.8-5,8.5-7s7.5-3,11.5-3h174c2.7,0,6,1,10,3
                c2.7,1.3,5.7,3.3,9,6c2.7,2,4.7,4.7,6,8c2,2.7,3,6,3,10v59c0,3.3-1,6.5-3,9.5s-4.3,5.8-7,8.5c-3.3,2.7-6.7,4.7-10,6
                c-2.7,1.3-6,2-10,2h-171c-0.7,0-1.7-0.2-3-0.5s-2.3-0.8-3-1.5c-6.7-0.7-12.3-3.8-17-9.5s-7-11.8-7-18.5V99z M181,330
                c-5.7,6-12.8,9-21.5,9s-15.7-3-21-9s-8-13.3-8-22c0-8,2.8-14.8,8.5-20.5s12.5-8.5,20.5-8.5c8.7,0,15.8,2.7,21.5,8
                c5.7,5.3,8.5,12.3,8.5,21S186.7,324,181,330z M309,330c-5.7-6-8.5-13.3-8.5-22s3-15.7,9-21s13-8,21-8c8.7,0,15.7,2.8,21,8.5
                s8,12.5,8,20.5c0,8.7-2.7,16-8,22s-12.3,9-21,9C321.8,339,314.7,336,309,330z"
            />
        </svg>);
    }
}

export default TrainIcon;
