import React from 'react';
import BaseIcon from './BaseIcon';

class BreakfastIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="breakfast" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M659,327.6c-8.1,0-15.8,1.6-22.8,4.5v-51.7H345.5v71.9c22.3,5.5,39.5,16.3,51.4,32.2c7.1,9.5,11.3,19.6,13.6,29.2 c4.6-2.7,9.8-4.3,15.4-4.3c4.1,0,8.1,0.8,11.9,2.5c45,19.5,70.2,44.6,74.9,74.7c2.2,14-0.6,27.1-5.7,38.3l1.6,2.6h63.8 c35.2,0,63.8-28.6,63.8-63.8V445c7.1,2.9,14.8,4.5,22.8,4.5c33.6,0,60.9-27.3,60.9-60.9C720,354.9,692.6,327.6,659,327.6z M659,429.5c-8.4,0-16.3-2.6-22.8-7v-67.9c6.5-4.4,14.4-7,22.8-7c22.6,0,40.9,18.4,40.9,40.9S681.6,429.5,659,429.5z"
            />
            <rect
                fill={ this.props.primary }
                x="541" y="559.9" width="106" height="23"
            />
            <path
                fill={ this.props.primary }
                d="M432.7,237.1c-3.8,4-3.5,10.4,0.5,14.1c1.9,1.8,4.4,2.7,6.8,2.7c2.7,0,5.3-1.1,7.3-3.2c21.3-22.9,10-40.7,2.4-52.5 c-8-12.6-13.4-20.9,3.6-39.5c3.7-4.1,3.4-10.4-0.6-14.1c-4.1-3.7-10.4-3.4-14.1,0.6c-27.3,29.9-14.3,50.3-5.7,63.8 C440.4,220.6,443.5,225.5,432.7,237.1z"
            />
            <path
                fill={ this.props.primary }
                d="M517.2,237.1c-3.8,4-3.5,10.4,0.5,14.1c1.9,1.8,4.4,2.7,6.8,2.7c2.7,0,5.3-1.1,7.3-3.2c21.3-22.9,10-40.7,2.4-52.5 c-8-12.6-13.4-20.9,3.6-39.5c3.7-4.1,3.4-10.4-0.6-14.1c-4.1-3.7-10.4-3.4-14.1,0.6c-27.3,29.9-14.3,50.3-5.7,63.8 C524.9,220.6,528,225.5,517.2,237.1z"
            />
            <path
                fill={ this.props.primary }
                d="M117.5,548.4c-1.6-1.5-3.1-3.2-4.6-4.9c-14,22.5-22.7,41-26.5,56.3c-4.1,16.6-2.2,29.6,5.5,38.8c7.3,8.7,19.3,13,35.8,13 c9.5,0,20.4-1.4,32.8-4.3c23.2-5.4,43.4-13.9,44.3-14.2c0.4-0.2,0.7-0.3,1-0.5c-11.1-8.1-24.3-19.3-40.2-34.5 C142.2,575.8,122.5,553.9,117.5,548.4z"
            />
            <path
                fill={ this.props.primary }
                d="M198.2,461.2c-0.5-1.7-2.1-7.8-2.9-16.5l-4.1-9.2c-2.2-5.1-8.1-7.4-13.1-5.2c-38.3,16.6-59.5,36.6-63.1,59.4 c-3.8,24,13.5,41.5,16.9,44.7c3,3.3,23.5,26.2,47.6,49.1c46.1,44,65.4,50.3,75.7,50.3c0.6,0,1.1,0,1.6-0.1c1.8-0.1,3.6-0.5,5.2-1 c-10-15.9-21.5-42.1-36.9-86.6C210.4,503.8,198.6,462.8,198.2,461.2z"
            />
            <path
                fill={ this.props.primary }
                d="M495,543.5c-1.5,1.8-3.1,3.4-4.6,4.9c-5,5.6-24.8,27.4-48.2,49.7c-16,15.2-29.2,26.4-40.2,34.5c0.3,0.2,0.7,0.4,1,0.5 c0.8,0.4,21,8.8,44.3,14.2c12.4,2.9,23.4,4.3,32.8,4.3c16.5,0,28.5-4.4,35.8-13c7.7-9.2,9.6-22.2,5.5-38.8 C517.7,584.5,509,566,495,543.5z"
            />
            <path
                fill={ this.props.primary }
                d="M390.5,455.6c0.4-1.3,9.5-33.6-9.6-59.2c-14.2-19-39.4-28.6-75-28.6H302c-35.6,0-60.8,9.6-75,28.6 c-19.2,25.6-10,57.9-9.6,59.2c0.1,0.4,12,41.8,26.7,84c27.2,78.3,40.2,93.4,49.5,97.9c2.2,1.1,4.6,1.6,6.9,1.6 c1.2,0,2.4-0.2,3.5-0.5c1.2,0.3,2.3,0.5,3.5,0.5c2.3,0,4.7-0.5,6.9-1.6c9.2-4.5,22.3-19.6,49.5-97.9 C378.5,497.4,390.4,456.1,390.5,455.6z"
            />
            <path
                fill={ this.props.primary }
                d="M476,534.4c3.4-3.2,20.7-20.7,16.9-44.7c-3.6-22.9-24.8-42.9-63.1-59.4c-5.1-2.2-11,0.1-13.1,5.2l-4.1,9.2 c-0.8,8.6-2.4,14.8-2.9,16.5c-0.5,1.6-12.3,42.6-27,85c-15.4,44.5-26.9,70.7-36.9,86.6c1.6,0.5,3.4,0.9,5.2,1c0.5,0,1,0.1,1.6,0.1 c10.3,0,29.6-6.3,75.7-50.3C452.5,560.6,473,537.8,476,534.4z"
            />
        </svg>);
    }
}

export default BreakfastIcon;
