import React from 'react';
import BaseIcon from './BaseIcon';

class SecurityIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="security" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M619.1,197.8c-82.9-3.2-149.1-27.9-205.9-77.8c-7.6-6.7-18.9-6.7-26.5,0c-56.8,49.9-122.9,74.5-205.8,77.8
                c-10.6,0.4-19.1,9.1-19.3,19.7c-2,159.1-22.4,374.4,231.5,466.3c4.4,1.6,9.3,1.6,13.7,0c253.9-91.9,233.6-307.2,231.6-466.3
                C638.3,206.8,629.8,198.2,619.1,197.8z M348.6,537.7L226.7,415.8c-7.8-7.8-7.8-20.5,0-28.3l38.9-38.9c7.8-7.8,20.5-7.8,28.3,0
                l54.6,54.6c7.8,7.8,20.5,7.8,28.3,0L506,273.9c7.8-7.8,20.5-7.8,28.3,0l38.9,38.9c7.8,7.8,7.8,20.5,0,28.3L376.9,537.7
                C369.1,545.5,356.4,545.5,348.6,537.7z"
            />
        </svg>);
    }
}

export default SecurityIcon;
