import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../components/Checkbox';
import styled from 'styled-components';
import { t } from 'i18next';

const StyledItalicText = styled.i`
    padding-left: 16px;
    padding-right: 16px;
    color: #808080;
`

const StyledFilterList = styled.div`
    padding: 0 24px 5px;
`;

const showList = (filters, onCheck, checked) => {
    return filters.map((filter, key) => {
        const id = String(filter.id);

        return <Checkbox
            label={ filter.name }
            name={ id }
            onChange={ onCheck }
            key={ `filter_${key}` }
            checked={ checked.includes(id) }
        />
    });
};

const FilterList = ({ filters, onCheck, checked }) => {
    if (!filters.length) {
        return (
            <StyledItalicText>
                { t('common:notAvailable') }.
            </StyledItalicText>
        )
    }

    return(
        <StyledFilterList>
            { showList(filters, onCheck, checked) }
        </StyledFilterList>
    )
}

FilterList.defaultProps = {
    filters: []
}

FilterList.propTypes = {
    filters: PropTypes.array.isRequired,
    onCheck: PropTypes.func.isRequired,
    checked: PropTypes.array.isRequired,
}

export default FilterList;
