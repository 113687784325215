import React from 'react';
import PropTypes from 'prop-types';
import createReactContext from 'create-react-context';

// This initializes a new Context API, with default values to prevent errors and abuse.
const GlobalContext = createReactContext({
    page: '',
    changePage: () => {},
});

// Define the consumer side to pass through all the data in the app.
export const GlobalConsumer = GlobalContext.Consumer;

// Define the provider side to host all the logic and update the value of the Context API.
export class GlobalProvider extends React.Component {
    state = {
        page: 'comparison',
        profile: null,
    }

    componentDidMount() {
        const profile = this.props.profile || null;

        this.setState({
            profile: profile,
        });
    }

    changePage = page => {
        this.setState({
            page: page,
        });
    }

    render() {
        return (
            <GlobalContext.Provider
                value={{
                    ...this.state,
                    changePage: this.changePage,
                }}
            >
                { this.props.children }
            </GlobalContext.Provider>
        )
    }
}

GlobalProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

export default GlobalProvider;
