import React from 'react';
import BaseIcon from './BaseIcon';

class ParkingTicketIcon extends BaseIcon {
    render() {
        return (<svg version='1.1' id='ParkingTicket' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 800'>
            <path
                fill={ this.props.primary }
                d='M702.2,400.1c-3.5,17.8-19.3,30.7-38.2,31.5c-5.9,0.2-11,4.6-12.1,10.5c-1.2,5.9,1.8,11.8,7.2,14.3
                c17.1,8,26.7,26.1,23.1,43.8l-3.4,16.9c-4.4,22.3-29,36.2-50.6,30.9c-6-1.4-12.2-2.6-18.3-3.8c-9-1.8-18-3.6-27-5.4
                c-11.9-2.4-23.8-4.7-35.7-7.2c-14.2-2.8-28.4-5.7-42.5-8.5c-15.8-3.2-31.7-6.4-47.5-9.6c-16.9-3.4-33.8-6.8-50.7-10.2
                c-17.4-3.5-34.7-7-52-10.5c-17.2-3.5-34.3-6.9-51.5-10.4c-16.4-3.3-32.8-6.6-49.2-9.9c-15-3-30-6-45-9c-13-2.6-26.1-5.2-39-7.8
                c-10.4-2.1-20.7-4.2-31.2-6.3c-7.2-1.4-14.3-2.9-21.5-4.3c-3.3-0.6-6.6-1.4-9.9-2c-0.2,0-0.3-0.1-0.5-0.1c1.8,8.4,1,17.3-2.9,25.6
                c-8.1,17.5-27,26.7-45.2,23.2c-5.4-1-11.3,1-13.6,5.9l-7.2,15.7c-2.3,5,0,10.8,4.3,14.2c0.8,0.6,580.3,267.8,581.3,268.1
                c5.4,1,11.3-1,13.6-5.9l7.2-15.7c2.3-5,0-10.8-4.3-14.2c-14.5-11.5-19.8-31.8-11.7-49.4c8.1-17.5,27-26.7,45.2-23.2
                c5.4,1,11.3-1,13.6-5.9l7.2-15.7c2.3-5,0-10.8-4.3-14.2c-14.5-11.5-19.8-31.8-11.7-49.4c8.1-17.5,27-26.7,45.2-23.2
                c5.4,1,11.3-1,13.6-5.9l7.2-15.7c2.3-5,0-10.8-4.3-14.2c-14.5-11.5-19.8-31.8-11.7-49.4c8.1-17.5,27-26.7,45.2-23.2
                c5.4,1,11.3-1,13.6-5.9l8-17.4c2.3-5,0.2-10.9-4.8-13.2l-86.6-40L702.2,400.1z'
            />

            <path
                fill={ this.props.primary }
                d='M11.7,394.6c1,0.5,626.6,126,627.6,126c5.5-0.2,10.8-3.6,11.8-8.9l3.4-16.9c1-5.4-2.5-10.5-7.5-12.9
                c-16.7-7.9-26.6-26.4-22.8-45.3c3.8-19,20.1-32.2,38.6-33c5.5-0.2,10.8-3.6,11.8-8.9l2.5-12.4l0.9-4.6c1-5.4-2.5-10.5-7.5-12.9
                c-16.7-7.9-26.6-26.4-22.8-45.3c3.8-19,20.1-32.2,38.6-33c5.5-0.2,10.8-3.6,11.8-8.9l3.4-16.9c1-5.4-2.5-10.5-7.5-12.9
                c-16.7-7.9-26.6-26.4-22.8-45.3c3.8-19,20.1-32.2,38.6-33c5.5-0.2,10.8-3.6,11.8-8.9l3.8-18.7c1-5.4-2.4-10.6-7.8-11.7
                c0,0-628.7-126.1-629.3-126.1c-4.7,0-8.8,3.3-9.7,8l-3.8,18.7c-1,5.4,2.5,10.5,7.5,12.9c16.7,7.9,26.6,26.4,22.8,45.3
                c-3.8,19-20.1,32.2-38.6,33c-5.5,0.2-10.8,3.6-11.8,8.9l-3.4,16.9c-1,5.4,2.5,10.5,7.5,12.9c16.7,7.9,26.6,26.4,22.8,45.3
                c-3.8,19-20.1,32.2-38.6,33c-5.5,0.2-10.8,3.6-11.8,8.9l-3.4,16.9c-1,5.4,2.5,10.5,7.5,12.9c16.7,7.9,26.6,26.4,22.8,45.3
                c-3.8,19-20.1,32.2-38.6,33c-5.5,0.2-10.8,3.6-11.8,8.9l-3.4,16.9C3.1,387.2,6.7,392.2,11.7,394.6z M547.5,188.5
                c2.2-10.9,12.8-17.9,23.6-15.8c10.9,2.2,17.9,12.8,15.8,23.6l-26.5,132l-16.3,81.4c-1.9,9.6-10.3,16.2-19.7,16.2
                c-1.3,0-2.7-0.2-3.9-0.4c-10.9-2.2-17.9-12.8-15.8-23.6l18.3-90.8L547.5,188.5z M185.1,115.7c2.2-10.9,12.8-17.9,23.6-15.8
                c10.9,2.2,17.9,12.8,15.8,23.6l-9.2,45.6l-10.4,51.7l-8.4,41.6L181.7,337c-1.9,9.6-10.3,16.2-19.7,16.2c-1.3,0-2.7-0.2-3.9-0.4
                c-0.6-0.1-1.1-0.2-1.6-0.4c-10-2.9-16.2-12.9-14.1-23.2l3-15l7.8-38.9L185.1,115.7z'
            />
        </svg>);
    }
}

export default ParkingTicketIcon;
