import PropTypes from 'prop-types';

/**
 * This component will hold a page title in the future,
 * so for now it is dumb
 */
const PageWrapper = (props) => {
    return props.children;
}

PageWrapper.propTypes = {
    children: PropTypes.object.isRequired,
}

export default PageWrapper;
