import utils from './utils';

export default (() => {
    const clientTokenKeyName = 'client-token';
    const generateToken = () => utils.tokenGenerator();

    var create = () => {
        try {
            return !!localStorage.setItem(clientTokenKeyName, generateToken());
        } catch(e) {
            throw new Error('localStorage out of memory error');
        }
    }, exists = () => {
        return !!localStorage.getItem(clientTokenKeyName);
    };

    return {
        exists: exists,
        create: create,
        read: () => {
            if (!exists()) {
                create();
            }

            return localStorage.getItem(clientTokenKeyName);
        },
        delete: () => {
            return localStorage.removeItem(clientTokenKeyName);
        },
    };
})();
