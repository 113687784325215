import React from 'react';
import BaseIcon from './BaseIcon';

class TaxiElectricalIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="taxi-electrical" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M718.2,86.9h-7.5V48.7c0-8.6-7.1-15.7-15.7-15.7s-15.7,7.1-15.7,15.7v38.2h-56.1V48.7c0-8.6-7.1-15.7-15.7-15.7
                c-8.6,0-15.7,7.1-15.7,15.7v38.2h-7.5c-8.6,0-15.7,7.1-15.7,15.7c0,8.6,7.1,15.7,15.7,15.7h4.7v47.4c0,21.9,1.1,25.9,3.3,29.3
                l22.9,37.1c3.3,5.4,8.6,9,14.7,10.1v33.4h9.5v55.9c0,11.8-5.7,22.3-14.6,28.9l-15.5-37.6c-9.8-24.1-39-43.7-65.1-43.7h-22.4v-66.6
                c0-11.7-9.5-21.2-21.2-21.2H387.6h-113c-11.8,0-21.2,9.6-21.2,21.2v66.7h-22.4c-26.1,0-55.3,19.5-65.1,43.7l-33,80.3l-30.8-8.5
                c-3-0.8-6-1.2-8.8-1.2c-15.9,0-27.4,12.2-27.4,28.9v17.1c0,19.5,15.9,35.4,35.4,35.4h2.3l-4.7,11.5c-9.2,22.4-16.7,60.2-16.7,84.5
                v160.4c0,19.5,15.9,35.4,35.4,35.4h42.6c19.5,0,35.4-15.9,35.4-35.4V692h191.9h191.8v39.5c0,19.5,15.9,35.4,35.4,35.4h42.6
                c19.5,0,35.4-15.9,35.4-35.4V571c0-24.3-7.4-62.1-16.7-84.5l-4.7-11.5h2.3c19.5,0,35.4-15.9,35.4-35.4v-17.1
                c0-16.8-11.5-28.9-27.4-28.9c-2.8,0-5.8,0.3-8.8,1.2l-30.8,8.5l-8.2-20.1c17.6-10.5,29.4-29.7,29.4-51.6v-55.9h9.5v-33.2
                c6.1-1.1,11.4-4.7,14.7-10.1l22.9-37.1c2.2-3.5,3.3-7.4,3.3-11.6v-65.3h4.7c8.6,0,15.7-7.1,15.7-15.7C734,94,726.9,86.9,718.2,86.9
                z M474,216.5h13.1v63.1H474V216.5z M401.9,216.5h13c0.8,0,1.5,0.1,1.9,0.3c0.4,0.3,0.8,0.6,1.1,1.1l13.5,22.3
                c0.2-0.3,0.3-0.7,0.5-1.1c0.2-0.3,0.3-0.8,0.6-1.1l12.2-19.9c0.3-0.6,0.8-1,1.2-1.3c0.4-0.3,0.9-0.4,1.5-0.4H460l-20.1,30
                l20.6,33.1h-13c-0.8,0-1.6-0.3-2.1-0.7c-0.5-0.4-0.9-0.9-1.3-1.5l-13.6-23.3c-0.2,0.3-0.3,0.6-0.4,0.9c-0.2,0.3-0.3,0.6-0.4,0.8
                l-12.9,21.5c-0.3,0.6-0.8,1-1.4,1.5c-0.5,0.4-1.2,0.7-1.9,0.7h-12.3l20.6-32.5L401.9,216.5z M357.1,216.5h13.4l24.6,63.1H385
                c-1.1,0-2-0.3-2.8-0.8c-0.7-0.5-1.3-1.2-1.6-2l-4.2-12h-25.1l-4.2,12c-0.3,0.8-0.8,1.4-1.5,1.9c-0.8,0.6-1.6,0.9-2.7,0.9h-10.2
                L357.1,216.5z M281.2,216.5h50V227h-18.4v52.6h-13.1V227h-18.4V216.5z M257,626.7h-95.8c-7.9,0-14.1-6.4-14.1-14.1v-45.4
                c0-7.9,6.4-14.1,14.1-14.1h95.7c7.9,0,14.1,6.4,14.1,14.1v45.4h0.1C271.1,620.4,264.7,626.7,257,626.7z M175.3,481.4
                c-15.7,0-23.5-11.8-17.6-26.2L207.9,333c5.8-14.5,23.5-26.2,39-26.2h140.7h140.7c15.6,0,33.2,11.8,39,26.2l50.2,122.3
                c5.8,14.5-1.9,26.2-17.6,26.2H387.6H175.3z M628.1,612.6c0,7.7-6.3,14.1-14.1,14.1h-95.7c-7.7,0-14.1-6.3-14.1-14.1v-45.4
                c0-7.7,6.3-14.1,14.1-14.1H614c7.7,0,14.1,6.3,14.1,14.1V612.6z M679.4,180.8h-56.4c-5.2,0-9.5-4.3-9.5-9.5c0-5.2,4.3-9.5,9.5-9.5
                h56.4c5.2,0,9.5,4.3,9.5,9.5C688.9,176.5,684.6,180.8,679.4,180.8z M679.4,146.7h-56.4c-5.2,0-9.5-4.3-9.5-9.5
                c0-5.2,4.3-9.5,9.5-9.5h56.4c5.2,0,9.5,4.3,9.5,9.5C688.9,142.5,684.6,146.7,679.4,146.7z"
            />
            <path
                fill={ this.props.primary }
                d="M366.4,236.4c-0.4-1-0.8-2.2-1.3-3.6c-0.4-1.4-0.8-2.9-1.3-4.4c-0.4,1.6-0.8,3.1-1.3,4.5c-0.4,1.4-0.8,2.5-1.3,3.6
		        l-6.8,19.2h18.8L366.4,236.4z"
            />
        </svg>);
    }
}

export default TaxiElectricalIcon;
