import React from 'react';
import BaseIcon from './BaseIcon';

class MobianIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="mobian-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                fill={ this.props.primary }
                d="M429.8,108.9c-0.2-0.4-0.3-0.7-0.6-1c-1.2-2.2-2.4-4.5-3.6-6.7l0,0c-0.2-0.3-0.3-0.6-0.5-0.8l0,0c-0.6-1.1-1.3-2.2-2-3.3
                c-0.1-0.2-0.2-0.3-0.3-0.5c-0.8-1.2-1.5-2.4-2.3-3.6c-0.2-0.2-0.3-0.5-0.5-0.7c-0.6-0.9-1.3-2-2-2.9c-0.2-0.2-0.4-0.6-0.6-0.8
                c-0.8-1.2-1.6-2.3-2.4-3.5c-0.2-0.2-0.3-0.4-0.4-0.6c-0.7-0.9-1.4-1.9-2-2.8c-0.2-0.3-0.5-0.6-0.7-0.9c-0.8-1.1-1.7-2.1-2.4-3.2
                c-0.1-0.1-0.2-0.2-0.2-0.2c-0.8-1-1.7-2-2.4-3.1c-0.2-0.3-0.6-0.6-0.8-1c-0.7-0.9-1.4-1.7-2.1-2.5c-0.2-0.2-0.4-0.5-0.6-0.7
                c-0.9-1-1.7-2-2.7-3c-0.3-0.3-0.6-0.6-0.9-0.9c-0.7-0.7-1.3-1.4-2-2.2c-0.3-0.3-0.6-0.6-0.9-0.9c-0.9-0.9-1.8-1.9-2.8-2.8
                c-0.3-0.3-0.6-0.6-0.9-0.9c-0.7-0.7-1.4-1.3-2-2c-0.4-0.4-0.7-0.7-1.1-1c-0.9-0.8-1.7-1.6-2.5-2.4c-0.3-0.3-0.7-0.6-1-0.9
                c-0.8-0.7-1.6-1.4-2.3-2c-0.4-0.4-0.8-0.7-1.2-1c-0.7-0.6-1.4-1.2-2.1-1.8c-0.3-0.3-0.7-0.6-1-0.9c-0.9-0.8-2-1.6-3-2.4
                c-0.4-0.3-0.9-0.7-1.3-1c-0.7-0.6-1.4-1.1-2-1.6c-0.4-0.3-0.9-0.6-1.3-0.9c-1-0.8-2-1.5-3.1-2.2c-0.4-0.3-0.9-0.6-1.3-0.9
                c-0.7-0.5-1.4-1-2.1-1.5c-0.5-0.3-0.9-0.6-1.4-0.9c-0.9-0.6-1.9-1.3-2.8-1.9c-0.5-0.3-0.9-0.6-1.4-0.9c-0.8-0.5-1.6-1-2.4-1.5
                l-1.5-0.9c-0.8-0.5-1.6-0.9-2.4-1.4c-0.5-0.2-0.9-0.5-1.3-0.8c-1-0.6-2.1-1.2-3.1-1.8c-0.5-0.2-0.9-0.6-1.5-0.8
                c-0.8-0.4-1.6-0.8-2.4-1.3c-0.5-0.2-0.9-0.5-1.4-0.7c-1.1-0.6-2.3-1.2-3.4-1.7c-0.5-0.2-0.9-0.5-1.4-0.6c-0.8-0.4-1.7-0.8-2.4-1.2
                c-0.2-0.1-0.5-0.2-0.7-0.3c0.2,0.1,0.5,0.2,0.7,0.2c-0.5-0.2-1-0.4-1.5-0.6c0.2,0.1,0.5,0.2,0.7,0.3c-0.2-0.1-0.5-0.2-0.7-0.2
                c-1.2-0.6-2.4-1-3.5-1.6c-0.4-0.2-0.8-0.3-1.1-0.5c-0.9-0.4-1.8-0.8-2.8-1.1c-0.5-0.2-1-0.4-1.5-0.6c-0.9-0.4-2-0.8-3-1.1
                c-0.3-0.2-0.7-0.2-1-0.4c-1.2-0.5-2.4-0.9-3.7-1.3c-0.5-0.2-0.9-0.3-1.4-0.5c-0.9-0.3-1.9-0.6-2.8-0.9c-0.4-0.2-0.8-0.2-1.3-0.4
                c-1.3-0.4-2.6-0.8-3.9-1.2l0,0c-0.4-0.2-0.9-0.3-1.3-0.4l0,0c-1-0.2-2-0.6-3-0.8c-0.5-0.2-0.9-0.2-1.3-0.3c-1.3-0.3-2.7-0.7-4-0.9
                c-0.3-0.1-0.6-0.2-0.9-0.2c-1.1-0.2-2.2-0.5-3.3-0.7c-0.5-0.1-0.9-0.2-1.3-0.3c-1.3-0.2-2.5-0.5-3.8-0.7c-0.2,0-0.3-0.1-0.5-0.1
                c-1.4-0.2-2.8-0.5-4.2-0.7c-0.4-0.1-0.9-0.2-1.3-0.2c-1.2-0.2-2.3-0.3-3.5-0.5c-0.3-0.1-0.6-0.1-0.9-0.2c-1.4-0.2-2.8-0.3-4.2-0.5
                c-0.4,0-0.8-0.1-1.1-0.1c-1.2-0.1-2.4-0.2-3.5-0.3c-0.4,0-0.8-0.1-1.1-0.1c-1.5-0.1-2.9-0.2-4.4-0.3c-0.2,0-0.6,0-0.8,0
                c-1.3-0.1-2.5-0.1-3.8-0.2c-0.4,0-0.8,0-1.2,0c-1.3,0-2.8-0.1-4.1-0.1h-0.1c-0.1,0-0.2,0-0.3,0C148.3,2.8,60.7,90.4,60.7,198
                c0,32.1,7.8,62.3,21.5,89c31.5,61.3,165.8,213,173.7,221.8l0.1,0.1v0.4c0,0,0.1-0.1,0.2-0.2c0.1,0.1,0.1,0.2,0.1,0.2v-0.4
                c7.9-8.9,142.2-160.5,173.7-221.8c13.8-26.7,21.5-57,21.5-89C451.4,165.9,443.6,135.6,429.8,108.9z M359.9,205.7
                c-3,39.4-28,72.6-62.7,87.5c-12.6,5.4-26.4,8.4-40.9,8.4c-57.4,0-103.9-46.5-103.9-103.9c0-2.7,0.1-5.3,0.3-8
                c3.1-40.9,29.9-75.3,66.9-89.3c11.4-4.3,23.8-6.7,36.8-6.7c57.4,0,103.9,46.5,103.9,103.9C360.2,200.4,360.1,203.2,359.9,205.7z
                 M425.2,100.4c0.2,0.2,0.3,0.6,0.5,0.8C425.5,100.9,425.3,100.7,425.2,100.4z"
            />
        </svg>);
    }
}

export default MobianIcon;
