import React from 'react';
import PropTypes from 'prop-types';
import CardBox from '../../components/cardBox/CardBox';
import Loader from '../../components/Loader';
import ResultList from '../../components/results/ResultList';
import { ConfirmationConsumer } from '../../context/Confirmation';
import { t } from 'i18next';

function capitalise(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const ResultsContainer = ({ loading, results }) => {
    if (loading) {
        return(
            <CardBox
                title={ `${ t('common:searching') }...` }
                bgColor="transparent"
                bxShadowOff
                paddingOff
            >
                <Loader />
            </CardBox>
        )
    }

    return (
        <ConfirmationConsumer>
            { ({ searchDetails }) => {
                return(
                    <CardBox
                        title={ capitalise(t('comparison:searchResult', { count: results.length, typeOfBooking: searchDetails.typeOfBooking })) }
                        bgColor="transparent"
                        bxShadowOff
                        paddingOff
                    >
                        <ResultList
                            results={ results }
                        />
                    </CardBox>
                )
            }}
        </ConfirmationConsumer>

    );
};

ResultsContainer.propTypes = {
    activeFilters: PropTypes.array.isRequired,
    activeSubCategories: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    results: PropTypes.array.isRequired,
};

export default ResultsContainer;
