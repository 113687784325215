import React from 'react';
import BaseIcon from './BaseIcon';

class AsphaltedIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="asphalted" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M423.3,134.5c0.2,5.5,0.5,11,0.7,16.5c0.3,8.3-6.3,15.2-14.6,15.2h0c-8.3,0-14.9-6.9-14.6-15.2c0.2-5.5,0.5-11,0.7-16.5
                c0.3-8.3-6.3-15.2-14.6-15.2h-47.5c-6.6,0-12.4,4.5-14.1,10.9c-47,178.5-94,357-141,535.5c-2.4,9.3,4.5,18.3,14.1,18.3h167
                c7.8,0,14.3-6.2,14.6-14c0.3-6.3,0.5-12.6,0.8-18.9c0.3-7.8,6.8-14,14.6-14h40.1c7.8,0,14.3,6.2,14.6,14
                c0.3,6.3,0.5,12.6,0.8,18.9c0.3,7.8,6.8,14,14.6,14h167c9.6,0,16.6-9.1,14.1-18.3c-47-178.5-94-357-141-535.5
                c-1.7-6.4-7.5-10.9-14.1-10.9c-15.9,0-31.7,0-47.5,0C429.6,119.3,423,126.2,423.3,134.5z M405.3,236.4h7.9c7.8,0,14.3,6.2,14.6,14
                c1.3,33.7,2.7,67.4,4,101.1c0.3,8.3-6.3,15.2-14.6,15.2h-16.1c-8.3,0-14.9-6.9-14.6-15.2c1.4-33.7,2.8-67.4,4.1-101
                C391.1,242.6,397.5,236.4,405.3,236.4z M378.7,551.9c1.3-33.7,2.7-67.4,4-101.1c0.3-7.8,6.7-14,14.6-14h24c7.8,0,14.3,6.2,14.6,14
                c1.3,33.7,2.7,67.4,4,101.1c0.3,8.3-6.3,15.2-14.6,15.2h-32.1C385,567.1,378.4,560.2,378.7,551.9z"
            />
            <path
                fill={ this.props.primary }
                d="M536.9,119.3L536.9,119.3c-8.4,0-14.3,8.2-11.6,16.1c60.1,180,120.2,360.2,180.2,540.2c1.7,5,6.3,8.4,11.6,8.4h41
                c8.7,0,14.6-8.8,11.3-16.9C695.8,487.1,622,307,548.2,126.9C546.3,122.3,541.9,119.3,536.9,119.3z"
            />
            <path
                fill={ this.props.primary }
                d="M270.5,126.9C196.7,307,123,487.1,49.2,667.2c-3.3,8.1,2.6,16.9,11.3,16.9h41c5.3,0,9.9-3.4,11.6-8.4
                c60.1-180.1,120.2-360.2,180.2-540.3c2.6-7.9-3.3-16.1-11.6-16.1h0C276.8,119.3,272.4,122.3,270.5,126.9z"
            />
        </svg>);
    }
}

export default AsphaltedIcon;
