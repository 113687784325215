import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { t } from 'i18next';

const StyledTextField = styled(TextField)`
    width: 100% !important;
    margin-bottom: 25px !important;

    * {
        // To reset the Firefox box-shadow when required.
        box-shadow: initial;
    }
`;

class Textfield extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            errorStyle: {},
            valid: !props.required,
        }
    }

    componentDidMount() {
        const { valid, name, value, onChange } = this.props;
        if (value) {
            // If value has been set from the parent state, creating an artificial event.target.value
            // because multiple function expect an event object.
            // We trigger the validation function, so that the valid status is properly set on mount.
            const event = {
                target: {
                    name: name,
                    value: value,
                }
            };

            this.handleInputValidation(event, onChange)
        }

        if (valid) {
            valid(name, this.state.valid);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.valid && this.state.valid !== prevState.valid) {
            this.props.valid(this.props.name, this.state.valid);
        }
    }

    handleInputValidation = (e, callback) => {
        const { constraint, required } = this.props;
        const warningText = this.props.warningText || t('forms:formValidation.somethingWrong');
        const errorText = this.props.errorText || t('forms:formValidation.required');
        const targetValue = e.target.value;

        // Default
        let error = '';
        let className = '';
        let valid = true;

        if (!required){
        // Is not required.
            if(targetValue && constraint && !targetValue.match(constraint)){
            // Has value and validation rules but fails the verification.
                error = warningText;
                valid = false;
            }
        } else if (!targetValue){
        // Is required and doesn't have a value.
            error = errorText;
            valid = false;
        } else if (constraint && !targetValue.match(constraint)){
        // Is required, has value and validation rules but fails the verification.
            error = warningText;
            valid = false;
        }

        return this.setState({
            error: error,
            className: className,
            valid: valid,
        }, callback(e));
    }

    render(){
        const { label, multiLine, name, onChange, rows, required, value } = this.props;

        return (
            <StyledTextField
                helperText={ this.state.error }
                error={ !!this.state.error }
                label={ label }
                multiline={ multiLine }
                name={ name }
                onChange={ (e) => this.handleInputValidation(e, onChange) }
                onBlur={ (e) => this.handleInputValidation(e, onChange) }
                rows={ rows }
                required={ required }
                value={ value }
                InputLabelProps={{
                    shrink: true,
                }}
            />
        );
    }
}

Textfield.defaultProps = {
    required: false
}

Textfield.propTypes = {
    constraint: PropTypes.object,
    errorText: PropTypes.string,
    label: PropTypes.string,
    multiLine: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    rows: PropTypes.number,
    valid: PropTypes.func,
    value: PropTypes.string,
    warningText: PropTypes.string,
}

export default Textfield;
