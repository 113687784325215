import React from 'react';
import BaseIcon from './BaseIcon';

class LuggageIcon extends BaseIcon {
    render() {
        return (<svg version='1.1' id='Luggage' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 800'>
            <path
                fill={ this.props.primary }
                d='M597.6,214.7h-48.9V66.9c0-34.9-28.4-63.3-63.3-63.3H314.7c-34.9,0-63.3,28.4-63.3,63.3v147.8h-48.9
                c-30.5,0-55.3,24.8-55.3,55.3v417.6c0,30.6,24.8,55.3,55.3,55.3h7.4v40.1c0,7.4,6,13.4,13.4,13.4h63.2c7.4,0,13.4-6,13.4-13.4v-40.1
                h200.3v40.1c0,7.4,6,13.4,13.4,13.4h63.2c7.4,0,13.4-6,13.4-13.4v-40.1h7.4c30.5,0,55.3-24.8,55.3-55.3V270
                C652.9,239.5,628.1,214.7,597.6,214.7z M506.4,214.7H293.6V66.9c0-11.6,9.5-21.1,21.1-21.1h170.7c11.6,0,21.1,9.5,21.1,21.1V214.7z'
            />
        </svg>);
    }
}

export default LuggageIcon;
