const legalDocs = {
    general: {
        privacyStatement: {
            en: 'https://mobian.global/wp-content/uploads/Privacy_statement_MOBIAN.pdf',
        },
        termsConditions: {
            de: 'https://mobian.global/wp-content/uploads/Allgemeine-Gesch%C3%A4ftsbedingungen_MOBIAN.pdf',
            en: 'https://mobian.global/wp-content/uploads/Bookings_TermsConditions_MOBIAN.pdf',
            es: 'https://mobian.global/wp-content/uploads/Términos_y_Condiciones_booking_MOBIAN.pdf',
            fr: 'https://mobian.global/wp-content/uploads/Termes_et_conditions-Réservations_MOBIAN.pdf',
            it: 'https://mobian.global/wp-content/uploads/Condizioni_Generali_booking_MOBIAN.pdf',
            nl: 'https://mobian.global/wp-content/uploads/Algemene_voorwaarden_boekingen_MOBIAN.pdf',
        },
    },
    provider: {
        engagementAgreement: {
            de: 'https://mobian.global/wp-content/uploads/Engagement_Vereinbarung_Provider_MOBIAN.pdf',
            en: 'https://mobian.global/wp-content/uploads/Engagement_agreement_Provider_MOBIAN.pdf',
            fr: 'https://mobian.global/wp-content/uploads/Lettre_de_mission_Provider_MOBIAN.pdf',
            nl: 'https://mobian.global/wp-content/uploads/Engagement_overeenkomst_Provider_MOBIAN.pdf',
        },
        termsConditions: {
            de: 'https://mobian.global/wp-content/uploads/Gesch%C3%A4ftsbedingungen_Provider_MOBIAN.pdf',
            en: 'https://mobian.global/wp-content/uploads/TermsConditions_Provider_MOBIAN.pdf',
            fr: 'https://mobian.global/wp-content/uploads/Termes_et_conditions_Provider_MOBIAN.pdf',
            nl: 'https://mobian.global/wp-content/uploads/Algemene_voorwaarden_Provider_MOBIAN.pdf',
        },
    },
    reseller: {
        engagementAgreement: {
            en: 'https://mobian.global/wp-content/uploads/Engagement_agreement_Reseller_MOBIAN.pdf',
        },
        termsConditions: {
            en: 'https://mobian.global/wp-content/uploads/TermsConditions_Reseller_MOBIAN.pdf',
        },
    },
};

const legalLink = (key) => {
    let browserLanguage = window.navigator.userLanguage || window.navigator.language;
    browserLanguage = browserLanguage.split('-').shift(); // Removing the region from the language if it set.

    let keys = key.split('.');
    keys.push(browserLanguage);

    const link = keys.reduce(
        (result, index) => result[index] || result['en'], legalDocs
    );

    return link;
};

export default legalLink;
