import React from 'react';
import BaseIcon from './BaseIcon';

class EnclosedIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="enclosed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path
                fill={ this.props.primary }
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.9,23.7L41.3,6.1l19.1,17.5v27.6h20.4V23.7l19.4-17.5l19.2,17.5v27.6H140V23.7l18.5-17.5l20.1,17.5v27.6
                h10.5c2.8,0,5,2.2,5,5v4.9c0,2.8-2.2,5-5,5h-10.5v73.8h10.5c2.8,0,5,2.2,5,5v3.5c0,2.8-2.2,5-5,5h-10.5v40.4H140v-40.4h-20.5v40.4
                H80.9v-40.4H60.5v40.4H21.9v-40.4h-11c-2.8,0-5-2.2-5-5v-3.5c0-2.8,2.2-5,5-5h11V66.2H11.7c-2.8,0-5-2.2-5-5v-4.9c0-2.8,2.2-5,5-5
                h10.2L21.9,23.7L21.9,23.7z M140,140.1V66.2h-20.5v73.8L140,140.1L140,140.1z M80.9,140.1V66.2H60.5v73.8L80.9,140.1L80.9,140.1z"
            />
        </svg>);
    }
}

export default EnclosedIcon;
