import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ParkingRows from './rows/ParkingRows';
import TaxiRows from './rows/TaxiRows';
import TransferRows from './rows/TransferRows';

const StyledTable = styled.table`
    width: 100%;
    border: 0;
    border-collapse: collapse;
`;

const showRows = information => {
    switch (information.typeOfBooking) {
        case 'parking':
            return <ParkingRows information={ information } />
        case 'taxi':
            return <TaxiRows information={ information } />
        case 'transfer':
            return <TransferRows information={ information } />
        default:
            throw new Error('Missing booking type');
    }
};

const Table = ({ information }) => {
    return (
        <StyledTable>
            { showRows(information) }
        </StyledTable>
    );
}

Table.propTypes = {
    information: PropTypes.object.isRequired,
}

export default Table;
