import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import PointOfInterestResult from './locationField/PointOfInterestResult';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import mobianApiClient from '../../api/mobianApiClient';
import utils from '../../utils';
import { withStyles } from '@material-ui/core/styles';

function renderInputComponent(inputProps) {
    const { inputRef = () => {}, ref, ...other } = inputProps;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: node => {
                    ref(node);
                    inputRef(node);
                },
            }}
            { ...other }
        />
    );
}

function renderSuggestion(suggestion, { isHighlighted }) {
    if (suggestion.type === "google-places") {
        return(
            <MenuItem
                selected={ isHighlighted }
                component="div"
            >
                { suggestion.name }
            </MenuItem>
        )
    }

    return(
        <MenuItem
            selected={ isHighlighted }
            component="div"
        >
            <PointOfInterestResult result={ suggestion } />
        </MenuItem>
    )
}

const getSuggestionValue = suggestion => suggestion.name;

const styles = () => ({
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
});

class LocationField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            error: '',
            google: [],
            isLoading: false,
            pointsOfInterest: [],
            valid: !props.required,
        }
    }

    componentDidMount() {
        const { valid, name, value } = this.props;
        if (value) {
            this.handleInputValidation(value)
        }

        if (valid) {
            valid(name, this.state.valid);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.valid && this.state.valid !== prevState.valid) {
            this.props.valid(this.props.name, this.state.valid);
        }
    }

    fetchCoordinates(id) {
        const geocoder = new google.maps.Geocoder;

        const options = {
            placeId: id,
        };

        return new Promise(function (resolve, reject) {
            const process = (response, status) => {
                if (status !== 'OK' || !response || !response[0]) {
                    reject("Google Geocode seems down or couldn't find the location.");
                }

                const result = {
                    latitude: response[0].geometry.location.lat(),
                    longitude: response[0].geometry.location.lng(),
                };

                resolve(result);
            }

            geocoder.geocode(options, process);
        });
    };

    fetchGooglePlaces(value) {
        const autocomplete = new google.maps.places.AutocompleteService();
        const { types } = this.props;

        const options = {
            input: value,
        };

        if (types !== null) {
            options.types = types;
        }

        return new Promise(function (resolve) {
            const process = (predictions, status) => {
                let results = [];

                if (status === google.maps.places.PlacesServiceStatus.OK) {

                    // Process predictions
                    predictions.forEach((result) => {
                        results.push({
                            address: result.description,
                            id: result.place_id,
                            type: 'google-places',
                            name: result.description,
                        });
                    });
                }

                resolve(results);
            }

            autocomplete.getPlacePredictions(options, process);
        });
    }

    fetchPointsOfInterest = (value) => {
        const params = {
            name: `*${ value.replace(/%20/g, '*') }*`,
            size: 12,
        };

        return mobianApiClient.fetchPointsOfInterest(params).then((response) => {
            let results = [];

            if (utils.isEmpty(response)) {
                return results;
            }

            response.results.slice(0, 5).forEach((result) => {
                results.push({
                    address: result.address,
                    id: result.id,
                    type: result.type,
                    name: result.name,
                    coordinates: {
                        latitude: result.latitude,
                        longitude: result.longitude,
                    },
                });


            });

            return results;
        });
    }

    fetchData = (value) => {
        Promise.all([
            this.fetchPointsOfInterest(value),
            this.fetchGooglePlaces(value),
        ]).then(([ pointsOfInterest, google ]) => {
            const results = [...pointsOfInterest, ...google];

            return this.setState({
                dataSource: results,
            });
        });
    }


    handleInputValidation = (input) => {
        const { required, t, onChange } = this.props;
        const errorText = this.props.errorText || t('forms:formValidation.required');

        // Default
        let error = '';
        let valid = true;

        if (required && !input.id) {
            // Is required and doesn't have a value.
            error = errorText;
            valid = false;
        }

        return this.setState({
            error: error,
            valid: valid,
        },
            onChange(input)
        );
    }

    handleInput = ({ value }) => {
        if (value.length <= 2) {
            if (this.props.value !== null) {
                return this.handleInputValidation(value);
            }

            return;
        }

        return this.fetchData(value);
    }

    handleBlur = (event) => {
        if (!event.target.value || this.props.value.id) {
            // Either it has no value
            // Or already has a valid id therefore we don't need to check if it is valid.
            return;
        }

        return this.handleInputValidation(event.target.value);
    }

    handleChange = (event, { newValue, method }) => {
        if (!newValue && method !== "type") {
            return;
        }

        this.props.onChange(newValue);
      };

    onSuggestionSelected = (event, { suggestion }) => {
        // If the selected suggestion doesn't have coordinates, we'll fetch them from Google and add them.
        if (suggestion.hasOwnProperty('coordinates')) {
            return this.handleInputValidation(suggestion);
        }

        this.fetchCoordinates(suggestion.id).then((coordinates) => {
            suggestion.coordinates = coordinates;

            this.handleInputValidation(suggestion);
        });
    }

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            dataSource: []
        });
    };

    render() {
        const { classes, label, value } = this.props;

        const inputProps = {
            error: !!this.state.error,
            helperText: this.state.error,
            inputRef: (node) => {
                this.popperNode = node;
            },
            InputLabelProps: {
                shrink: true,
            },
            label: label,
            onBlur: this.handleBlur.bind(this),
            onChange: this.handleChange,
            value: _.get(value, 'name') || '',
        };

        return (
            <Autosuggest
                theme={{
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                suggestions={ this.state.dataSource }
                onSuggestionsFetchRequested={ this.handleInput }
                onSuggestionsClearRequested={ this.onSuggestionsClearRequested }
                getSuggestionValue={ getSuggestionValue }
                onSuggestionSelected={ this.onSuggestionSelected }
                renderInputComponent={ renderInputComponent }
                renderSuggestion={ renderSuggestion }
                inputProps={ inputProps }
                renderSuggestionsContainer={
                    (options) => (
                        <Popper anchorEl={ this.popperNode } open={ Boolean(options.children) }>
                            <Paper
                                square
                                {...options.containerProps}
                                style={{ width: this.popperNode ? this.popperNode.clientWidth : null }}
                            >
                                {options.children}
                            </Paper>
                        </Popper>
                    )
                }
            />
        );
    }
}

LocationField.propTypes = {
    classes: PropTypes.object,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    t: PropTypes.func,
    value: PropTypes.object,
};

export default withStyles(styles)(LocationField);
