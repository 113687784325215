import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MessageBox = styled.ul`
    list-style-type: none;
    margin: 0;
    display: block;
    ${ props => props.backgroundColor ? `
        padding: 1rem;
        background: ${ props.backgroundColor }
    ` : `
        padding: 0;
    ` }
`;

/**
 * @todo finish the component with regards to its styling.
 */

const StatusBoxMessages = ({ text, status }) => {
    const textArray = text.split('\n');
    const Messages = textArray.map((message, key) => {
        return <li key={ `result_${key}` }>{ message }</li>
    });

    const backgroundColor = (status === 'error') ? '#FFECB3' : false;

    return (
        <MessageBox backgroundColor={ backgroundColor }>
            { Messages }
        </MessageBox>
    )
}

StatusBoxMessages.propTypes = {
    text: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
}

export default StatusBoxMessages;
