import React from 'react';
import PropTypes from 'prop-types';
import Price from './Price';
import FlexContainer from '../components/grid/FlexContainer';
import Checkbox from '../components/Checkbox';

const CheckboxWithPrice = ({ label, price, currency, locale, checked, onChange }) => {
    return (
        <label>
            <FlexContainer>
                <Checkbox
                    label={ label }
                    checked={ checked }
                    onChange={ onChange }
                />

                <Price
                    amount={ price }
                    currency={ currency }
                    locale={ locale }
                />
            </FlexContainer>
        </label>
    )
}

CheckboxWithPrice.propTypes = {
    checked: PropTypes.bool,
    currency: PropTypes.string,
    label: PropTypes.string,
    locale: PropTypes.string,
    onChange: PropTypes.func,
    price: PropTypes.number
}

export default CheckboxWithPrice;
