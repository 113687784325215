import React from 'react';
import BaseIcon from './BaseIcon';

class NoShuttleIcon extends BaseIcon {
    render() {
        return (<svg version='1.1' id='NoShuttle' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 800'>
            <g>
                <path
                    fill={ this.props.primary }
                    d='M268.2,183.3h334.4c14,0,22,4.6,23.9,14c0,0,25.2,175.2,25.2,177c0,7.6-2.4,13.6-7,18.3c-4.6,4.6-10.7,7-18.3,7H484.5
                    l105.3,105.3c5.9-2.6,12.3-4,19.1-4c12.6,0,23.6,4.5,33,13.4s14,19.7,14,32.3c0,7.2-1.5,13.8-4.5,19.8l49.7,49.7V374.3l-28.2-224.9
                    c-3.8-19.6-11.3-34.6-22.5-44.9c-11.3-10.3-27.6-19.6-49.2-28.1c-21.6-9.4-52.5-17.8-92.8-25.3C468.9,43.7,432.7,40,400,39.8
                    c-32.7,0.1-68.9,3.8-108.3,11.2c-40.3,7.6-71.2,15.9-92.8,25.3c-9.7,3.8-18.3,7.8-25.8,11.9L268.2,183.3z M269.8,99.5h260.4
                    c12.6,0,22.9,10.3,22.9,22.9c0,12.6-10.3,22.9-22.9,22.9H269.8c-12.6,0-22.9-10.2-22.9-22.9C246.9,109.8,257.2,99.5,269.8,99.5z'
                />

                <path
                    fill={ this.props.primary }
                    d='M314.7,399.6h-141c-7.6,0-13.6-2.4-18.3-7c-4.6-4.6-7-10.7-7-18.3c0-1.2,10.1-71.9,17.5-123.5l-45.7-45.7L99.1,374.3v293.4
                    c0,11,9,20,20,20h41.7c0,0.1,0,0.3,0,0.4v41.3c0,17,13.8,30.8,30.8,30.8h15.9c17,0,30.8-13.8,30.8-30.8c0,0,0-41.5,0-41.6h323.5
                    c0,0.1,0,41.6,0,41.6c0,17,13.8,30.8,30.8,30.8h15.9c17,0,30.8-13.8,30.8-30.8v-5.2L314.7,399.6z M224.2,578.9
                    c-9.4,8.9-20.4,13.4-33,13.4c-12.6,0-23.7-4.5-33-13.4c-9.4-8.9-14-19.6-14-32.3c0-12.6,4.6-23.5,14-32.3
                    c9.4-8.9,20.4-13.4,33-13.4c12.6,0,23.7,4.5,33,13.4c9.4,8.9,14,19.6,14,32.3C238.3,559.2,233.6,569.9,224.2,578.9z'
                />
            </g>

            <g>
                <path
                    fill={ this.props.primary }
                    d='M731.6,771.6c-10.2,0-20.5-3.9-28.3-11.7L40.1,96.7c-15.6-15.6-15.6-40.9,0-56.6s40.9-15.6,56.6,0l663.1,663.1
                    c15.6,15.6,15.6,40.9,0,56.6C752,767.7,741.8,771.6,731.6,771.6z'
                />
            </g>
        </svg>);
    }
}

export default NoShuttleIcon;
