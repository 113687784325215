import React from 'react';
import CardBox from '../../components/cardBox/CardBox';
import FlexContainer from '../../components/grid/FlexContainer';
import StatusBox from '../../components/statusBox/StatusBox';
import { ConfirmationConsumer } from '../../context/Confirmation';
import { t } from 'i18next';

const BookingStatus = () => {
    return (
        <CardBox>
            <ConfirmationConsumer>
                {({ bookingLoading, bookingMessage, bookingResult }) => {
                    const { reservation_status, order_id } = bookingResult;

                    const texts = t('confirmation:bookingStatus', { returnObjects: true });
                    const bookingReferenceText = order_id ? `Booking #${ order_id }` : ``;

                    if (bookingLoading) {
                        return (
                            <FlexContainer justify="center">
                                <StatusBox
                                    status="loading"
                                    title={ texts.loading.title }
                                    text={ texts.loading.text }
                                    />
                            </FlexContainer>
                        )
                    }

                    // If there is no reservation status, something went wrong.
                    if (!reservation_status) {
                        return (
                            <FlexContainer justify="center">
                                <StatusBox
                                    status="error"
                                    title={ texts.error.title }
                                    text={ bookingMessage || texts.error.text }
                                />
                            </FlexContainer>
                        )
                    }

                    return (
                        <FlexContainer justify="center">
                            <StatusBox
                                status={ reservation_status }
                                title={ texts[reservation_status].title }
                                text={ texts[reservation_status].text }
                                addendum={ bookingReferenceText }
                            />
                        </FlexContainer>
                    )
                }}
            </ConfirmationConsumer>
        </CardBox>
    )
}

export default BookingStatus;
