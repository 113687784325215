import _ from 'lodash';

export default (function () {
    const buildQueryString = (params, prefix = '') => {
        let queryString = [];

        _.forEach(params, (value, key) => {

            // Add an prefix for all the formatted keys.
            let formattedKey = prefix ? `${prefix}[${key}]` : key;

            // Convert the pascalCase keys to a underscore case to make search possible.
            formattedKey = _.snakeCase(formattedKey);

            // Loop through the different set of keys.
            if (_.isArray(value)) {
                _.forEach(value, arrayValue => {
                    queryString.push(`${formattedKey}[]=${arrayValue}`);
                });
            } else if (_.isObject(value)) {
                queryString.push(buildQueryString(value, formattedKey));
            } else {
                queryString.push(`${formattedKey}=${value}`);
            }
        });

        return queryString.join('&');
    };

    /**
     * normalizes the payload.
     * @param {Object} payload
     */
    const normalizePayload = payload => {
        let result = {};

        Object.entries(payload).forEach(([key, val])  => {
            if (val === '') {
                return;
            }

            if (val === null) {
                return;
            }

            if (_.isArray(val)) {
                if (_.isEmpty(val)) {
                    return;
                }

                _.forEach(val, subValue => {
                    return subValue !== "" || subValue !== undefined;
                })
            }

            if (_.isPlainObject(val)) {
                val = normalizePayload(val);
            }

            if (_.isString(val)) {
                val = val.trim();
            }

            result[key] = val;
        });

        return result;
    }

    const generateString = () => {
        let dt = new Date().getTime();

        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = (dt + Math.random()*16)%16 | 0;

            dt = Math.floor(dt/16);

            return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
        });

        return uuid;
    };

    return {
        /**
         * The map() method creates a new array with the results of calling a provided
         * function on every element in the calling array or object.
         *
         * @param {Array|Object} object - Object to traverse through.
         * @param {function} callback - Function which is called on every entry.
         * @returns {Array}
         */
        map: (object, callback) => {
            if (_.isArray(object)) {
                return object.map(callback);
            }

            let mappedValues = [];

            _.forOwn(object, (value, key) => {
                mappedValues.push(callback(value, key));
            });

            return mappedValues;
        },

        tokenGenerator: () => {
            return generateString();
        },

        buildUrl: (baseUrl, params) => {
            if (_.isEmpty(params)) {
                return baseUrl;
            }

            params = normalizePayload(params);

            return baseUrl + (~baseUrl.indexOf('?') ? '&' : '?') +  buildQueryString(params);
        },
    }
})();
