import React from 'react';
import BaseIcon from './BaseIcon';

class SmsIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="sms" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M604.9,146.8H204.6c-28.8,0-52.2,25.1-52.2,56v284c0,30.9,23.4,56,52.2,56H390l157.9,136.7V542.8h57
                c28.8,0,52.2-25.1,52.2-56v-284C657.1,171.9,633.7,146.8,604.9,146.8z M316.4,382.7c-2,4.9-4.8,9.2-8.5,12.8
                c-3.7,3.7-8.2,6.5-13.6,8.6c-5.4,2.1-11.5,3.1-18.4,3.1c-3.9,0-7.8-0.4-11.7-1.2c-3.9-0.8-7.6-1.9-11.2-3.3c-3.6-1.4-7-3.1-10.2-5.1
                c-3.2-2-6-4.2-8.3-6.6L242,379c0.6-0.9,1.4-1.7,2.5-2.3c1-0.6,2.1-0.9,3.2-0.9c1.5,0,3.1,0.6,4.8,1.7c1.7,1.1,3.7,2.4,5.9,3.7
                c2.2,1.4,4.8,2.6,7.7,3.7c2.9,1.1,6.4,1.7,10.5,1.7c5.9,0,10.6-1.4,13.8-4.1c3.3-2.7,4.9-6.8,4.9-12.2c0-3.1-0.8-5.6-2.4-7.6
                c-1.6-1.9-3.7-3.5-6.3-4.8c-2.6-1.3-5.6-2.4-8.9-3.4c-3.3-0.9-6.8-2-10.3-3.1c-3.5-1.1-6.9-2.5-10.3-4.1c-3.3-1.6-6.3-3.7-8.9-6.3
                c-2.6-2.6-4.7-5.8-6.3-9.7c-1.6-3.9-2.4-8.6-2.4-14.4c0-4.6,0.9-9,2.7-13.3c1.8-4.3,4.5-8.1,8-11.5c3.5-3.3,7.8-6,13-8.1
                c5.1-2,11-3.1,17.6-3.1c7.4,0,14.3,1.1,20.6,3.4c6.3,2.3,11.7,5.5,16,9.6l-6.3,12c-0.8,1.3-1.6,2.3-2.4,2.8
                c-0.8,0.6-1.9,0.9-3.2,0.9c-1.2,0-2.6-0.4-4-1.3c-1.4-0.9-3.1-1.8-5.1-2.8c-1.9-1-4.1-2-6.6-2.8c-2.5-0.9-5.4-1.3-8.8-1.3
                c-5.9,0-10.3,1.3-13.3,4c-2.9,2.6-4.4,6.1-4.4,10.3c0,2.7,0.8,5,2.4,6.8c1.6,1.8,3.7,3.3,6.4,4.6c2.6,1.3,5.6,2.5,9,3.5
                c3.3,1,6.8,2.1,10.3,3.4c3.5,1.2,6.9,2.6,10.3,4.3c3.3,1.6,6.3,3.7,9,6.2c2.6,2.5,4.8,5.5,6.4,9.1c1.6,3.6,2.4,8,2.4,13.1
                C319.3,372.6,318.3,377.8,316.4,382.7z M473.8,406h-22.5v-75.7c0-1.6,0-3.3,0.1-5.1c0.1-1.8,0.2-3.7,0.5-5.5l-35.3,66.9
                c-1.9,3.7-4.9,5.5-8.9,5.5h-3.6c-2,0-3.7-0.5-5.3-1.4c-1.5-0.9-2.7-2.3-3.7-4.1l-35.4-67.2c0.2,2,0.3,3.9,0.4,5.7
                c0.1,1.8,0.2,3.6,0.2,5.1V406h-22.5V282.6h19.4c1.1,0,2,0,2.9,0.1c0.9,0.1,1.6,0.2,2.3,0.5c0.7,0.3,1.2,0.7,1.8,1.2
                c0.5,0.5,1.1,1.3,1.6,2.3l34.5,65.8c1.1,2,2.1,4,3,6.1c0.9,2.1,1.8,4.2,2.7,6.5c0.9-2.3,1.7-4.5,2.7-6.6c0.9-2.2,1.9-4.2,3-6.2
                l34.3-65.5c0.5-1,1-1.7,1.6-2.3c0.6-0.5,1.2-0.9,1.8-1.2c0.7-0.3,1.4-0.4,2.2-0.5c0.8-0.1,1.8-0.1,2.9-0.1h19.4V406z M571.8,382.7
                c-2,4.9-4.8,9.2-8.5,12.8c-3.7,3.7-8.2,6.5-13.6,8.6c-5.4,2.1-11.5,3.1-18.4,3.1c-3.9,0-7.8-0.4-11.7-1.2c-3.9-0.8-7.6-1.9-11.2-3.3
                c-3.6-1.4-7-3.1-10.2-5.1c-3.2-2-6-4.2-8.3-6.6l7.5-12.1c0.6-0.9,1.4-1.7,2.5-2.3c1-0.6,2.1-0.9,3.2-0.9c1.5,0,3.1,0.6,4.8,1.7
                c1.7,1.1,3.7,2.4,5.9,3.7s4.8,2.6,7.7,3.7c2.9,1.1,6.4,1.7,10.5,1.7c6,0,10.6-1.4,13.8-4.1c3.3-2.7,4.9-6.8,4.9-12.2
                c0-3.1-0.8-5.6-2.4-7.6c-1.6-1.9-3.7-3.5-6.3-4.8c-2.6-1.3-5.6-2.4-8.9-3.4c-3.3-0.9-6.8-2-10.3-3.1c-3.5-1.1-6.9-2.5-10.3-4.1
                c-3.3-1.6-6.3-3.7-8.9-6.3c-2.6-2.6-4.7-5.8-6.3-9.7c-1.6-3.9-2.4-8.6-2.4-14.4c0-4.6,0.9-9,2.7-13.3c1.8-4.3,4.5-8.1,8-11.5
                c3.5-3.3,7.8-6,13-8.1c5.1-2,11-3.1,17.6-3.1c7.4,0,14.3,1.1,20.6,3.4s11.7,5.5,16,9.6l-6.3,12c-0.8,1.3-1.6,2.3-2.4,2.8
                c-0.8,0.6-1.9,0.9-3.2,0.9c-1.2,0-2.6-0.4-4-1.3c-1.4-0.9-3.1-1.8-5.1-2.8c-1.9-1-4.1-2-6.6-2.8c-2.5-0.9-5.4-1.3-8.8-1.3
                c-5.9,0-10.3,1.3-13.3,4c-2.9,2.6-4.4,6.1-4.4,10.3c0,2.7,0.8,5,2.4,6.8c1.6,1.8,3.7,3.3,6.4,4.6c2.6,1.3,5.6,2.5,9,3.5
                s6.8,2.1,10.3,3.4c3.5,1.2,6.9,2.6,10.3,4.3c3.3,1.6,6.3,3.7,9,6.2c2.6,2.5,4.8,5.5,6.4,9.1c1.6,3.6,2.4,8,2.4,13.1
                C574.7,372.6,573.8,377.8,571.8,382.7z"
            />
        </svg>);
    }
}

export default SmsIcon;
