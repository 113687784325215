import React from 'react';
import PropTypes from 'prop-types';
import FlexContainer from '../components/grid/FlexContainer';

const Stepbuttons = (props) => {
    return (
        <FlexContainer justify={ props.justify }>
            { props.children }
        </FlexContainer>
    )
}

Stepbuttons.defaultProps = {
    justify: 'space-between',
}

Stepbuttons.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    justify: PropTypes.string,
}

export default Stepbuttons;
