import React from 'react';
import BaseIcon from './BaseIcon';

class PluginChargingIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="plugin_charging" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <g>
                <path fill={ this.props.primary } d="M693.8,333h-38.5v-22.1c0-29.2-23.7-53-52.9-53H160.8c-29.2,0-52.9,23.7-52.9,53v221c0,29.2,23.7,53,52.9,53
                    h441.6c29.2,0,52.9-23.7,52.9-53v-23.2h38.5c14.6,0,26.5-11.8,26.5-26.4V359.5C720.3,344.9,708.4,333,693.8,333z M518.3,446.1
                    c13,0,23.4,10.5,23.4,23.4c0,12.9-10.5,23.4-23.4,23.4h-50.8v2.6c0,17.3-14,31.3-31.3,31.3h-21.5c-46.3,0-85.6-29.9-99.6-71.1
                    h-42.4c-19.4,0-35.1-15.8-35.1-35.2s15.7-35.1,35.1-35.1H317c15.2-38.6,53.2-66.1,97.7-66.1h21.5c16.4,0,29.7,12.7,31,28.7h51.1
                    c13,0,23.4,10.5,23.4,23.4c0,13-10.5,23.5-23.4,23.5h-50.8v51.2H518.3z"/>
            </g>
        </svg>);
    }
}

export default PluginChargingIcon;
