import axios from 'axios';

const SoloClient = () => {
    let axiosSource,

        /**
        * Use axios to make an external call.
        *
        * @param {Object} config
        * @returns {AxiosInstance}
        */
        call = (config) => {
            if (axiosSource) {
                axiosSource.cancel('Only one request allowed at a time.');
            }

            axiosSource = axios.CancelToken.source();

            config = config || {};
            config.cancelToken = axiosSource.token;

            return axios(config);
        };

    return call;
};

export default new SoloClient();
