import React from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-datetime-picker';
import FormHelperText from '@material-ui/core/FormHelperText';
import styled from 'styled-components';
import utils from '../../utils';
import { t } from 'i18next';

const StyledDateTimePicker = styled(DateTimePicker)`
    width: 100% !important;
    margin-top: 14px;
    padding: 6px 0 0;

    > div {
        width: 100% !important;
    }

    .react-datetime-picker__wrapper {
        border: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    }

    .react-datetime-picker__calendar--above-label {
        // This is needed because sometime the calender show above the field instead of underneath.
        top: 100%;
    }

    .react-calendar {
        border: 0;
        box-shadow: rgba(0,0,0,0.12) 0px 1px 6px, rgba(0,0,0,0.12) 0px 1px 4px;
        color: black;
    }
`;

const StyleWrapper = styled.div`
    position: relative;
`;

const Label = styled.label`
    top: 0;
    left: 0;
    position: absolute;
    color: ${ props => props.color || 'rgba(0, 0, 0, 0.54)' };
    font-size: .75rem;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
`;

class DateTimepicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            errorColor: '',
            valid: !props.required,
            pristine: true,
        }
    }

    componentDidMount() {
        const { valid, name, value } = this.props;

        if (value) {
            this.handleInputValidation(value);
        }

        if (valid) {
            valid(name, this.state.valid);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.minDate && this.props.minDate !== prevProps.minDate) {
            // Add 7 days to the minDate.
            let value = this.props.value || (this.props.minDate ? utils.addDays(this.props.minDate, 7) : null);

            if (value && value < this.props.minDate) {
                value = this.props.minDate;
            }

            this.handleInputValidation(value);
        }

        if (this.props.valid && this.state.valid !== prevState.valid) {
            this.props.valid(this.props.name, this.state.valid);
        }
    }

    handleInputValidation = (targetValue) => {
        const { constraint, required, name, onChange } = this.props;
        const warningText = this.props.warningText || t('forms:formValidation.somethingWrong');
        const errorText = this.props.errorText || t('forms:formValidation.required');
        const oneHourFromNow = utils.addHours(new Date(), 1);

        // Default
        let error;
        let errorColor;
        let valid = true;
        let value = targetValue;
        let pristine = this.state.pristine;

        if (value) {
            value = utils.roundTime(5, value);

            if (value <= oneHourFromNow) {
                value = oneHourFromNow;
            }
        }

        // Is not required.
        if (!required) {
            // Has value and validation rules but fails the verification.
            if(value && constraint && !value.match(constraint)){
                error = warningText;
                errorColor = 'rgb(244, 67, 54)';
                valid = false;
            }
        // Is required and doesn't have a value.
        } else if (!value) {
            error = errorText;
            errorColor = 'rgb(244, 67, 54)';
            valid = false;
        // Is required, has value and validation rules but fails the verification.
        } else if (constraint && !value.match(constraint)) {
            error = warningText;
            valid = false;
        }

        if (this.state.pristine && targetValue) {
            const standardValue = new Date(new Date(value).setHours(12,0,0));

            if (standardValue >= oneHourFromNow) {
                value = standardValue;
            }

            pristine = false;
        }

        const event = {
            target: {
                name: name,
                value: value,
            },
        };

        return this.setState({
            error: error,
            errorColor: errorColor,
            valid: valid,
            pristine: pristine,
        }, onChange(event));
    }

    render(){
        let { value } = this.props;

        const { label, name, minDate } = this.props;

        const minimumDate = minDate || utils.addHours(new Date(), 1);

        // Add 7 days to the minDate.
        value = value || (minDate ? utils.addDays(minDate, 7) : null);

        return (
            <StyleWrapper>
                <Label color={ this.state.errorColor }>
                    { label }
                </Label>

                <StyledDateTimePicker
                    clearIcon={ null }
                    disableClock={ true }
                    minDate={ minimumDate }
                    minDetail="decade"
                    name={ name }
                    onBlur={ () => this.handleInputValidation(this.props.value) }
                    onChange={ this.handleInputValidation }
                    value={ value || null }
                />

                <FormHelperText error={ !!this.state.error }>
                    { this.state.error }
                </FormHelperText>
            </StyleWrapper>
        );
    }
}

DateTimepicker.propTypes = {
    constraint: PropTypes.object,
    errorText: PropTypes.string,
    label: PropTypes.string.isRequired,
    minDate: PropTypes.object,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    valid: PropTypes.func.isRequired,
    value: PropTypes.object,
    warningText: PropTypes.string,
}

export default DateTimepicker;
