import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledBookButton = styled.button`
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    appearance: none;
    background-color: #419488;
    border-radius: 2px;
    border: 0;
    box-sizing: border-box;
    color: #FFF;
    cursor: pointer;
    display: inline-block;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    margin: 0 !important;
    outline: none;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    min-width: 130px;
    min-height: 50px;

    &:hover{
        background-color: #00796B;
    }

    &:focus, &:active{
        background-color: #00695C;
    }
`;

const BookButton = ({ onClick, title }) => {
    return (
        <StyledBookButton
            onClick={ onClick }
        >
            { title }
        </StyledBookButton>
    );
}

BookButton.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired,
}

export default BookButton;
