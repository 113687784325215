import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledMapLabel = styled.div`
    margin-top: 5px;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
`;

const MapLabel = ({ title }) => {
    return (
        <StyledMapLabel>
            { title }
        </StyledMapLabel>
    )
}

MapLabel.propTypes = {
    title: PropTypes.string.isRequired,
}

export default MapLabel;
