import englishCommon from './en/common.json';
import englishForms from './en/forms.json';
import englishComparison from './en/comparison.json';
import englishConfirmation from './en/confirmation.json';

import germanCommon from './de/common.json';
import germanForms from './de/forms.json';
import germanComparison from './de/comparison.json';
import germanConfirmation from './de/confirmation.json';

import dutchCommon from './nl/common.json';
import dutchForms from './nl/forms.json';
import dutchComparison from './nl/comparison.json';
import dutchConfirmation from './nl/confirmation.json';

import italianCommon from './it/common.json';
import italianForms from './it/forms.json';
import italianComparison from './it/comparison.json';
import italianConfirmation from './it/confirmation.json';

import frenchCommon from './fr/common.json';
import frenchForms from './fr/forms.json';
import frenchComparison from './fr/comparison.json';
import frenchConfirmation from './fr/confirmation.json';

import spanishCommon from './es/common.json';
import spanishForms from './es/forms.json';
import spanishComparison from './es/comparison.json';
import spanishConfirmation from './es/confirmation.json';

const resources = {
    de: {
        common: germanCommon,
        forms: germanForms,
        comparison: germanComparison,
        confirmation: germanConfirmation,
    },
    en: {
        common: englishCommon,
        forms: englishForms,
        comparison: englishComparison,
        confirmation: englishConfirmation,
    },
    es: {
        common: spanishCommon,
        forms: spanishForms,
        comparison: spanishComparison,
        confirmation: spanishConfirmation,
    },
    fr: {
        common: frenchCommon,
        forms: frenchForms,
        comparison: frenchComparison,
        confirmation: frenchConfirmation,
    },
    it: {
        common: italianCommon,
        forms: italianForms,
        comparison: italianComparison,
        confirmation: italianConfirmation,
    },
    nl: {
        common: dutchCommon,
        forms: dutchForms,
        comparison: dutchComparison,
        confirmation: dutchConfirmation,
    },
};

export default resources;
