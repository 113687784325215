import React from 'react';
import Price from '../../Price';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPrice = styled.div`
    align-items: center;
    display: flex;
    height: 50px;
    font-size: 20px;
    font-weight: 500;
    justify-content: center;
    text-align: center;
`;

const ProductPrice = ({ amount, currency }) => {
    return (
        <StyledPrice>
            <Price
                amount={ amount }
                currency={ currency }
            />
        </StyledPrice>
    );
}

ProductPrice.propTypes = {
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
}

export default ProductPrice;
