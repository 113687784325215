import React from 'react';
import BaseIcon from './BaseIcon';

class TaxiIcon extends BaseIcon {
    render() {
        return (<svg version="1.1" id="taxi" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            <path
                fill={ this.props.primary }
                d="M747.9,298.8c-3.3,0-6.9,0.4-10.4,1.4l-36.4,10l-39-94.9c-11.6-28.5-46.1-51.7-77-51.7h-26.5V84.9
                c0-13.8-11.2-25.1-25.1-25.1H400H266.4c-13.9,0-25.1,11.3-25.1,25.1v78.8h-26.5c-30.9,0-65.4,23.1-77,51.7l-39,94.9l-36.4-10
                c-3.5-1-7.1-1.4-10.4-1.4c-18.8,0-32.4,14.4-32.4,34.2v20.2c0,23.1,18.8,41.9,41.9,41.9h2.7l-5.5,13.6
                c-10.9,26.5-19.7,71.2-19.7,99.9v189.6c0,23.1,18.8,41.9,41.9,41.9h50.4c23.1,0,41.9-18.8,41.9-41.9v-46.7H400h226.7v46.7
                c0,23.1,18.8,41.9,41.9,41.9H719c23.1,0,41.9-18.8,41.9-41.9V508.6c0-28.7-8.7-73.4-19.7-99.9l-5.5-13.6h2.7
                c23.1,0,41.9-18.8,41.9-41.9V333C780.3,313.2,766.7,298.8,747.9,298.8z M502.1,89.6h15.5v74.6h-15.5V89.6z M416.9,89.6h15.4
                c1,0,1.8,0.1,2.3,0.4c0.5,0.3,0.9,0.7,1.3,1.3l16,26.4c0.2-0.4,0.4-0.8,0.6-1.3c0.2-0.4,0.4-0.9,0.7-1.3l14.4-23.5
                c0.4-0.7,0.9-1.2,1.4-1.5c0.5-0.3,1.1-0.5,1.8-0.5h14.8l-23.7,35.5l24.3,39.1h-15.4c-1,0-1.9-0.3-2.5-0.8c-0.6-0.5-1.1-1.1-1.5-1.8
                l-16.1-27.5c-0.2,0.4-0.3,0.7-0.5,1.1c-0.2,0.4-0.3,0.7-0.5,1l-15.3,25.4c-0.4,0.7-1,1.2-1.6,1.8c-0.6,0.5-1.4,0.8-2.3,0.8H416
                l24.4-38.4L416.9,89.6z M364,89.6h15.8l29.1,74.6h-12c-1.3,0-2.4-0.3-3.3-1c-0.8-0.6-1.5-1.4-1.9-2.4l-5-14.2H357l-5,14.2
                c-0.3,0.9-0.9,1.6-1.8,2.3c-0.9,0.7-1.9,1.1-3.2,1.1h-12.1L364,89.6z M274.2,89.6h59.1v12.4h-21.8v62.2h-15.5v-62.2h-21.8V89.6z
                M262.3,557.7c0,9.3-7.6,16.7-16.7,16.7H132.4c-9.3,0-16.7-7.6-16.7-16.7V504c0-9.3,7.6-16.7,16.7-16.7h113.1
                c9.3,0,16.7,7.6,16.7,16.7V557.7z M149.1,402.7c-18.5,0-27.8-13.9-20.8-31l59.3-144.5c6.9-17.1,27.8-31,46.1-31H400h166.3
                c18.4,0,39.2,13.9,46.1,31l59.3,144.5c6.9,17.1-2.3,31-20.8,31H400H149.1z M684.3,557.7c0,9.1-7.5,16.7-16.7,16.7H554.5
                c-9.1,0-16.7-7.5-16.7-16.7V504c0-9.1,7.5-16.7,16.7-16.7h113.1c9.1,0,16.7,7.5,16.7,16.7V557.7z"
            />
            <path
                fill={ this.props.primary }
                d="M374.9,113.1c-0.5-1.2-1-2.6-1.5-4.2c-0.5-1.6-1-3.4-1.5-5.2c-0.5,1.9-1,3.7-1.5,5.3c-0.5,1.6-1,3-1.5,4.2l-8,22.7h22.2
                L374.9,113.1z"
            />
        </svg>);
    }
}

export default TaxiIcon;
