import React from 'react';
import PropTypes from 'prop-types';
import ResultItem from './ResultItem';
import styled from 'styled-components';

const StyledUnorderedList = styled.ul`
    margin: 0;
    padding: 0;
`

const showList = results => {
    return results.map((result, key) => {
        return <ResultItem result={result} key={`result_${key}`} />
    });
};

const ResultList = ({ results }) => {
    return <StyledUnorderedList>{ showList(results) }</StyledUnorderedList>
}

ResultList.propTypes = {
    results: PropTypes.array.isRequired,
}

export default ResultList;
